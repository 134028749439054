import { MenuItem, Typography } from '@material-ui/core';
import { FormikTextField } from '@superdispatch/forms';
import { formatPaymentMethod, formatPaymentTerm } from '@superdispatch/sdk';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { Form, FormikProvider, useFormikContext } from 'formik';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { usePaymentOptions } from '../../core/SuperPayUtils';
import { OrderFormValues } from '../../orders/core/form/OrderForm';
import { useSuperPayCancelForm } from './useSuperPayCancelForm';

interface SuperPayCancelDialogProps {
  open: boolean;
  onClose: () => void;
}

export function SuperPayCancelDialog({
  open,
  onClose,
}: SuperPayCancelDialogProps) {
  const { values: order } = useFormikContext<OrderFormValues>();
  const { paymentMethods, paymentTerms } = usePaymentOptions(null, true);
  const { form, isLoading } = useSuperPayCancelForm({
    orderId: order.id,
    orderGuid: order.guid,
    onClose,
  });

  return (
    <ConfirmDialog
      maxWidth={false}
      fullWidth={false}
      open={open && !!order}
      onClose={isLoading ? undefined : onClose}
      title="Cancel SuperPay Payment?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: isLoading,
        onClick: onClose,
      }}
      confirmButtonProps={{
        children: 'Confirm',
        isLoading,
        onClick: () => form.handleSubmit(),
        autoFocus: false,
      }}
    >
      <FormikProvider value={form}>
        <Form>
          <Box maxWidth={['auto', '360px']}>
            <Stack space="small">
              <Typography color="textSecondary">
                To proceed, select a new payment method. Refunds take 1–3
                business days, and SuperPay can’t be reselected.
              </Typography>
              <Columns space="small">
                <Column>
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    name="method"
                    label="Payment Method"
                    SelectProps={{ displayEmpty: true }}
                  >
                    <MenuItem value="">
                      <Typography color="textSecondary">
                        Select a method
                      </Typography>
                    </MenuItem>
                    {paymentMethods.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentMethod(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Column>
                <Column>
                  <FormikTextField
                    select={true}
                    fullWidth={true}
                    name="terms"
                    label="Terms"
                  >
                    {paymentTerms.map((value) => (
                      <MenuItem key={value} value={value}>
                        {formatPaymentTerm(value)}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Column>
              </Columns>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </ConfirmDialog>
  );
}
