import { useAtom } from 'jotai';
import { useOrderActionsContext } from 'orders/core/actions/OrderActionsContext';
import { Order } from 'shared/types/order';
import tempImage from './assets/post-to-slb-img.png';
import { isFirstOrderCreatedAtom } from './atoms/OnboardingAtoms';
import { OnboardingNudgingDialog } from './OnboardingNudgingDialog';

interface OnboardingOnCreateOrderPostToSLBNudgingDialogProps {
  order: Order;
}

export function OnboardingOnCreateOrderPostToSLBNudgingDialog({
  order,
}: OnboardingOnCreateOrderPostToSLBNudgingDialogProps) {
  const { onAction } = useOrderActionsContext();
  const [isFirstOrderCreated, setIsFirstOrderCreated] = useAtom(
    isFirstOrderCreatedAtom,
  );

  if (!isFirstOrderCreated) {
    return null;
  }

  return (
    <OnboardingNudgingDialog
      image={<img src={tempImage} alt="post to slb" width="212px" />}
      localStorageKey="is_on_order_create_post_to_slb_onboarding_nudging_closed"
      onPrimaryAction={() => {
        onAction('post_to_sdlb', order);
      }}
      onClose={() => {
        setIsFirstOrderCreated(false);
      }}
      secondaryActionText="Do it later"
      primaryActionText="Post to Super Loadboard"
      overline="Congratulations with creating order!"
      title="Next, Post to Super Loadboard"
      subtitle="When you are ready, post the order to Super Loadboard"
    />
  );
}
