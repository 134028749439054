import { useFeatureToggle } from '../../shared/data/FeatureToggle';
import { OrderActionsAnalyticsProperties } from '../data/VerificationAnalyticsEvents';
import { useVerificationApplication } from '../data/VerificationApplicationAPI';
import { AuthorityRevocationDialog } from './AuthorityRevocationDialog';
import { VerificationApplicationDialog } from './VerificationApplicationDialog';
import { VerificationApplicationDialogLegacy } from './VerificationApplicationDialogLegacy';

interface VerificationDialogsProps extends OrderActionsAnalyticsProperties {
  open: boolean;
  onClose: () => void;
}

export function VerificationDialogs(props: VerificationDialogsProps) {
  const isVerificationOptimizationEnabled = useFeatureToggle(
    'verification-optimization.enabled.ui',
  );

  const { data: verification } = useVerificationApplication();

  if (verification?.status === 'REVOKED') {
    return <AuthorityRevocationDialog {...props} />;
  }

  if (
    isVerificationOptimizationEnabled &&
    verification?.status === 'NOT_SUBMITTED'
  ) {
    return <VerificationApplicationDialog {...props} />;
  }

  return <VerificationApplicationDialogLegacy {...props} />;
}
