import { Typography } from '@material-ui/core';
import { useSnackbarStack } from '@superdispatch/ui';
import { useMutation } from 'react-query';
import { ConfirmDialog } from 'shared/ui/ConfirmDialog';
import { useDeleteRepresentative } from 'superpay/data/MoovAPI';
import { MoovRepresentative } from 'superpay/data/MoovDTO';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';

interface Props {
  open: boolean;
  representative?: MoovRepresentative;
  onDeleteSuccess: () => void;
  onClose: () => void;
}

export function OwnerDeleteConfirmationForm({
  open,
  representative,
  onDeleteSuccess,
  onClose,
}: Props) {
  const { addSnackbar } = useSnackbarStack();
  const { mutateAsync: deleteRepresentative } = useDeleteRepresentative();

  const { mutate, isLoading } = useMutation({
    mutationFn: () => {
      if (!representative) {
        return Promise.reject(
          new Error('Please try again or reload the page.'),
        );
      }

      return deleteRepresentative(representative.representativeID);
    },
    onSuccess: () => {
      addSnackbar('Representative deleted', { variant: 'success' });
      onDeleteSuccess();
      logPaymentInfo(
        'Representative deleted',
        'DeleteRepresentativeConfirmation',
        {
          representativeId: representative?.representativeID,
        },
      );
      onClose();
    },
    onError: (error: Error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <ConfirmDialog
      open={open}
      maxWidth="xs"
      onClose={isLoading ? undefined : onClose}
      title="Delete representative?"
      cancelButtonProps={{
        children: 'Cancel',
        disabled: isLoading,
        onClick: () => onClose(),
      }}
      confirmButtonProps={{
        children: 'Delete',
        isLoading,
        onClick: () => mutate(),
        autoFocus: false,
      }}
    >
      {!!representative && (
        <Typography>
          {representative.name.firstName} {representative.name.lastName}
        </Typography>
      )}
    </ConfirmDialog>
  );
}
