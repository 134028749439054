import { useUserState } from 'shared/data/AppUserState';
import { trackEvent as originalTrackEvent } from 'shared/helpers/AnalyticsHelpers';
import { VERIFICATION_APPLICATION_STATUS } from './VerificationApplicationDTO';

type ContactComplianceLocations =
  | 'Shipper Type'
  | 'W-9'
  | 'Dealer License'
  | 'Certificate of Insurance'
  | 'Become a Verified Shipper';

export interface OrderActionsAnalyticsProperties {
  action?: string;
  actionType?: 'single' | 'bulk';
}

type VerificationApplicationEvent =
  | {
      name: 'Shipper Clicked on Learn More';
      utm_content:
        | 'Shipper Type'
        | 'W-9'
        | 'Dealer License'
        | 'Certificate of Insurance'
        | 'Verification Under Review';
    }
  | {
      name: 'Shipper Clicked on Contact Compliance';
      utm_content: ContactComplianceLocations;
      verification_status:
        | typeof VERIFICATION_APPLICATION_STATUS[number]
        | undefined;
    }
  | {
      name: 'Shipper Clicked on "Doing Business From Outside Of The US" Link';
    }
  | {
      name: 'Shipper Clicked on "I Do Not Own A W-9" Link';
    }
  | {
      name: 'Shipper Clicked on "I Do Not Own An Insurance" Link';
    }
  | {
      name: 'Shipper Opened Verification Dialog';
      utm_medium: 'Orders' | 'Profile';
      action?: string;
      action_type?: 'single' | 'bulk';
    }
  | {
      name: 'Shipper Opened Verification Application';
    }
  | {
      name: 'Shipper Continued to Your Company Step';
    }
  | {
      name: 'Shipper Continued to Identification Step';
    }
  | {
      name: 'Shipper Continued to Insurance Step';
    }
  | {
      name: 'Shipper Submitted Verification Application';
    }
  | {
      name: 'Shipper Clicked Finish and Continue';
    }
  | {
      name: 'Shipper Clicked Back';
      utm_content: 'Identification' | 'Insurance';
    }
  | {
      name: 'Shipper Closed Verification Application';
      step:
        | 'Verification Info'
        | 'Your Company'
        | 'Identification'
        | 'Insurance';
    }
  | {
      name: 'Shipper Clicked on "Learn More" Link in Operating Authority Inactive Banner';
    }
  | {
      name: 'Shipper Viewed Authority Revocation Dialog';
      utm_content: 'Automatic' | 'From Order Actions';
      action?: string;
      action_type?: 'single' | 'bulk';
    };

export function trackVerificationApplicationEvent({
  name,
  ...rest
}: VerificationApplicationEvent) {
  switch (name) {
    case 'Shipper Opened Verification Application':
      originalTrackEvent(name, { ...rest });
      break;
    default:
      originalTrackEvent(name, {
        utm_medium: 'Verification Application',
        ...rest,
      });
  }
}

export function trackClosedVerificationApplicationEvent(path: string) {
  const step = path.split('/').pop();
  trackVerificationApplicationEvent({
    name: 'Shipper Closed Verification Application',
    step:
      step === 'step-1'
        ? 'Your Company'
        : step === 'step-2'
        ? 'Identification'
        : 'Insurance',
  });
}

export function useTrackContactComplianceEvent() {
  const { user } = useUserState();
  return (location: ContactComplianceLocations) => {
    trackVerificationApplicationEvent({
      name: 'Shipper Clicked on Contact Compliance',
      utm_content: location,
      verification_status: user?.shipper.verification_status,
    });
  };
}
