import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPaySettings } from 'shared/helpers/superpay/SuperPayAPI';
import { useUpdateSuperPaySettings } from '../data/SuperPayAPI';
import { superPayUpdateSettingsSchema } from '../data/SuperPayDTO';

export function useWorkflowSettingsForm() {
  const { data, refetch } = useSuperPaySettings();
  const { mutateAsync: updateSuperPaySettings } = useUpdateSuperPaySettings();
  const { addSnackbar } = useSnackbarStack();
  return useAppFormik({
    validationSchema: superPayUpdateSettingsSchema,
    initialValues: data,
    onSubmit: (values) => updateSuperPaySettings(values),
    onSubmitSuccess: (_, values) => {
      addSnackbar('Settings updated', {
        variant: 'success',
      });

      if (!data?.expedited_pay_available && values.expedited_pay_available) {
        trackEvent('Shipper turned Expedited Pay On', {
          '1_3_business_days_percentage': values.expedited_pay_fee,
        });
      }

      if (
        values.expedited_pay_eligibility !== data?.expedited_pay_eligibility
      ) {
        trackEvent(
          'Shipper Switched Carriers Permission for Expedite Payments',
          {
            carrier_permission: values.expedited_pay_eligibility,
          },
        );
      }

      void refetch();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });
}
