import { CircularProgress, Link, Typography } from '@material-ui/core';
import { Color, Inline, Stack, Tag } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { lowerCase, startCase } from 'lodash-es';
import { OrderDeliveryVerificationFailedTooltip } from 'orders/OrderDeliveryVerificationFailedTooltip';
import { Fragment, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import Order from 'shared/types/order';
import { DetailedFormattedDate } from 'shared/ui/DetailedFormattedDate';
import { Divider } from 'shared/ui/Divider';
import {
  Timeline,
  TimelineBranch,
  TimelineBranchItem,
  TimelineItem,
  TimelineItemPadded,
} from 'shared/ui/Timeline';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';
import { InlineBulletItems } from '../../../shared/ui/BulletTextItem';
import { OrderActivityDTO } from '../../data/dto/OrderActivityDTO';
import { OrderActivityCarrierInfo } from './OrderActivityCarrierInfo';
import { OrderActivityErrorMessages } from './OrderActivityErrorMessages';
import { OrderActivityPaymentOnHoldTooltip } from './OrderActivityPaymentOnHoldTooltip';

const ScrollableContainer = styled.div`
  margin: 0 16px 16px 0;
  max-height: 500px;
  overflow: auto;
`;

const TimelineContentWrapper = styled.div`
  padding: 0 32px 0 48px !important;
`;

const Pre = styled.pre`
  margin: 0;
  white-space: pre-wrap;
  font-family: inherit;
`;

const activityCodeLabels = new Map([
  ['canceled', 'Offer Canceled'],
  ['counter_offer_sent_by_broker', 'Counter Offer Sent By Shipper'],
  ['manually_marked_as_accepted', 'Manually Marked as Accepted'],
  ['manually_marked_as_delivered', 'Manually Marked as Delivered'],
  ['manually_marked_as_picked_up', 'Manually Marked as Picked Up'],
  ['marked_as_delivery_verified', 'Marked as Delivery Verified'],
  ['marked_as_not_picked_up', 'Carrier marked vehicle as Not Picked Up'],
  ['unmarked_as_delivery_verified', 'Unmarked as Delivery Verified'],
  ['posted_to_cd', 'Posted to CD'],
  ['posted_to_loadboard', 'Posted to Loadboard'],
  ['removed_from_cd', 'Removed from CD'],
  ['removed_from_loadboard', 'Removed from Loadboard'],
  ['unmark_as_paid', 'Unmarked as Paid'],
  ['unposted_from_loadboard', 'Unposted from Loadboard'],
  ['super_pay_charge_initiated', 'Charge Initiated'],
  ['super_pay_charge_failed', 'SuperPay Charge Failed'],
  ['super_pay_charge_completed', 'Charged'],
  ['super_pay_canceled', 'SuperPay Canceled'],
  ['super_pay_payment_initiated', 'Carrier payment will be initiated'],
  ['super_pay_payment_completed', 'Carrier Paid'],
  ['super_pay_payment_failed', 'Payment Failed'],
  ['super_pay_payment_on_hold', 'Payment on Hold'],
  ['super_pay_payment_resumed', 'Payment Resumed'],
  ['super_pay_payin_refunding', 'Refund is processing'],
  ['super_pay_payin_refunded', 'Refund completed'],
  ['super_pay_payin_refund_failed', 'Refund failed'],
  ['system_delivery_verification_succeed', 'Delivery Verified'],
  ['system_delivery_verification_failed', 'Delivery Verification Failed'],
  [
    'system_delivery_verification_verified_manually',
    'Marked as Delivery Verified',
  ],
  ['price_negotiation_created', 'Price Negotiation Enabled'],
  ['price_negotiation_deleted', 'Price Negotiation Disabled'],
  ['price_negotiation_offer_sent', 'Offer Sent via Price Negotiation'],
  [
    'price_negotiation_counter_offer_sent',
    'Counter Offer Sent via Price Negotiation',
  ],
  ['vehicle_data_changed', 'Updated Vehicle Info by Carrier'],
  ['auto_reposted_to_loadboard', 'Auto-reposted to Super Loadboard'],
]);

interface OrderViewActivityStatusProps {
  activity: OrderActivityDTO;
  vehicleCount: number;
  order: Order;
}

function OrderViewActivityTitle({
  vehicleCount,
  activity: { code, action_status, title, user, description },
  order,
}: OrderViewActivityStatusProps) {
  const titleValue = title || activityCodeLabels.get(code) || startCase(code);

  if (code === 'super_pay_payment_on_hold' && !user?.username && !description) {
    return (
      <Inline space="xxsmall" verticalAlign="center">
        {titleValue}
        <OrderActivityPaymentOnHoldTooltip order={order} />
      </Inline>
    );
  }

  if (code === 'system_delivery_verification_failed') {
    return (
      <Inline space="xxsmall" verticalAlign="center">
        {titleValue}
        <OrderDeliveryVerificationFailedTooltip order={order} />
      </Inline>
    );
  }

  return (
    <span>
      {vehicleCount > 0 ? `${vehicleCount} ${titleValue}` : titleValue}{' '}
      {action_status === 'IN_PROGRESS' ? (
        <Typography color="textSecondary" display="inline">
          In Progress...
        </Typography>
      ) : action_status === 'FAILED' ? (
        <Tag color="red" variant="subtle">
          Failed
        </Tag>
      ) : null}
    </span>
  );
}

export interface OrderViewActivitiesProps {
  order: Order;
  activities: OrderActivityDTO[] | undefined;
}

export function OrderViewActivities({
  activities,
  order,
}: OrderViewActivitiesProps) {
  const { vehicles: orderVehicles = [] } = order;
  const [isScrolled, setIsScrolled] = useState(false);

  if (!activities) {
    return (
      <div style={{ textAlign: 'center', margin: '2rem' }}>
        <CircularProgress variant="indeterminate" color="primary" size={50} />
      </div>
    );
  }

  return (
    <div aria-label="order activities">
      <Box padding="small">
        <Typography variant="h3">Activity</Typography>
      </Box>

      {isScrolled && <Divider />}

      <ScrollableContainer
        onScroll={(event) => setIsScrolled(event.currentTarget.scrollTop !== 0)}
      >
        <div style={{ marginTop: -16 }}>
          <Timeline>
            {activities.map((activity) => {
              const {
                id,
                user,
                created_at,
                action_status,
                error_details,
                error_message,
                vehicles = [],
                vehicles_not_picked_up,
              } = activity;
              const hasMultipleVehicles =
                vehicles.length > 0 && orderVehicles.length > 1;

              const hasNotPickedUpVehicles =
                !!vehicles_not_picked_up?.vehicles.length;

              return (
                <TimelineItem
                  key={id}
                  isExpandable={hasMultipleVehicles || hasNotPickedUpVehicles}
                  title={
                    <OrderViewActivityTitle
                      order={order}
                      activity={activity}
                      vehicleCount={hasMultipleVehicles ? vehicles.length : 0}
                    />
                  }
                  subtitle={
                    <TimelineItemPadded>
                      <InlineBulletItems space="xxsmall">
                        {user?.username && (
                          <Box maxWidth="150px">
                            <TextBox
                              wrapOverflow={true}
                              noWrap={true}
                              color="inherit"
                            >
                              {user.username}
                            </TextBox>
                          </Box>
                        )}
                        <DetailedFormattedDate date={created_at} />
                      </InlineBulletItems>
                      {!!activity.created_from_order && (
                        <Typography>
                          Created from{' '}
                          <Link
                            component={RouterLink}
                            aria-label="created from order"
                            to={`/orders/view/${activity.created_from_order.guid}`}
                          >
                            {activity.created_from_order.number}
                          </Link>
                        </Typography>
                      )}
                    </TimelineItemPadded>
                  }
                  expandContent={
                    hasMultipleVehicles ? (
                      <TimelineBranch>
                        {vehicles.map((vehicle) => (
                          <TimelineBranchItem key={vehicle.guid}>
                            <Typography
                              gutterBottom={true}
                              noWrap={true}
                              aria-label="vehicle model"
                            >
                              {VehicleDTO.formatVehicleModel(vehicle)}
                            </Typography>

                            <Typography
                              color="textSecondary"
                              noWrap={true}
                              aria-label="vehicle vin"
                            >
                              {vehicle.vin}
                            </Typography>
                          </TimelineBranchItem>
                        ))}
                      </TimelineBranch>
                    ) : (
                      hasNotPickedUpVehicles && (
                        <>
                          {vehicles_not_picked_up.vehicles.map((vehicle) => (
                            <Fragment
                              key={VehicleDTO.formatVehicleModel(vehicle)}
                            >
                              <TimelineBranch>
                                <TimelineBranchItem>
                                  <Typography
                                    gutterBottom={true}
                                    noWrap={true}
                                    aria-label="vehicle model"
                                  >
                                    {VehicleDTO.formatVehicleModel(vehicle)}
                                  </Typography>

                                  <Typography
                                    color="textSecondary"
                                    noWrap={true}
                                    aria-label="vehicle vin"
                                  >
                                    {vehicle.vin}
                                  </Typography>
                                </TimelineBranchItem>
                              </TimelineBranch>
                              {!!vehicle.cancel_reason && (
                                <TimelineContentWrapper>
                                  <Stack space="xxsmall">
                                    <Typography
                                      variant="body1"
                                      style={{ color: Color.Dark300 }}
                                    >
                                      Reason
                                    </Typography>
                                    <Box
                                      backgroundColor="Yellow50"
                                      padding="xsmall"
                                      aria-label="cancel reason"
                                    >
                                      <Pre>{vehicle.cancel_reason}</Pre>
                                    </Box>
                                  </Stack>
                                </TimelineContentWrapper>
                              )}
                            </Fragment>
                          ))}
                          <TimelineContentWrapper>
                            <Typography>
                              Order{' '}
                              <Link
                                component={RouterLink}
                                to={`/orders/view/${vehicles_not_picked_up.new_order_guid}`}
                              >
                                {vehicles_not_picked_up.new_order_number}
                              </Link>{' '}
                              was created
                            </Typography>
                          </TimelineContentWrapper>
                        </>
                      )
                    )
                  }
                >
                  {action_status === 'FAILED' && (
                    <TimelineItemPadded>
                      <OrderActivityErrorMessages
                        errorDetails={error_details}
                        errorMessage={error_message}
                      />
                    </TimelineItemPadded>
                  )}

                  {!!activity.carrier_name && (
                    <TimelineItemPadded>
                      <OrderActivityCarrierInfo activity={activity} />
                    </TimelineItemPadded>
                  )}

                  {!!activity.description && (
                    <TimelineItemPadded>
                      <Typography>{activity.description}</Typography>
                    </TimelineItemPadded>
                  )}

                  {activity.code === 'canceled_by_carrier' && (
                    <TimelineItemPadded>
                      <Box paddingTop="small">
                        <Stack space="xxsmall">
                          <Inline>
                            <Typography variant="body1" color="textSecondary">
                              Reason
                            </Typography>
                            <Typography>
                              {joinStrings(
                                ',',
                                ...(activity.offer_cancel_reasons?.map((r) =>
                                  startCase(lowerCase(r)),
                                ) || []),
                              )}
                            </Typography>
                          </Inline>
                          {activity.offer_cancel_comment && (
                            <Box
                              padding="xsmall"
                              backgroundColor="Yellow50"
                              borderRadius="small"
                            >
                              <Pre>{activity.offer_cancel_comment}</Pre>
                            </Box>
                          )}
                        </Stack>
                      </Box>
                    </TimelineItemPadded>
                  )}
                </TimelineItem>
              );
            })}
          </Timeline>
        </div>
      </ScrollableContainer>
    </div>
  );
}
