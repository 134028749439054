import { Dialog, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPayRequestedCarriers } from 'shared/helpers/superpay/SuperPayAPI';
import { SuperPayIcon } from 'shared/icons/SuperPayIcon';
import styled from 'styled-components';

const BoxList = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function SuperPayRequestedCarrierListContent({
  onClose,
}: {
  onClose: () => void;
}) {
  const { data, isLoading } = useSuperPayRequestedCarriers();
  return (
    <Box width={['auto', '400px']} padding={['small', 'large']}>
      <Stack space="medium" align="center">
        <Columns>
          <Column>
            <Typography variant="h3">
              Your top Carriers who have requested{' '}
              <SuperPayIcon width={88} height={18} />.
            </Typography>
          </Column>
          <Column width="content">
            <IconButton size="small" onClick={onClose}>
              <Close />
            </IconButton>
          </Column>
        </Columns>

        {!isLoading && data && (
          <Stack space="xxsmall">
            <BoxList>
              <Typography variant="body1">Company Name</Typography>
              <Typography variant="body1">Orders Delivered / 30d</Typography>
            </BoxList>
            {data.objects.map(({ name, guid, delivered_orders_count }) => (
              <BoxList key={guid}>
                <Typography>{name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {delivered_orders_count}
                </Typography>
              </BoxList>
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  );
}

interface SuperPayRequestedCarrierListProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SuperPayRequestedCarrierList({
  isOpen,
  onClose,
}: SuperPayRequestedCarrierListProps) {
  useEffect(() => {
    if (isOpen) {
      trackEvent('Shipper Opened List of Carriers Who Requested SuperPay');
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <SuperPayRequestedCarrierListContent onClose={onClose} />
    </Dialog>
  );
}
