import { SystemAlert } from 'shared/layout/system-alerts/SystemAlert';
import { useAlerts } from 'shared/layout/system-alerts/useAlerts';

export function SuperPayAlert() {
  const { alerts } = useAlerts();
  const superPayAlert = alerts.find((alert) => alert.type === 'SUPER_PAY');

  if (!superPayAlert) {
    return null;
  }

  return <SystemAlert alert={superPayAlert} />;
}
