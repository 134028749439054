import { useFormikEnhanced } from '@superdispatch/forms';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { plainToClass } from 'class-transformer';
import { useInternalUsers } from 'core/dictionary/data/DictionaryAPI';
import { FormikProvider } from 'formik';
import { useGeneralSettings } from 'general-settings/data/GeneralSettingsAPI';
import { useSetAtom } from 'jotai';
import { set } from 'lodash-es';
import {
  isFirstOrderCreatedAndOfferSendAtom,
  isFirstOrderCreatedAtom,
} from 'onboarding/atoms/OnboardingAtoms';
import { createEmptyOrder } from 'orders/core/form/helpers/createEmptyOrder';
import {
  getOrderAction,
  OrderFormType,
} from 'orders/core/form/helpers/orderFormHelpers';
import {
  modifyCreatedOrder,
  transformToOrder,
} from 'orders/core/form/helpers/transformOrder';
import { OrderFormSchema } from 'orders/core/form/OrderFormValidation';
import { useOrderFormInitialValues } from 'orders/core/form/useOrderFormInitialValues';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { customerDTO } from 'shared/dto/CustomerDTO';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { isAPIValidationError } from 'shared/errors/APIError';
import { APIValidationErrorMessage } from 'shared/errors/APIValidationErrorMessage';
import {
  trackDurationAnalyticsLegacy,
  trackEventLegacy,
} from 'shared/helpers/AnalyticsHelpers';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import {
  ORDER_RECENT_SELECTIONS_KEY,
  updateRecentArray,
  updateRecentVehicleMakers,
  useRecentSelections,
} from 'shared/helpers/RecentSelectionsHelpers';
import { SourceManager } from 'shared/helpers/SourceManager';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { AttachmentWithFile } from 'shared/types/attachment';
import Order from 'shared/types/order';
import { ValidationError } from 'yup';
import { OrderForm, OrderFormValues } from '../core/form/OrderForm';
import {
  useNewLoadCache,
  useNewLoadId,
  useOrderAPI,
  useOrdersCache,
} from '../data/OrderAPI';

export function OrderCreatePage() {
  const formAction = useRef<{
    type: OrderFormType;
    args?: Record<string, unknown>;
  } | null>(null);

  const { user } = useUserState();
  const { data: settings } = useGeneralSettings();

  const navigate = useNavigate();
  const { addSnackbar } = useSnackbarStack();
  const { createOrder, createOrderAttachment, uploadOrderVehiclePhoto } =
    useOrderAPI();

  const { data, isLoading } = useNewLoadId();

  const { clearNewLoadCache } = useNewLoadCache();
  const { invalidateOrders } = useOrdersCache();

  useEffect(() => clearNewLoadCache, []);

  const { data: { objects: users = [] } = {} } = useInternalUsers();

  const initialOrder = useMemo(
    () => createEmptyOrder({ number: data?.load_id }),
    [data],
  );

  const [openedTime, setOpenedTime] = useState<number | undefined>(undefined);
  const setIsFirstOrderCreated = useSetAtom(isFirstOrderCreatedAtom);
  const setIsFirstOrderCreatedAndOfferSendAtom = useSetAtom(
    isFirstOrderCreatedAndOfferSendAtom,
  );

  const hasClosedOnOrderCreateSLBNudgingDialog = useStorageValue(
    'is_on_order_create_post_to_slb_onboarding_nudging_closed',
  );
  const hasClosedOnSendOfferSLBNudgingDialog = useStorageValue(
    'is_on_send_offer_post_to_slb_onboarding_nudging_closed',
  );

  const nudgingShowed =
    hasClosedOnOrderCreateSLBNudgingDialog ||
    hasClosedOnSendOfferSLBNudgingDialog;

  const recentSelections = useRecentSelections();

  useDocumentTitle('Create Order');
  useEffect(() => {
    setOpenedTime(Date.now());
  }, [data?.load_id]);

  /**
   * Save recent selections to local storage for future use in order creation form
   * @param order - order object
   * @returns
   */
  const saveRecentSelections = (order: OrderFormValues) => {
    recentSelections.customers = updateRecentArray(
      recentSelections.customers,
      customerDTO.cast({
        ...order.customer,
        save_as_recent: true,
      }),
    );
    recentSelections.pickupVenues = updateRecentArray(
      recentSelections.pickupVenues,
      customerDTO.cast({ ...order.pickup.venue, save_as_recent: true }),
    );
    recentSelections.deliveryVenues = updateRecentArray(
      recentSelections.deliveryVenues,
      customerDTO.cast({ ...order.delivery.venue, save_as_recent: true }),
    );

    recentSelections.inspection_type = order.inspection_type;
    recentSelections.pickup_date_type = order.pickup.date_type;
    recentSelections.delivery_date_type = order.delivery.date_type;
    recentSelections.customer_payment_terms = order.customer_payment?.terms;
    recentSelections.vehicle_makers = updateRecentVehicleMakers(
      recentSelections.vehicle_makers,
      plainToClass(VehicleDTO, order.vehicles),
    );

    writeStorageItem(
      ORDER_RECENT_SELECTIONS_KEY,
      JSON.stringify(recentSelections),
    );
  };

  const { initialValues } = useOrderFormInitialValues(
    initialOrder,
    'order_create_page',
  );

  const validateForm = async (values: OrderFormValues) => {
    try {
      await OrderFormSchema.validate(values, {
        abortEarly: false,
        context: {
          maxNumberOfTags: settings?.max_number_of_tags,
          formType: formAction.current?.type,
          isSuperPaySuspend:
            user?.shipper.super_pay_settings?.suspended_account,
        },
      });
      return null;
    } catch (validationError: unknown) {
      let errors = {};
      if (
        ValidationError.isError(validationError) &&
        validationError.inner.length > 0
      ) {
        validationError.inner.forEach((error) => {
          set(errors, error.path, error.message);
        });
      }
      return errors;
    }
  };

  const formik = useFormikEnhanced<OrderFormValues, Order>({
    initialValues,
    validate: validateForm,
    validateOnBlur: true,
    validateOnChange: false,
    async onSubmit(formValues) {
      saveRecentSelections(formValues);

      const payload = transformToOrder(formValues, users);

      const { attachments = [], vehicles = [] } = payload;

      const createdOrder = await createOrder(payload);

      await Promise.all(
        attachments.map((attachment: AttachmentWithFile) => {
          if (attachment.file instanceof File) {
            return createOrderAttachment(
              createdOrder.id,
              attachment.file,
              attachment.is_shared_with_carrier,
              attachment.is_shared_with_customer,
            );
          }

          return undefined;
        }),
      );

      await Promise.all(
        vehicles.map((vehicle, index) => {
          const photo = vehicle.photos?.find(
            (x) => x.photo_file instanceof File,
          );
          const createdVehicle = createdOrder.vehicles?.[index];

          if (createdVehicle && photo?.photo_file) {
            return uploadOrderVehiclePhoto(
              createdOrder.id,
              createdVehicle.id,
              photo.photo_file,
            );
          }

          return undefined;
        }),
      );

      return createdOrder;
    },
    onSubmitSuccess(createdOrder, formValues) {
      // TODO: [STMS-2104] Remove this after all pages are migrated to new analytics
      let orderToTrack = modifyCreatedOrder(createdOrder, formValues);

      trackEventLegacy('Created Order', orderToTrack);
      addSnackbar('Saved', { variant: 'success' });

      navigate(`/orders/view/${createdOrder.guid}`, {
        state: {
          action: getOrderAction(formAction.current) || {
            type: 'instant_dispatch_opener',
          },
        },
        replace: true,
      });

      if (!nudgingShowed) {
        if (formAction.current?.type === 'save') {
          setIsFirstOrderCreated(true);
        }

        if (formAction.current?.type === 'save_and_send_offer') {
          setIsFirstOrderCreatedAndOfferSendAtom(true);
        }
      }

      void invalidateOrders();
    },
    onSubmitFailure(error) {
      if (isAPIValidationError(error)) {
        //TODO Add frontend validation
        addSnackbar(
          <Stack>
            The following information is missing. Edit the order and provide
            this information:
            <APIValidationErrorMessage error={error} />
          </Stack>,
          {
            variant: 'error',
          },
        );
      } else {
        addSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  // The function for changing the form type 'save' or 'save_and_send_offer', etc
  const handleChangeFormType = useCallback(
    (type: OrderFormType, args?: Record<string, unknown>) => {
      formAction.current = { type, args };
      formik.handleSubmit();

      if (openedTime) {
        trackDurationAnalyticsLegacy('Clicked Save Order', openedTime);
      }
    },
    [formik, openedTime],
  );

  const onCancelForm = useCallback(() => {
    navigate('/orders', { replace: true });
  }, [navigate]);

  return (
    <FormikProvider value={formik}>
      <SourceManager primarySource="New Order">
        <OrderForm
          title="New Order"
          source="order_create_page"
          order={initialOrder}
          users={users}
          onSave={handleChangeFormType}
          onCancelForm={onCancelForm}
          isLoading={!data || isLoading}
          isSubmitDisabled={formik.isSubmitting}
          isFormEdited={formik.dirty}
        />
      </SourceManager>
    </FormikProvider>
  );
}
