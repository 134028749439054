import { hasOnlyDigits, ONLY_DIGITS_PATTERN } from 'shared/utils/StringUtils';
import {
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { JOB_TITLES } from 'superpay/data/MoovDTO';
import { InferType, StringSchema } from 'yup';

export const ownerEditFormValidationSchema = yupObject({
  name: yupObject({
    firstName: yupString().max(64).required('Enter first name').defined(),
    lastName: yupString().max(64).required('Enter last name').defined(),
  }),
  phone: yupPhone().required('Enter phone number').defined().nullable(),
  email: yupString().max(255).required('Invalid email').email().defined(),
  birthDate: yupObject({
    month: yupNumber().min(1).max(12),
    day: yupNumber().min(1).max(31),
    year: yupNumber().min(1900),
  }).nullable(),
  address: yupObject({
    addressLine1: yupString()
      .max(60)
      .required('Enter street address')
      .defined(),
    city: yupString().max(24).required('Enter city').defined(),
    stateOrProvince: yupString().length(2).required('Invalid state').defined(),
    postalCode: yupString()
      .test('only postal code digits', 'Invalid ZIP', hasOnlyDigits)
      .length(5)
      .required('Invalid ZIP')
      .defined(),
    country: yupString().default('US'),
  }),
  governmentID: yupObject({
    ssn: yupObject({
      full: ssnSchema()
        .matches(ONLY_DIGITS_PATTERN, 'Invalid SSN')
        .length(9)
        .defined(),
    }).nullable(),
  }),
  responsibilities: yupObject({
    isController: yupBoolean().default(false),
    ownershipPercentage: yupNumber()
      .min(25, 'Percent ownership cannot be less than 25')
      .max(100, 'Percent ownership cannot be more than 100')
      .required('Invalid percent ownership')
      .defined(),
    jobTitle: yupEnum(JOB_TITLES, 'manager').required().defined(),
  }),
});

export type OwnerEditFormValues = InferType<
  typeof ownerEditFormValidationSchema
>;

function ssnSchema(): StringSchema<null | string> {
  return yupString()
    .trim()
    .nullable()
    .default(null)
    .transform(function normalize(value) {
      return typeof value == 'string' && value.length > 0
        ? value
        : this.getDefault();
    });
}
