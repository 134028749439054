import { Inline, useSnackbarStack } from '@superdispatch/ui';
import { Button, TextBox } from '@superdispatch/ui-lab';
import { useOrderAPI, useOrdersCache } from 'orders/data/OrderAPI';
import { useOrderRequests } from 'orders/data/OrderRequestsAPI';
import { useMutation } from 'react-query';
import { APIError } from 'shared/errors/APIError';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';
import { trackOrderRecommendations } from './OrderRequestRecommendationAnalytics';

export function useOrderPriceRecommendation(order: Order) {
  const recommendedPrice = order.recommended_price;

  if (order.price && recommendedPrice) {
    if (order.price < recommendedPrice) {
      return 'error';
    }

    if (order.price === recommendedPrice) {
      return 'success';
    }
  }

  return null;
}

function usePriceMutation({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError: (error: APIError) => void;
}) {
  const { updateOrderPrice } = useOrderAPI();
  const { invalidateOrders } = useOrdersCache();

  return useMutation<unknown, APIError, { orderId: number; price: number }>({
    mutationFn: (params) => updateOrderPrice(params.orderId, params.price),
    onSuccess: () => {
      onSuccess?.();
      void invalidateOrders();
    },
    onError,
  });
}

export function OrderPriceRecommendation({ order }: { order: Order }) {
  const { addSnackbar } = useSnackbarStack();
  const { data: requests } = useOrderRequests(order.guid);
  const recommendedPrice = order.recommended_price;
  const { isLoading, mutate } = usePriceMutation({
    onSuccess: () => {
      trackOrderRecommendations(
        { recommended_price: recommendedPrice },
        requests?.objects.length,
      );
    },
    onError: (error: APIError) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  if (!recommendedPrice) {
    return null;
  }

  return (
    <Inline space="xsmall" verticalAlign="center">
      <Button
        variant="primary"
        pending={isLoading}
        onClick={() => {
          mutate({ orderId: order.id, price: recommendedPrice });
        }}
      >
        Set Price to{' '}
        {formatCurrency(recommendedPrice, { maximumFractionDigits: 0 })}
      </Button>
      <TextBox color="secondary">for faster booking.</TextBox>
    </Inline>
  );
}
