import { Divider, Typography } from '@material-ui/core';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import styled from 'styled-components';
import { useSubscriptionPlan } from 'subscription-details/useSubscriptionPlan';

const PlanPrice = styled(Typography)`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;
const StyledDivider = styled(Divider)`
  background-color: ${Color.Silver500};
`;

export function PaywallPlanInformation() {
  const { plan } = useSubscriptionPlan();

  if (!plan) {
    return null;
  }

  const isMonthlyPayment = plan.planCode.includes('monthly');

  return (
    <Box
      paddingLeft="small"
      paddingRight="small"
      paddingTop="medium"
      paddingBottom="medium"
      borderRadius="small"
      backgroundColor="Blue50"
    >
      <Stack space="xsmall">
        <Columns align="center" space="small">
          <Column width="fluid">
            <Stack space="xxsmall">
              <Typography variant="h6" color="textSecondary">
                PLAN
              </Typography>
              <Typography variant="body1">{plan.plan}</Typography>
            </Stack>
          </Column>
          <Column width="content">
            <Stack space="xxsmall">
              <Inline verticalAlign="bottom" space="none">
                <PlanPrice>
                  {formatCurrency(plan.monthlyPlanPrice, {
                    maximumFractionDigits: 0,
                  })}
                </PlanPrice>
                <Typography variant="h5" color="textSecondary">
                  {isMonthlyPayment ? '/month' : '/year'}
                </Typography>
              </Inline>

              <Typography color="textSecondary">
                {isMonthlyPayment ? 'Billed monthly' : 'Billed annually'}
              </Typography>
            </Stack>
          </Column>
        </Columns>
        {plan.addons.length > 0 && <StyledDivider />}
        {plan.addons.map((addon) => (
          <Typography key={addon.type}>
            You will be charged{' '}
            <b>
              {formatCurrency(addon.overage_price, {
                maximumFractionDigits: 2,
              })}
            </b>{' '}
            per {addon.type === 'VIN_POSTED' ? 'VINs posted' : 'VINs delivered'}{' '}
            over {addon.max_item_count}
          </Typography>
        ))}
      </Stack>
    </Box>
  );
}
