import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { SystemAlert } from './SystemAlert';
import { AlertDTO } from './useAlerts';

export function SuperPaySystemAlert({ alert }: { alert: AlertDTO }) {
  const isRead = useIsRead(alert.guid);

  if (isRead) {
    return null;
  }

  function handleClose() {
    markAsRead(alert.guid);
  }

  return <SystemAlert alert={alert} onClose={handleClose} />;
}

const LAST_ALERT_GUID_KEY = 'system_alerts_superpay_last_alert_guid';

function useIsRead(orderGuid: string | null) {
  const lastAlertGuid = useStorageValue(LAST_ALERT_GUID_KEY);
  return lastAlertGuid === orderGuid;
}

function markAsRead(orderGuid: string) {
  writeStorageItem(LAST_ALERT_GUID_KEY, orderGuid);
}
