import { Dialog, Typography } from '@material-ui/core';
import { useConstant } from '@superdispatch/hooks';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { ReactElement } from 'react';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import createOrderBackground from './assets/create-order-bg.svg';

const OnboardingBox = styled(Box)({
  backgroundImage: `url(${createOrderBackground})`,
  backgroundRepeat: 'no-repeat',
});

interface OnboardingNudgingDialogProps {
  image: ReactElement;
  localStorageKey: string;
  secondaryActionText?: string;
  primaryActionText: string;
  onPrimaryAction?: () => void;
  open?: boolean;
  onClose?: () => void;
  overline?: string;
  title: string;
  subtitle?: string;
}

export function OnboardingNudgingDialog({
  image,
  localStorageKey,
  secondaryActionText,
  primaryActionText,
  onPrimaryAction,
  open,
  onClose,
  overline,
  title,
  subtitle,
}: OnboardingNudgingDialogProps) {
  const platform = useResponsiveValue('mobile', 'other');
  const hasClosedDialog = useStorageValue(localStorageKey);
  const hasClosedDialogConstant = useConstant(() => hasClosedDialog);
  const [isOpen, _, closeDialog] = useBoolean(!hasClosedDialog);
  const handleOpen = () => {
    return open !== undefined ? open && isOpen : isOpen;
  };

  if (hasClosedDialogConstant) {
    return null;
  }

  const handleClose = () => {
    writeStorageItem(localStorageKey, String(true));
    onClose?.();
    closeDialog();
  };

  return (
    <Dialog
      open={handleOpen()}
      onClose={handleClose}
      fullScreen={platform === 'mobile'}
    >
      <OnboardingBox
        padding="large"
        paddingLeft="xlarge"
        paddingRight="xlarge"
        maxWidth="492px"
      >
        <Stack space="large" align="center">
          {image}
          <Stack space="small" align="center">
            {overline && (
              <Typography
                variant="h4"
                align="center"
                color="textSecondary"
                noWrap={false}
              >
                {overline}
              </Typography>
            )}

            <Typography variant="h2" align="center">
              {title}
            </Typography>

            {subtitle && (
              <Typography align="center" color="textSecondary">
                {subtitle}
              </Typography>
            )}

            <Inline space="small">
              {secondaryActionText && (
                <Button size="large" variant="neutral" onClick={handleClose}>
                  {secondaryActionText}
                </Button>
              )}
              <Button
                size="large"
                onClick={() => {
                  onPrimaryAction?.();
                  handleClose();
                }}
              >
                {primaryActionText}
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </OnboardingBox>
    </Dialog>
  );
}
