import {
  Card,
  CardContent,
  Collapse,
  Container,
  Switch,
} from '@material-ui/core';
import { Color, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { AriaAttributes, ReactNode, useState } from 'react';
import { ChevronDown } from 'shared/icons/ChevronDown';
import styled from 'styled-components';

export const SectionContainer = styled(Container)`
  max-width: 624px;
  padding: 0;
`;

export const SectionSwitchCard = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${Color.Silver400};
`;

export const SectionSwitch = styled(Switch)`
  margin: -12px;
`;

interface SuperLoadboardSettingsSectionProps extends AriaAttributes {
  children: ReactNode;
}

export function SuperLoadboardSettingsSection({
  children,
  ...props
}: SuperLoadboardSettingsSectionProps) {
  return (
    <SectionContainer {...props}>
      <Card>
        <CardContent>
          <Stack space="small">{children}</Stack>
        </CardContent>
      </Card>
    </SectionContainer>
  );
}

const StyledMoreInformationButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  border: none;
`;

export function MoreInformationExpandingBlock({
  children,
}: {
  children: ReactNode;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <StyledMoreInformationButton onClick={() => setIsExpanded(!isExpanded)}>
        <TextBox variant="body" color="secondary">
          {isExpanded ? 'Less Information' : 'More Information'}
        </TextBox>

        <ChevronDown
          color="action"
          fontSize="inherit"
          style={{
            fontSize: 20,
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </StyledMoreInformationButton>

      <Collapse in={isExpanded}>
        <Box marginTop="xsmall">{children}</Box>
      </Collapse>
    </>
  );
}
