import {
  logError,
  logInfo,
  logWarning,
} from 'shared/helpers/MonitoringService';

interface LogExtraInfoProps extends Record<string, unknown> {
  moovAccountId?: string | null;
}

export function logPaymentInfo(
  action: string,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logInfo(action, {
    namespace: `shipper.payments.onboarding.${source}`,
    moov_account_id: moovAccountId,
    ...extraInfo,
  });
}

export function logPaymentError(
  error: Error,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logError(error, `shipper.payments.onboarding.${source}`, {
    extraInfo: {
      moov_account_id: moovAccountId,
      ...extraInfo,
    },
  });
}

export function logPaymentWarning(
  action: string,
  source: string,
  { moovAccountId, ...extraInfo }: LogExtraInfoProps = {},
) {
  logWarning(action, {
    namespace: `shipper.payments.onboarding.${source}`,
    moov_account_id: moovAccountId,
    ...extraInfo,
  });
}
