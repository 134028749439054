import { Box, Link, Tooltip, Typography } from '@material-ui/core';
import { formatRelativeTime, FormattedDate } from '@superdispatch/dates';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, Inline, Stack, Tag } from '@superdispatch/ui';
import { plainToClass } from 'class-transformer';
import {
  ApprovedTag,
  BlacklistedTag,
  CanExpeditePaymentTag,
  CanInstantlyBookTag,
  InsuranceCertificateHolderTag,
  InsuranceExpiredTag,
  PrivateNetworkTag,
  UnverifiedTag,
  VerifiedTag,
} from 'core/CarrierTags';
import { DictionaryCarrierDTO } from 'core/dictionary/data/DictionaryDTO';
import { isCarrierInsuranceExpiresInMonth } from 'core/insurance/ExpirationDates';
import { CertificateExpirationDetails } from 'core/insurance/InsuranceExpirationInfo';
import { PricePerMile } from 'core/PricePerMile';
import { RatingStats } from 'core/ratings/RatingStats';
import { TotalOrdersDispatchedLink } from 'core/TotalOrdersDispatchedLink';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';
import { SourceManagerLegacy } from 'shared/helpers/SourceManagmentLegacy';
import { CARRIER_LOGISTICS } from 'shared/types/carrier';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { Waypoints } from 'shared/ui/Waypoints';
import styled from 'styled-components';
import { OrderRequestDTO } from '../../data/dto/OrderRequestDTO';
import {
  OrderRequestCardActionsLegacy,
  RequestStatusInfo,
  requestStatusInfo,
} from './OrderRequestCardActionsLegacy';
import { OrderRequestCargoInsuranceLegacy } from './OrderRequestCargoInsuranceLegacy';

const OrderRequestPaddedBox = styled.div`
  padding: 8px 32px;
`;

const RequestCarrierContactNameSpacing = styled.div`
  margin-right: 4px;
`;

const RequestCarrierInfoBox = styled.div`
  margin: 8px 0;
`;

const OrderRequestActionsBox = styled.div`
  margin: 16px 0 0 4px;
`;

const RequestPriceText = styled.div`
  font-size: 16px;
  font-weight: 500;
`;

interface Props {
  order: Order;
  request: OrderRequestDTO;
  hasPrevCounterOffer: boolean;
  onActionSuccess: (order: Order) => void;
}

export const OrderRequestCard = forwardRef<HTMLDivElement, Props>(
  ({ order, request, onActionSuccess, hasPrevCounterOffer }, ref) => {
    const contactDetails = `${request.carrier_contact_name} · ${request.carrier_phone}`;

    const brokerRecords = request.broker_records;
    const showOrderRequestActions =
      request.status === 'OFFER_SENT' ||
      request.status === 'COUNTER_OFFER_SENT' ||
      request.status === 'REQUEST_PENDING';

    const requestPrice = request.counter_offer_price || request.price;
    const changedAmount = Number(requestPrice) - Number(order.price);

    const requestPickupWaypoint = {
      title: (
        <FormattedDate
          variant="ShortDate"
          format="JodaISO"
          date={request.pickup_date}
        />
      ),
      subtitle: <Typography variant="caption">Pickup Date</Typography>,
    };

    const requestDeliveryWaypoint = {
      title: (
        <FormattedDate
          variant="ShortDate"
          format="JodaISO"
          date={request.delivery_date}
        />
      ),
      subtitle: <Typography variant="caption">Delivery Date</Typography>,
    };

    return (
      <OrderRequestPaddedBox
        ref={ref}
        data-cy="RequestCard"
        data-intercom-target={
          request.status === 'REQUEST_PENDING' ? 'request card' : undefined
        }
      >
        <Stack space="small">
          {requestStatusInfo[request.status] && (
            <RequestStatusInfo
              status={request.status}
              date={request.changed_at}
            />
          )}

          <Columns>
            <Column width="fluid">
              <Inline space="xsmall">
                <RequestPriceText>
                  <NumericFormat
                    displayType="text"
                    value={requestPrice}
                    thousandSeparator={true}
                    prefix="$"
                  />
                </RequestPriceText>

                {changedAmount !== 0 && (
                  <Tooltip title="Carrier is requesting a change in price">
                    <Tag color="yellow" variant="subtle">
                      <NumericFormat
                        displayType="text"
                        value={changedAmount}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={changedAmount > 0 ? '+$' : '$'}
                      />
                    </Tag>
                  </Tooltip>
                )}
              </Inline>
            </Column>

            <Column width="content">
              {typeof order.distance_meters === 'number' && (
                <PricePerMile order={order} price={requestPrice} />
              )}
            </Column>
          </Columns>

          <Waypoints
            from={requestPickupWaypoint}
            to={requestDeliveryWaypoint}
            separator="·"
          />

          <Inline space="xsmall">
            <Link
              to={`/manage-carriers/${request.carrier_guid}?utm_medium=Carrier Requests Drawer&utm_content=Carrier Profile Link`}
              component={RouterLink}
              target="_blank"
            >
              {request.carrier_name}
            </Link>

            {request.is_super_carrier ? <VerifiedTag /> : <UnverifiedTag />}
            <RatingStats
              source="Carrier Requests Drawer"
              sourceContent="Carrier Profile Link"
              carrierGuid={request.carrier_guid}
              ratingStats={request.carrier_rating_stats}
            />
          </Inline>
        </Stack>

        <RequestCarrierInfoBox>
          {request.logistics_type &&
            CARRIER_LOGISTICS[request.logistics_type] && (
              <Typography color="textSecondary">
                {CARRIER_LOGISTICS[request.logistics_type]}
              </Typography>
            )}
        </RequestCarrierInfoBox>

        <Box>
          <Stack space="xsmall">
            <RequestCarrierInfoBox>
              <TextOverflow
                title={contactDetails}
                size={350}
                aria-label="carrier contact"
              >
                <Box display="flex">
                  <RequestCarrierContactNameSpacing>
                    <Typography color="textSecondary">
                      {request.carrier_contact_name}
                    </Typography>
                  </RequestCarrierContactNameSpacing>
                  <RequestCarrierContactNameSpacing>
                    <Typography variant="body2" color="textSecondary">
                      ·
                    </Typography>
                  </RequestCarrierContactNameSpacing>

                  <SuspendedPhoneLink phone={request.carrier_phone} />
                </Box>
              </TextOverflow>
            </RequestCarrierInfoBox>

            <Inline>
              {brokerRecords?.approved && <ApprovedTag />}

              {brokerRecords?.preferred && <CanInstantlyBookTag />}

              {brokerRecords?.can_expedite_payment && <CanExpeditePaymentTag />}

              {brokerRecords?.is_in_private_network &&
                brokerRecords.private_network_groups && (
                  <PrivateNetworkTag
                    groups={brokerRecords.private_network_groups}
                  />
                )}

              {brokerRecords?.insurance_certificate_holder && (
                <InsuranceCertificateHolderTag />
              )}

              {request.is_insurance_expired && <InsuranceExpiredTag />}

              {brokerRecords?.in_blacklist && <BlacklistedTag />}
            </Inline>

            {!brokerRecords?.approved &&
              !brokerRecords?.insurance_certificate_holder && (
                <OrderRequestCargoInsuranceLegacy
                  url={request.cargo_insurance}
                  limit={request.cargo_limit}
                  deductible={request.cargo_deductible}
                  largestTrailerCapacity={request.largest_trailer_capacity}
                  insuranceRequirementsMet={request.insurance_requirements_met}
                />
              )}

            {isCarrierInsuranceExpiresInMonth(
              brokerRecords?.insurance_expires_at,
            ) && (
              <SourceManagerLegacy
                primarySource="Carrier Requests Drawer"
                secondarySource="Cargo Insurance Expiration"
              >
                <CertificateExpirationDetails
                  insuranceExpiresAt={brokerRecords?.insurance_expires_at}
                  certificateFileURl={
                    brokerRecords?.insurance_cert_holder_file_url || null
                  }
                />
              </SourceManagerLegacy>
            )}

            {!brokerRecords?.approved &&
              !!brokerRecords?.accepted_loads_count &&
              brokerRecords.accepted_loads_count > 0 && (
                <TotalOrdersDispatchedLink
                  source="carrier_requests_drawer"
                  carrier={plainToClass(DictionaryCarrierDTO, {
                    name: request.carrier_name,
                    guid: request.carrier_guid,
                  })}
                  loadsCount={brokerRecords.accepted_loads_count}
                />
              )}

            <Typography color="textSecondary" variant="caption">
              Requested {formatRelativeTime(request.created_at)}
            </Typography>
          </Stack>
        </Box>

        {showOrderRequestActions && (
          <OrderRequestActionsBox>
            <OrderRequestCardActionsLegacy
              order={order}
              request={request}
              onSubmitSuccess={onActionSuccess}
              hasPrevCounterOffer={hasPrevCounterOffer}
            />
          </OrderRequestActionsBox>
        )}
      </OrderRequestPaddedBox>
    );
  },
);

OrderRequestCard.displayName = 'OrderRequestCard';
