import {
  AppBar,
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  Color,
  Column,
  Columns,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useNotificationAPI } from 'shared/data/notifications/NotificationAPI';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import {
  getFCMCurrentToken,
  setFCMCurrentToken,
  useFirebaseMessaging,
} from 'shared/helpers/FirebaseHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { CompanyNotifications } from './company-notifications/CompanyNotifications';
import {
  useNotificationSettings,
  useNotificationSettingsAPI,
} from './data/NotificationSettingsAPI';
import { NotificationSettingsDTO } from './data/NotificationSettingsDTO';
import { useNotificationSettingsChannel } from './data/NotificationsSettingsChannel';
import { PersonalNotifications } from './personal-notifications/PersonalNotifications';

const AppBarSettingsV2 = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

export const StyledAlert = styled(Alert)`
  background-color: ${Color.Red50};
  border: 1px solid ${Color.Red500};
  color: ${Color.Red500};

  & .MuiAlert-icon {
    color: ${Color.Red500};
  }
`;

const snackbarStatusText = {
  default: 'Notification settings updated successfully',
  additionalEmailAdded: 'Email subscribed',
  additionalEmailRemoved: 'Email deleted',
  emailSubscribed: 'Email subscribed',
};

export function NotificationSettingsPage() {
  const messaging = useFirebaseMessaging();
  const { subscribeToPushNotification, disablePushNotification } =
    useNotificationAPI();
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const { addSnackbar } = useSnackbarStack();
  const { data: notificationSettings, refetch } = useNotificationSettings();
  const { updateNotificationSettings, resubscribeEmail } =
    useNotificationSettingsAPI();
  const { sendBroadcastMessage } = useNotificationSettingsChannel();

  const [snackbarText, setSnackbarText] = useState('');
  const [lastSmsNotificationPhoneNumber, setLastSmsNotificationPhoneNumber] =
    useState(notificationSettings?.sms_notification_phone_number || null);

  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  let handleSnackbarStatus: () => void;

  useDocumentTitle('Notification Settings');

  const formik = useFormikEnhanced({
    initialValues: { ...notificationSettings },
    async onSubmit(values) {
      handleSnackbarStatus();

      if (messaging) {
        const currentToken = getFCMCurrentToken();

        if (values.is_personal_push_notifications_enabled) {
          const token = await messaging.requestAndGetToken();

          await subscribeToPushNotification(token);
          setFCMCurrentToken(token);
        }

        if (
          currentToken &&
          notificationSettings?.is_personal_push_notifications_enabled &&
          !values.is_personal_push_notifications_enabled
        ) {
          await disablePushNotification(currentToken);
        }
      }

      return updateNotificationSettings(values as NotificationSettingsDTO);
    },
    onSubmitSuccess: (_, values) => {
      trackEventLegacy('Updated Notification Settings', values);

      sendBroadcastMessage(undefined);

      addSnackbar(snackbarText, {
        variant: 'success',
      });

      formik.setSubmitting(true);

      void refetch().then(() => formik.setSubmitting(false));

      setLastSmsNotificationPhoneNumber(null);
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update notification settings', {
        variant: 'error',
      });
    },
  });

  const subscribeEmail = (email: string) => {
    void resubscribeEmail(email).then(() => {
      trackEventLegacy('Subscribed email', { email });

      addSnackbar('Email subscribed', {
        variant: 'success',
      });
    });
  };

  handleSnackbarStatus = () => {
    if (formik.values.additional_email_preferences && notificationSettings) {
      if (
        notificationSettings.additional_email_preferences.length >
        formik.values.additional_email_preferences.length
      ) {
        setSnackbarText(snackbarStatusText.additionalEmailRemoved);
      } else if (
        notificationSettings &&
        notificationSettings.additional_email_preferences.length <
          formik.values.additional_email_preferences.length
      ) {
        setSnackbarText(snackbarStatusText.additionalEmailAdded);
      } else {
        setSnackbarText(snackbarStatusText.default);
      }
    } else {
      setSnackbarText(snackbarStatusText.default);
    }
  };

  const setSmsNotificationPhoneNumber = (value: string | null) => {
    formik.setFieldValue('sms_notification_phone_number', value);
    formik.setFieldTouched('sms_notification_phone_number', true);
  };

  const storeLastSmsPhoneNumber = (value: string | null) => {
    setLastSmsNotificationPhoneNumber(value);
  };

  const AppBarComponent = isSettingsV2Enabled ? AppBarSettingsV2 : AppBar;

  return (
    <PageLayout
      isContentLoading={!notificationSettings}
      header={
        notificationSettings && (
          <AppBarComponent>
            <Toolbar>
              <Box width="100%" mx={2}>
                <Columns>
                  <Column width="fluid">
                    <Typography variant="h2">Notifications</Typography>
                  </Column>

                  {platform === 'desktop' && (
                    <Column width="content">
                      <Button
                        disabled={
                          !formik.dirty ||
                          !formik.isValid ||
                          formik.isSubmitting
                        }
                        isLoading={formik.isSubmitting}
                        variant="contained"
                        type="submit"
                        data-intercom-target="save"
                        onClick={() => {
                          void formik.submitForm();
                        }}
                      >
                        Save
                      </Button>
                    </Column>
                  )}
                </Columns>
              </Box>
            </Toolbar>
          </AppBarComponent>
        )
      }
      footer={
        platform !== 'desktop' && notificationSettings ? (
          <Box
            display="flex"
            position="fixed"
            bottom={platform === 'mobile' ? '56px' : '0'}
            bgcolor={Color.White}
            width="100%"
            px={2}
            py={2}
            justifyContent="flex-start"
            boxShadow={`inset 0px 1px 0px ${Color.Silver400}`}
          >
            <Button
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              isLoading={formik.isSubmitting}
              variant="contained"
              type="submit"
              onClick={() => {
                void formik.submitForm();
              }}
            >
              Save
            </Button>
          </Box>
        ) : null
      }
    >
      <FormikProvider value={formik}>
        <Container maxWidth="md">
          <Box my={2} paddingBottom={12}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <PersonalNotifications
                    notificationSettings={notificationSettings}
                    subscribeEmail={subscribeEmail}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <CompanyNotifications
                    notificationSettings={notificationSettings}
                    subscribeEmail={subscribeEmail}
                    values={formik.values as NotificationSettingsDTO}
                    setSmsNotificationPhoneNumber={
                      setSmsNotificationPhoneNumber
                    }
                    storeLastSmsPhoneNumber={storeLastSmsPhoneNumber}
                    lastSmsPhoneNumber={lastSmsNotificationPhoneNumber}
                    handleSubmit={() => {
                      formik.handleSubmit();
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </FormikProvider>
    </PageLayout>
  );
}
