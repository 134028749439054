import { Typography } from '@material-ui/core';
import { Color } from '@superdispatch/ui';
import { ReactNode } from 'react';
import styled from 'styled-components';
import superLogo from '../assets/super-dispatch-verification-logo.svg';

const VerificationSidebarContainer = styled.div`
  flex: 0 0 auto;
  width: 480px;
  background-color: ${Color.Dark500};
  background-image: url(${superLogo});
  position: relative;
  overflow: hidden;
  color: ${Color.White};
`;

const Gradient = styled.div`
  position: absolute;
  left: 0;
  bottom: -350px;
  width: 664px;
  height: 593px;
  background: radial-gradient(
    55.69% 55.63% at 46.72% 44.46%,
    #8c2842 0%,
    #8c2842 30.5%,
    #384799 100%
  );
  filter: blur(150px);
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 80px 40px;
`;

const CenteredContent = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
`;

export interface VerificationSidebarProps {
  bottomText?: string;
  children: ReactNode;
}

export function VerificationSidebar({
  children,
  bottomText,
}: VerificationSidebarProps) {
  return (
    <VerificationSidebarContainer>
      <Gradient />
      <Content>
        <Typography>&nbsp;</Typography>
        <CenteredContent>{children}</CenteredContent>
        <Typography>{bottomText}</Typography>
      </Content>
    </VerificationSidebarContainer>
  );
}
