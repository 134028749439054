import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import acceptRequestIllustration from 'orders/assets/accept_request_illustration.png';
import emptyIllustration from 'orders/assets/empty_loads.png';
import notifyCarriersIllustration from 'orders/assets/notify_carriers_illustration.png';
import styled from 'styled-components';

const Step = styled.div`
  border-radius: 40px;
  padding: 6px 0;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.Blue50};
  color: ${Color.Blue500};
  font-weight: 600;
  font-size: 14px;
`;

const steps = [
  {
    title: 'We will notify carriers',
    description:
      'To maximize reach, we will alert all carriers whose criteria match your posting.',
    illustration: notifyCarriersIllustration,
  },
  {
    title: 'Review and accept carrier requests',
    description:
      'Review requests, compare prices, and assess ratings to choose the best option.',
    illustration: acceptRequestIllustration,
  },
];

function StepElement({
  number,
  title,
  description,
  illustration,
}: {
  number: number;
  title: string;
  description: string;
  illustration: string;
}) {
  return (
    <Stack space="small">
      <img src={illustration} height="120px" width="168px" />
      <Inline space="small">
        <Step>{number}</Step>
        <Stack space="xsmall">
          <TextBox variant="heading-4">{title}</TextBox>
          <Box maxWidth="300px">
            <TextBox color="secondary">{description}</TextBox>
          </Box>
        </Stack>
      </Inline>
    </Stack>
  );
}

export function OrderRequestsWhatHappensNext() {
  return (
    <Box padding="large">
      <Stack space="large">
        <TextBox variant="heading-3" align="left">
          What happens next?
        </TextBox>
        <Columns collapseBelow="desktop" space="xxlarge">
          {steps.map(({ title, description, illustration }, index) => (
            <Column key={index}>
              <StepElement
                number={index + 1}
                title={title}
                description={description}
                illustration={illustration}
              />
            </Column>
          ))}
        </Columns>
      </Stack>
    </Box>
  );
}

export function OrderRequestsEmpty() {
  return (
    <Box padding="large">
      <Stack space="medium" align="center">
        <img src={emptyIllustration} alt="empty-illustration" height="80px" />

        <TextBox variant="heading-3" align="center">
          This order is not posted <br /> and hasn’t received any requests.
        </TextBox>
      </Stack>
    </Box>
  );
}
