import { InputAdornment, MenuItem, Typography } from '@material-ui/core';
import {
  FormikCheckboxField,
  FormikNumberField,
  FormikPatternField,
  FormikTextField,
} from '@superdispatch/forms';
import { Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import {
  JOB_TITLES,
  JOB_TITLE_DISPLAY_NAMES,
  USA_STATES,
} from 'superpay/data/MoovDTO';
import { OwnerEditFormValues } from 'superpay/owners/OwnerEditFormValues';
import { OwnerAddFormValues } from './OwnerAddFormValues';
import { SSNField } from './OwnerSSNField';

interface BusinessOwnerFormProps {
  withControllerCheckbox?: boolean;
}

export function OwnerFormFields({
  withControllerCheckbox = false,
}: BusinessOwnerFormProps) {
  const formikContext = useFormikContext<
    OwnerAddFormValues | OwnerEditFormValues
  >();
  const { values, setFieldValue, dirty, getFieldMeta } = formikContext;
  const birthDateFieldMeta = getFieldMeta('birthDate');
  const ssnFieldMeta = getFieldMeta('governmentID.ssn.full');

  const query = useSearchInput(
    dirty && values.address.postalCode ? values.address.postalCode : '',
  );
  const { data: places = [] } = usePredictions(query);

  useEffect(() => {
    if (places.length > 0 && dirty) {
      setFieldValue('address.city', places[0]?.place);
      setFieldValue('address.stateOrProvince', places[0]?.region_short);
    }
  }, [dirty, places, setFieldValue]);

  return (
    <Stack space="medium">
      <Stack space="small">
        <Columns collapseBelow="tablet" space="small">
          <Column width="1/2">
            <FormikTextField
              label="First Name"
              name="name.firstName"
              fullWidth={true}
            />
          </Column>
          <Column width="1/2">
            <FormikTextField
              label="Last Name"
              name="name.lastName"
              fullWidth={true}
            />
          </Column>
        </Columns>

        <Columns collapseBelow="tablet" space="small">
          <Column width="1/2">
            <FormikTextField
              label="Job Title"
              name="responsibilities.jobTitle"
              placeholder="Select option"
              select={true}
              fullWidth={true}
            >
              {JOB_TITLES.map((jobTitle) => (
                <MenuItem key={jobTitle} value={jobTitle}>
                  {JOB_TITLE_DISPLAY_NAMES[jobTitle]}
                </MenuItem>
              ))}
            </FormikTextField>
          </Column>
          <Column width="1/2">
            <FormikNumberField
              label="Percent Ownership"
              placeholder="25-100"
              name="responsibilities.ownershipPercentage"
              fullWidth={true}
              inputProps={{ decimalScale: 0, maxLength: 3 }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Column>
        </Columns>

        <Columns space="small">
          <Column width="1/2">
            <SSNField
              label="SSN"
              onChange={(ssnValue: string) =>
                setFieldValue('governmentID.ssn.full', ssnValue)
              }
              errorMessage={ssnFieldMeta.error}
            />
          </Column>
        </Columns>

        <Columns space="small" collapseBelow="tablet">
          <Column width="1/2">
            <FormikPatternField
              name="phone"
              fullWidth={true}
              mask="_"
              format="+1 ( ### ) ### - ####"
              allowEmptyFormatting={true}
              label={
                <Inline space="xxsmall">
                  <Typography>Phone</Typography>
                  <Typography color="textSecondary">
                    (US numbers only)
                  </Typography>
                </Inline>
              }
            />
          </Column>
          <Column>
            <FormikTextField label="Email" name="email" fullWidth={true} />
          </Column>
        </Columns>

        <Stack space="xxsmall">
          <Typography>Date of Birth</Typography>
          <Inline space="small">
            <Box maxWidth="48px">
              <FormikNumberField
                label="Month"
                name="birthDate.month"
                placeholder="••"
                inputProps={{ maxLength: 2 }}
                formatError={() => null}
              />
            </Box>
            <Box maxWidth="48px">
              <FormikNumberField
                label="Day"
                name="birthDate.day"
                placeholder="••"
                inputProps={{ maxLength: 2 }}
                formatError={() => null}
              />
            </Box>
            <Box maxWidth="72px">
              <FormikNumberField
                label="Year"
                name="birthDate.year"
                placeholder="••••"
                inputProps={{ maxLength: 4, thousandSeparator: false }}
                formatError={() => null}
              />
            </Box>
          </Inline>
          {!!birthDateFieldMeta.error && birthDateFieldMeta.touched && (
            <Typography color="error">Invalid Date of Birth</Typography>
          )}
        </Stack>
      </Stack>

      <Stack space="small">
        <Stack space="xsmall">
          <Typography variant="h4">Residential Address</Typography>
          <Typography color="textSecondary">
            P.O. boxes and commercial addresses are not eligible.
          </Typography>
        </Stack>

        <FormikTextField
          label="Street Address"
          name="address.addressLine1"
          fullWidth={true}
          inputProps={{ maxLength: 60 }}
        />

        <Columns space="small">
          <Column width="1/4">
            <FormikTextField
              label="ZIP"
              name="address.postalCode"
              fullWidth={true}
            />
          </Column>

          <Column width="1/2">
            <FormikTextField
              label="City"
              name="address.city"
              fullWidth={true}
              inputProps={{ maxLength: 24 }}
            />
          </Column>

          <Column width="1/4">
            <FormikTextField
              label="State"
              name="address.stateOrProvince"
              fullWidth={true}
              select={true}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="" disabled={true}>
                <Typography color="textSecondary">Select</Typography>
              </MenuItem>
              {USA_STATES.map((code) => (
                <MenuItem key={code} value={code}>
                  {code}
                </MenuItem>
              ))}
            </FormikTextField>
          </Column>
        </Columns>
      </Stack>

      {withControllerCheckbox && (
        <Box>
          <FormikCheckboxField
            label="This person is a company controller"
            helperText={
              <Box>
                Every business requires a controller, such as the CEO, CFO, COO,
                President, Vice President, or Managing Partner.
              </Box>
            }
            name="responsibilities.isController"
          />
        </Box>
      )}
    </Stack>
  );
}
