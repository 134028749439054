import { Link } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, Work } from '@material-ui/icons';
import { formatRelativeTime } from '@superdispatch/dates';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { parseURITemplate } from '@superdispatch/uri';
import { plainToClass } from 'class-transformer';
import { UnverifiedTag, VerifiedTag } from 'core/CarrierTags';
import { DictionaryCarrierDTO } from 'core/dictionary/data/DictionaryDTO';
import { isCarrierInsuranceExpiresInMonth } from 'core/insurance/ExpirationDates';
import { CertificateExpirationDetails } from 'core/insurance/InsuranceExpirationInfo';
import { RatingStats } from 'core/ratings/RatingStats';
import { TotalOrdersDispatchedLink } from 'core/TotalOrdersDispatchedLink';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import { TextOverflow } from 'shared/ui/TextOverflow';
import { RequestStatusInfo, requestStatusInfo } from './OrderRequest';
import {
  OrderRequestCargoInsurance,
  OrderRequestCargoInsuranceBelowMinimum,
} from './OrderRequestCargoInsurance';
import { OrderRequestCarrierTags } from './OrderRequestCarrierTags';
import { OrderRequestSuperPayActions } from './OrderRequestSuperPayActions';

interface OrderRequestDetailsProps {
  order: Order;
  request: OrderRequestDTO;
  isSuperPay: boolean;
  setSuperPay: (checked: boolean) => void;
  isSuperPayDisabled: boolean;
}

export function OrderRequestDetails({
  order,
  request,
  isSuperPay,
  setSuperPay,
  isSuperPayDisabled,
}: OrderRequestDetailsProps) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const {
    status,
    changed_at,
    created_at,
    broker_records,
    carrier_guid,
    carrier_name,
    is_super_carrier,
    carrier_phone,
    carrier_usdot,
    carrier_rating_stats,
    carrier_contact_name,
    cargo_insurance,
    cargo_limit,
    carrier_email,
    cargo_deductible,
    largest_trailer_capacity,
    insurance_requirements_met,
  } = request;

  const [expandedRef, setExpandedRef] = useState<HTMLDivElement | null>(null);
  const canExpand = expandedRef?.childNodes.length;

  return (
    <Stack space="xsmall">
      {requestStatusInfo[status] && (
        <RequestStatusInfo status={status} date={changed_at} />
      )}

      <Stack space="xsmall">
        <Inline space="xsmall">
          <TextOverflow size={280}>
            <Link
              target="_blank"
              component={RouterLink}
              to={parseURITemplate(
                `/manage-carriers/${carrier_guid}/{?utm_medium}`,
                {
                  utm_medium: 'Requests Drawer',
                },
              )}
            >
              <TextBox variant="heading-5">{carrier_name}</TextBox>
            </Link>
          </TextOverflow>
          {is_super_carrier ? <VerifiedTag /> : <UnverifiedTag />}
          <RatingStats
            ratingStats={carrier_rating_stats}
            carrierGuid={carrier_guid}
          />
        </Inline>

        <Inline space="xxsmall">
          <TextBox color="secondary">{carrier_contact_name}</TextBox>
          &#xb7;
          <Link href={`tel:${carrier_phone}`}>
            <TextBox color="secondary">{carrier_phone}</TextBox>
          </Link>
        </Inline>
      </Stack>

      {/* Add extra space */}
      <div />

      {!expanded && (
        <Stack space="small">
          <OrderRequestCarrierTags request={request} compact={true} />

          <OrderRequestSuperPayActions
            variants={['accepts-superpay-change-terms']}
            isDisabled={isSuperPayDisabled}
            request={request}
            order={order}
            isSuperPay={isSuperPay}
            setSuperPay={setSuperPay}
          />

          {!broker_records?.approved &&
            !broker_records?.insurance_certificate_holder && (
              <OrderRequestCargoInsuranceBelowMinimum
                url={cargo_insurance}
                largestTrailerCapacity={largest_trailer_capacity}
                insuranceRequirementsMet={insurance_requirements_met}
              />
            )}

          {broker_records &&
            isCarrierInsuranceExpiresInMonth(
              broker_records.insurance_expires_at,
            ) && (
              <CertificateExpirationDetails
                insuranceExpiresAt={broker_records.insurance_expires_at}
                certificateFileURl={
                  broker_records.insurance_cert_holder_file_url
                }
              />
            )}
        </Stack>
      )}

      <Box display={expanded ? 'block' : 'none'}>
        <Stack space="xsmall">
          {broker_records && <OrderRequestCarrierTags request={request} />}

          <Stack
            space="xsmall"
            ref={(node) => {
              setExpandedRef(node);
            }}
          >
            {!broker_records?.approved &&
              !broker_records?.insurance_certificate_holder && (
                <OrderRequestCargoInsuranceBelowMinimum
                  url={cargo_insurance}
                  largestTrailerCapacity={largest_trailer_capacity}
                  insuranceRequirementsMet={insurance_requirements_met}
                />
              )}

            {!broker_records?.approved &&
              !broker_records?.insurance_certificate_holder && (
                <OrderRequestCargoInsurance
                  url={cargo_insurance}
                  limit={cargo_limit}
                  deductible={cargo_deductible}
                  largestTrailerCapacity={largest_trailer_capacity}
                />
              )}

            {isCarrierInsuranceExpiresInMonth(
              broker_records?.insurance_expires_at,
            ) && (
              <CertificateExpirationDetails
                insuranceExpiresAt={broker_records?.insurance_expires_at}
                certificateFileURl={
                  broker_records?.insurance_cert_holder_file_url || null
                }
              />
            )}

            {!!broker_records?.accepted_loads_count && (
              <Inline space="xsmall" verticalAlign="center">
                <Work fontSize="small" color="action" />
                <TotalOrdersDispatchedLink
                  carrier={plainToClass(DictionaryCarrierDTO, {
                    guid: carrier_guid,
                    name: carrier_name,
                    usdot_number: carrier_usdot,
                    phone: carrier_phone,
                    email: carrier_email,
                  })}
                  source="load_offer_drawer"
                  loadsCount={broker_records.accepted_loads_count}
                />
              </Inline>
            )}

            <OrderRequestSuperPayActions
              isDisabled={isSuperPayDisabled}
              request={request}
              order={order}
              isSuperPay={isSuperPay}
              setSuperPay={setSuperPay}
            />
          </Stack>
        </Stack>
      </Box>

      <TextBox color="secondary" variant="caption">
        Requested {formatRelativeTime(created_at)}
      </TextBox>

      {canExpand && (
        <Box marginLeft="-small">
          <Button
            variant="text"
            onClick={() => {
              setExpanded(!expanded);
              if (!expanded) {
                trackEvent('Shipper Clicked Show More on Carrier Request', {
                  utm_medium: 'Order Requests',
                });
              }
            }}
            endIcon={expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {expanded ? 'Show less' : 'Show more'}
          </Button>
        </Box>
      )}
    </Stack>
  );
}
