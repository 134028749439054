import { Typography } from '@material-ui/core';
import { useFormikEnhanced } from '@superdispatch/forms';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { FeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { List } from 'shared/ui/List';
import { useShipperProfileAPI } from '../shipper-profile/data/ShipperProfileAPI';
import { ShipperProfileDTO } from '../shipper-profile/data/ShipperProfileDTO';
import {
  MoreInformationExpandingBlock,
  SectionSwitch,
  SectionSwitchCard,
  SuperLoadboardSettingsSection,
} from './core/SuperLoadboardSettingsSection';
import { trackUpdatedSuperLoadboardSettingsValues } from './data/SuperLoadboardAnalytics';

const AUTO_REPOST_SWITCHES = [
  {
    title: 'Carrier Declined Offers',
    SwitchComponent: AutoRepostDeclinedSwitch,
    featureToggle: 'slb-settings.auto-repost-toggle.declined.ui',
  },
  {
    title: 'Carrier Cancelled Offers',
    SwitchComponent: AutoRepostCancelledSwitch,
    featureToggle: 'slb-settings.auto-repost-toggle.cancelled.ui',
  },
] as const;

export function SuperLoadboardAutoRepost({
  profile,
}: {
  profile: ShipperProfileDTO;
}) {
  return (
    <SuperLoadboardSettingsSection aria-label="Auto Repost">
      <Stack space="small">
        <Stack space="xxsmall">
          <Typography variant="h4">Repost Orders Automatically</Typography>
          <Typography color="textSecondary">Only to Super Loadboard</Typography>
        </Stack>

        <Stack space="medium">
          {AUTO_REPOST_SWITCHES.map(
            ({ title, SwitchComponent, featureToggle }) => (
              <FeatureToggle key={title} name={featureToggle}>
                <SectionSwitchCard>
                  <Columns align="center">
                    <Column width="fluid">{title}</Column>
                    <Column width="content">
                      <SwitchComponent profile={profile} />
                    </Column>
                  </Columns>
                </SectionSwitchCard>
              </FeatureToggle>
            ),
          )}
        </Stack>

        <MoreInformationExpandingBlock>
          <Typography color="textSecondary">
            Loads won&apos;t be reposted if the carrier cancels for any of these
            reasons:
          </Typography>

          <List>
            <li>
              <Typography color="textSecondary">Unit Not Ready</Typography>
            </li>
            <li>
              <Typography color="textSecondary">
                Unknown Inoperable Vehicle
              </Typography>
            </li>
            <li>
              <Typography color="textSecondary">
                Carrier leaves a note
              </Typography>
            </li>
          </List>
        </MoreInformationExpandingBlock>
      </Stack>
    </SuperLoadboardSettingsSection>
  );
}

interface AutoRepostSwitchProps {
  profile: ShipperProfileDTO;
  fieldName: keyof ShipperProfileDTO;
  onSubmitSuccess: (updatedValues: ShipperProfileDTO) => void;
}

function AutoRepostCancelledSwitch({
  profile,
}: {
  profile: ShipperProfileDTO;
}) {
  return (
    <AutoRepostSwitch
      profile={profile}
      fieldName="is_auto_repost_for_cancel_offers_enabled"
      onSubmitSuccess={(updatedValues) => {
        trackUpdatedSuperLoadboardSettingsValues({
          name: 'Auto Repost Cancelled Automatically',
          is_auto_repost_for_cancel_offers_enabled:
            updatedValues.is_auto_repost_for_cancel_offers_enabled,
        });
      }}
    />
  );
}

function AutoRepostDeclinedSwitch({ profile }: { profile: ShipperProfileDTO }) {
  return (
    <AutoRepostSwitch
      profile={profile}
      fieldName="is_auto_repost_for_decline_offers_enabled"
      onSubmitSuccess={(updatedValues) => {
        trackUpdatedSuperLoadboardSettingsValues({
          name: 'Auto Repost Declined Automatically',
          is_auto_repost_for_declined_offers_enabled:
            updatedValues.is_auto_repost_for_decline_offers_enabled,
        });
      }}
    />
  );
}

function AutoRepostSwitch({
  profile,
  fieldName,
  onSubmitSuccess,
}: AutoRepostSwitchProps) {
  const { addSnackbar } = useSnackbarStack();
  const { updateLoadboardSettings } = useShipperProfileAPI();
  const canUpdateProfile = useCanExecute('UPDATE_COMPANY_PROFILE');

  const formik = useFormikEnhanced({
    initialValues: profile,
    onSubmit: (values) => {
      return updateLoadboardSettings(values);
    },
    onSubmitSuccess: (_, updatedValues) => {
      onSubmitSuccess(updatedValues);
      addSnackbar('Settings updated', {
        variant: 'success',
      });
    },
    onSubmitFailure(submitError) {
      addSnackbar(submitError.message, { variant: 'error' });
    },
  });

  return (
    <FormikProvider value={formik}>
      <SectionSwitch
        disabled={!canUpdateProfile || formik.isSubmitting}
        checked={formik.values[fieldName] as boolean}
        onChange={(_, checked) => {
          formik.setFieldValue(fieldName, checked);
          void formik.submitForm();
        }}
      />
    </FormikProvider>
  );
}
