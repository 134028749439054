import { useEffect } from 'react';
import { onAnalyticsReady } from './AnalyticsHelpers';
import './Intercom.css';
import { readStorageItem, writeStorageItem } from './LocalStorage';

window.intercomSettings = {
  ...window.intercomSettings,
  vertical_padding: 72,
};

export function executeCommand<C extends Intercom_.IntercomCommand>(
  command: C,
  ...params: Parameters<Intercom_.IntercomCommandSignature[C]>
) {
  onAnalyticsReady(() => {
    if (typeof Intercom !== 'undefined') {
      if (command === 'update' && typeof params[0] === 'object') {
        window.intercomSettings = {
          ...window.intercomSettings,
          ...(params[0] as typeof window.intercomSettings),
        };
      }

      Intercom(command, ...params);
    }
  });
}

const INTERCOM_TOURS = {
  load_matching_intercom_tour_view_count: {
    staging: 460772,
    production: 460363,
  },
};

export type IntercomTourKey = keyof typeof INTERCOM_TOURS;

export function launchIntercomTour(tourKey: IntercomTourKey) {
  const hasSeenTour = readStorageItem(tourKey);
  if (!hasSeenTour) {
    startIntercomTour(
      import.meta.env.VITE_TARGET === 'production'
        ? INTERCOM_TOURS[tourKey].production
        : INTERCOM_TOURS[tourKey].staging,
    );
    writeStorageItem(tourKey, 'true');
  }
}

export function showIntercomButton() {
  executeCommand('update', { hide_default_launcher: false });
}

export function hideIntercomButton() {
  executeCommand('update', { hide_default_launcher: true });
}

export function updateIntercom() {
  executeCommand('update', { last_request_at: Math.floor(Date.now() / 1000) });
}

export function showIntercomDialog() {
  executeCommand('show');
}

export function hideIntercomDialog() {
  executeCommand('hide');
}

export function showNewMessage(message: string) {
  executeCommand('showNewMessage', message);
}

export function shutdownIntercom() {
  executeCommand('shutdown');
}

export function startIntercomTour(tourId: number): void {
  executeCommand('startTour', tourId);
}

export function startIntercomSurvey(surveyId: number): void {
  executeCommand('startSurvey', surveyId);
}

export function useHideIntercomButton() {
  useEffect(() => {
    hideIntercomButton();
    return showIntercomButton;
  }, []);
}

export function useShowIntercomButton() {
  useEffect(() => {
    showIntercomButton();
    return hideIntercomButton;
  }, []);
}

export function HideIntercomPopup() {
  useHideIntercomButton();
  return null;
}
