import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import { useEffect } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { LoadingContainer } from '../shared/ui/LoadingContainer';
import { useShipperProfile } from '../shipper-profile/data/ShipperProfileAPI';
import { SuperLoadboardAutoRepost } from './SuperLoadboardAutoRepost';
import { SuperLoadboardHideContacts } from './SuperLoadboardHideContacts';
import { SuperLoadboardInstantBooking } from './SuperLoadboardInstantBooking';
import { SuperLoadboardPrivateNetwork } from './SuperLoadboardPrivateNetwork';

const StyledAppBar = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

export function SuperLoadboardSettingsPage() {
  const { addSnackbar } = useSnackbarStack();

  const { data: profile, error } = useShipperProfile();

  const isAutoRepostCancelledEnabled = useFeatureToggle(
    'slb-settings.auto-repost-toggle.cancelled.ui',
  );
  const isAutoRepostDeclinedEnabled = useFeatureToggle(
    'slb-settings.auto-repost-toggle.declined.ui',
  );
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');
  const isAutoRepostEnabled =
    isAutoRepostCancelledEnabled || isAutoRepostDeclinedEnabled;

  useEffect(() => {
    if (error) {
      addSnackbar(error.message, { variant: 'error' });
    }
  }, [error, addSnackbar]);

  if (!profile) {
    return <LoadingContainer />;
  }

  const toolbar = (
    <Toolbar>
      <Box marginLeft="small" marginRight="small">
        <Typography variant="h2">Super Loadboard</Typography>
      </Box>
    </Toolbar>
  );

  return (
    <PageLayout
      withContentPadding={true}
      header={
        isSettingsV2Enabled ? <StyledAppBar>{toolbar}</StyledAppBar> : toolbar
      }
    >
      <Stack space="small">
        <SuperLoadboardInstantBooking profile={profile} />
        <SuperLoadboardHideContacts profile={profile} />
        {isAutoRepostEnabled && <SuperLoadboardAutoRepost profile={profile} />}
        <SuperLoadboardPrivateNetwork profile={profile} />
      </Stack>
    </PageLayout>
  );
}
