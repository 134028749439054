import { Link, Typography } from '@material-ui/core';
import { AttachMoney, Person, Phone, PictureAsPdf } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { SuspendedPhoneLink } from '@superdispatch/phones';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { DescriptionItem } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { CalendarIcon } from 'shared/icons/CalendarIcon';
import { HashtagIcon } from 'shared/icons/HashtagIcon';
import { CarrierFullInfo, Insurance } from 'shared/types/carrier';
import styled from 'styled-components';

const ResponsivePaddedItem = styled.div`
  margin-left: 32px;

  @media (min-width: 600px) {
    margin-left: 24px;
  }

  @media (min-width: 960px) {
    margin-left: 0;
  }
`;

interface CarrierInsuranceProps {
  insurances: Insurance[] | null;
}

export function CarrierInsurance({ insurances }: CarrierInsuranceProps) {
  const currentInsurance = insurances?.find(
    (insurance) => insurance.is_current,
  );

  if (!currentInsurance) {
    return (
      <Stack space="small">
        <Typography variant="h4">Cargo Insurance</Typography>
        <Typography color="textSecondary">No Information Provided</Typography>
      </Stack>
    );
  }

  return (
    <Stack space="small">
      <Typography variant="h4">Cargo Insurance</Typography>

      {currentInsurance.file_url && (
        <DescriptionItem icon={<PictureAsPdf />}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            aria-label="insurance certificate"
            href={currentInsurance.file_url}
            onClick={() =>
              trackEvent('[STMS] Clicked Carrier Insurance Certificate')
            }
          >
            Insurance Certificate
          </Link>
        </DescriptionItem>
      )}

      <Stack space={['xxsmall', 'xsmall']}>
        {currentInsurance.policy_expiration_date && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<CalendarIcon />}>
                <Typography color="textSecondary" component="span">
                  Expiration Date
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Expiration Date">
                <DescriptionItem>
                  <FormattedDate
                    variant="Date"
                    format="DateISO"
                    date={currentInsurance.policy_expiration_date}
                  />
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.cargo_limit != null && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<AttachMoney />}>
                <Typography color="textSecondary" component="span">
                  Cargo Limit
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Cargo Limit">
                <DescriptionItem>
                  {formatCurrency(Number(currentInsurance.cargo_limit), {
                    minimumFractionDigits: 0,
                  })}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.deductible_amount != null && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<AttachMoney />}>
                <Typography color="textSecondary" component="span">
                  Deductible
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem aria-label="Deductible">
                <DescriptionItem>
                  {formatCurrency(Number(currentInsurance.deductible_amount), {
                    minimumFractionDigits: 0,
                  })}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.auto_policy_number && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<HashtagIcon />}>
                <Typography color="textSecondary" component="span">
                  Auto Policy
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem>
                <DescriptionItem>
                  {currentInsurance.auto_policy_number}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}

        {currentInsurance.cargo_policy_number && (
          <Columns collapseBelow="desktop">
            <Column>
              <DescriptionItem icon={<HashtagIcon />}>
                <Typography color="textSecondary" component="span">
                  Cargo Policy
                </Typography>
              </DescriptionItem>
            </Column>
            <Column>
              <ResponsivePaddedItem>
                <DescriptionItem>
                  {currentInsurance.cargo_policy_number}
                </DescriptionItem>
              </ResponsivePaddedItem>
            </Column>
          </Columns>
        )}
      </Stack>

      {!!currentInsurance.agent_name && !!currentInsurance.agent_phone && (
        <Stack space="xsmall">
          <Typography variant="h6" color="textSecondary">
            Agent
          </Typography>

          <Stack space="xxsmall">
            {currentInsurance.agent_name && (
              <DescriptionItem icon={<Person />}>
                <Typography aria-label="cargo agent name">
                  {currentInsurance.agent_name}
                </Typography>
              </DescriptionItem>
            )}
            {currentInsurance.agent_phone && (
              <DescriptionItem icon={<Phone />}>
                <SuspendedPhoneLink
                  aria-label="cargo agent phone"
                  phone={currentInsurance.agent_phone}
                  fallback={currentInsurance.agent_phone}
                />
              </DescriptionItem>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

interface CarrierInsuranceLegacyProps {
  carrier: CarrierFullInfo;
}

export function CarrierInsuranceLegacy({
  carrier,
}: CarrierInsuranceLegacyProps) {
  const hasCargoInformation = useMemo(
    () =>
      Boolean(
        carrier.cargo_insurance ||
          (carrier.cargo_policy &&
            Object.values(carrier.cargo_policy).filter(Boolean).length),
      ),
    [carrier.cargo_insurance, carrier.cargo_policy],
  );

  return (
    <Stack space="small">
      <Typography variant="h4">Cargo Insurance</Typography>

      <Stack space="small">
        {hasCargoInformation ? (
          <>
            {carrier.cargo_insurance && (
              <DescriptionItem icon={<PictureAsPdf />}>
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="insurance certificate"
                  href={carrier.cargo_insurance}
                  onClick={() =>
                    trackEvent('[STMS] Clicked Carrier Insurance Certificate')
                  }
                >
                  Insurance Certificate
                </Link>
              </DescriptionItem>
            )}

            <Stack space={['xxsmall', 'xsmall']}>
              {carrier.cargo_policy?.policy_expiration_date && (
                <Columns collapseBelow="desktop">
                  <Column>
                    <DescriptionItem icon={<CalendarIcon />}>
                      <Typography color="textSecondary" component="span">
                        Expiration Date
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <ResponsivePaddedItem aria-label="Expiration Date">
                      <DescriptionItem>
                        <FormattedDate
                          variant="Date"
                          format="DateISO"
                          date={carrier.cargo_policy.policy_expiration_date}
                        />
                      </DescriptionItem>
                    </ResponsivePaddedItem>
                  </Column>
                </Columns>
              )}

              {carrier.cargo_policy?.limit != null && (
                <Columns collapseBelow="desktop">
                  <Column>
                    <DescriptionItem icon={<AttachMoney />}>
                      <Typography color="textSecondary" component="span">
                        Cargo Limit
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <ResponsivePaddedItem aria-label="Cargo Limit">
                      <DescriptionItem>
                        {formatCurrency(Number(carrier.cargo_policy.limit), {
                          minimumFractionDigits: 0,
                        })}
                      </DescriptionItem>
                    </ResponsivePaddedItem>
                  </Column>
                </Columns>
              )}

              {carrier.cargo_policy?.deductible != null && (
                <Columns collapseBelow="desktop">
                  <Column>
                    <DescriptionItem icon={<AttachMoney />}>
                      <Typography color="textSecondary" component="span">
                        Deductible
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <ResponsivePaddedItem aria-label="Deductible">
                      <DescriptionItem>
                        {formatCurrency(
                          Number(carrier.cargo_policy.deductible),
                          {
                            minimumFractionDigits: 0,
                          },
                        )}
                      </DescriptionItem>
                    </ResponsivePaddedItem>
                  </Column>
                </Columns>
              )}

              {carrier.cargo_policy?.auto_policy_number && (
                <Columns collapseBelow="desktop">
                  <Column>
                    <DescriptionItem icon={<HashtagIcon />}>
                      <Typography color="textSecondary" component="span">
                        Auto Policy
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <ResponsivePaddedItem>
                      <DescriptionItem>
                        {carrier.cargo_policy.auto_policy_number}
                      </DescriptionItem>
                    </ResponsivePaddedItem>
                  </Column>
                </Columns>
              )}

              {carrier.cargo_policy?.cargo_policy_number && (
                <Columns collapseBelow="desktop">
                  <Column>
                    <DescriptionItem icon={<HashtagIcon />}>
                      <Typography color="textSecondary" component="span">
                        Cargo Policy
                      </Typography>
                    </DescriptionItem>
                  </Column>
                  <Column>
                    <ResponsivePaddedItem>
                      <DescriptionItem>
                        {carrier.cargo_policy.cargo_policy_number}
                      </DescriptionItem>
                    </ResponsivePaddedItem>
                  </Column>
                </Columns>
              )}
            </Stack>

            {!!carrier.cargo_policy?.agent_name &&
              !!carrier.cargo_policy.agent_phone && (
                <Stack space="xsmall">
                  <Typography variant="h6" color="textSecondary">
                    Agent
                  </Typography>

                  <Stack space="xxsmall">
                    {carrier.cargo_policy.agent_name && (
                      <DescriptionItem icon={<Person />}>
                        <Typography aria-label="cargo agent name">
                          {carrier.cargo_policy.agent_name}
                        </Typography>
                      </DescriptionItem>
                    )}
                    {carrier.cargo_policy.agent_phone && (
                      <DescriptionItem icon={<Phone />}>
                        <SuspendedPhoneLink
                          aria-label="cargo agent phone"
                          phone={carrier.cargo_policy.agent_phone}
                          fallback={carrier.cargo_policy.agent_phone}
                        />
                      </DescriptionItem>
                    )}
                  </Stack>
                </Stack>
              )}
          </>
        ) : (
          <Typography color="textSecondary">No Information Provided</Typography>
        )}
      </Stack>
    </Stack>
  );
}
