import { useAtom } from 'jotai';
import postToSLBImg from './assets/post-to-slb-img.png';
import { isFirstOrderCreatedAndOfferSendAtom } from './atoms/OnboardingAtoms';
import { OnboardingNudgingDialog } from './OnboardingNudgingDialog';

export function OnboardingOnSendOfferPostToSLBNudgingDialog() {
  const [isFirstOrderCreatedAndOfferSend, setIsFirstOrderCreatedAndOfferSend] =
    useAtom(isFirstOrderCreatedAndOfferSendAtom);

  if (!isFirstOrderCreatedAndOfferSend) {
    return null;
  }

  return (
    <OnboardingNudgingDialog
      image={<img src={postToSLBImg} alt="post to slb" width="212px" />}
      localStorageKey="is_on_send_offer_post_to_slb_onboarding_nudging_closed"
      onClose={() => {
        setIsFirstOrderCreatedAndOfferSend(false);
      }}
      primaryActionText="Got it"
      overline="Congratulations with sending a load offer!"
      title="Next time, Post to Super Loadboard"
      subtitle="Posting to Super Loadboard will maximize your chances to move the load fast."
    />
  );
}
