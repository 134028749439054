import requestReceived from './assets/review-requests-img.png';
import { OnboardingNudgingDialog } from './OnboardingNudgingDialog';

interface OnboardingOnRequestReceivedNudgingDialogProps {
  open: boolean;
}

export function OnboardingOnRequestReceivedNudgingDialog({
  open,
}: OnboardingOnRequestReceivedNudgingDialogProps) {
  return (
    <OnboardingNudgingDialog
      image={<img src={requestReceived} alt="post to slb" width="131px" />}
      localStorageKey="is_request_received_onboarding_nudging_closed"
      secondaryActionText="Do it later"
      primaryActionText="Go to Requests"
      open={open}
      overline="You received the 1st Load Request from a carrier!"
      title="Next, Review Load Requests and take actions"
      subtitle="You are very close to move cars. Review Load Requests, if they meet your expectations, accept them."
    />
  );
}
