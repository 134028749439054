import { useQueryClient } from 'react-query';
import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  SubscriptionPlanCode,
  subscriptionPlanCodes,
} from 'shared/subscription-plans/useSubscriptionPlans';
import {
  yupArray,
  yupEnum,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const ADDON_TYPES = ['VIN_DELIVERED', 'VIN_POSTED'] as const;

const apiAddonSchema = yupObject({
  max_item_count: yupNumber().required(),
  overage_price: yupNumber().required(),
  type: yupEnum(ADDON_TYPES, null).required(),
});

const apiSubscriptionPlanSchema = yupObject({
  plan_id: yupString().nullable(),
  plan_name: yupString().required(),
  plan_price: yupNumber().required(),
  plan_code: yupString().oneOf(subscriptionPlanCodes).required(),
  plan_vehicle_count: yupNumber().required(),
  overage_price_per_vehicle: yupNumber().nullable(),
  next_billing_date: yupString().nullable(),
  active_till_date: yupString().nullable(),
  total_price: yupNumber().nullable(),
  tax_rate: yupNumber().nullable(),
  tax_juris_code: yupString().nullable(),
  addons: yupArray(apiAddonSchema).required(),
});

type ApiSubscriptionPlan = InferType<typeof apiSubscriptionPlanSchema>;
type ApiSubscriptionPlanAddon = InferType<typeof apiAddonSchema>;
export interface SubscriptionPlan {
  planId: string | null;
  plan: string;
  planCode: SubscriptionPlanCode;
  monthlyPlanPrice: number;
  maxPlanVehicles: number;
  perVehiclePrice: number | null;
  renewalDate: string | null;
  activeTillDate: string | null;
  totalPrice: number | null;
  taxPercent: number | null;
  taxJurisCode: string | null;
  isNonStandard: boolean;
  addons: ApiSubscriptionPlanAddon[];
}

interface UseSubscriptionPlanReturn {
  plan: SubscriptionPlan | undefined;
  isPlanLoading: boolean;
  planError: Error | null;
}

function toPlan(apiPlan: ApiSubscriptionPlan) {
  return {
    planId: apiPlan.plan_id,
    plan: apiPlan.plan_name,
    planCode: apiPlan.plan_code,
    monthlyPlanPrice: apiPlan.plan_price,
    maxPlanVehicles: apiPlan.plan_vehicle_count,
    perVehiclePrice: apiPlan.overage_price_per_vehicle,
    renewalDate: apiPlan.next_billing_date,
    activeTillDate: apiPlan.active_till_date,
    totalPrice: apiPlan.total_price,
    taxPercent: apiPlan.tax_rate,
    taxJurisCode: apiPlan.tax_juris_code,
    isNonStandard: apiPlan.plan_id === null,
    addons: apiPlan.addons,
  };
}

const PLAN_CACHE_KEY = ['subscription-details', 'subscription-plan'] as const;

export function useInvalidateSubscriptionPlanQuery() {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(PLAN_CACHE_KEY);
}

export function useSubscriptionPlan(): UseSubscriptionPlanReturn {
  const { requestResource } = useAPI();
  const {
    data: apiSubscriptionPlan,
    isLoading,
    error,
  } = useAPIQuery(
    PLAN_CACHE_KEY,
    () =>
      requestResource(
        'GET /internal/subscription/plan',
        (data) => data as ApiSubscriptionPlan,
      ),
    { schema: apiSubscriptionPlanSchema },
  );

  const plan = apiSubscriptionPlan ? toPlan(apiSubscriptionPlan) : undefined;

  return {
    plan,
    isPlanLoading: isLoading,
    planError: error,
  };
}
