import { useRef } from 'react';

export function useCallbackRef<T extends (...args: any[]) => unknown>(
  callback: T | undefined,
) {
  const ref = useRef(callback);
  ref.current = callback;

  const callbackRef = useRef((...args: Parameters<T>) => {
    return ref.current?.(...args);
  });

  return callbackRef.current;
}
