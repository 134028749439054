import { CircularProgress, Link, Tooltip, Typography } from '@material-ui/core';
import { FormattedDate } from '@superdispatch/dates';
import { useFormikEnhanced } from '@superdispatch/forms';
import {
  Color,
  Column,
  Columns,
  InfoCard,
  Stack,
  useSnackbarStack,
} from '@superdispatch/ui';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { CheckIconRounded } from 'shared/icons/CheckIconRounded';
import { InfoIconRounded } from 'shared/icons/InfoIconRounded';
import { WarningIconRounded } from 'shared/icons/WarningIconRounded';
import { CarrierFullInfo, isCarrierHasValidUSDOT } from 'shared/types/carrier';
import styled from 'styled-components';
import { CarrierDocumentation } from '../core/CarrierDocumentation';
import {
  CarrierInsurance,
  CarrierInsuranceLegacy,
} from '../core/CarrierInsurance';
import { useAuthority, useCarrierProfileAPI } from '../data/ManageCarriersAPI';

interface CarrierAssessmentProps {
  carrier: CarrierFullInfo;
}

const Divider = styled.div`
  border-top: 1px solid ${Color.Silver400};
`;

const SpinnedContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`;

const PaddedItem = styled.span`
  margin-right: 8px;
`;

const VerifiedDocumentIcon = styled(CheckIconRounded)`
  height: 20px;
  width: 20px;
`;

const UnverifiedDocumentIcon = styled(WarningIconRounded)`
  height: 20px;
  width: 20px;
`;

const InfoDocumentIcon = styled(InfoIconRounded)`
  height: 20px;
  width: 20px;
`;

export function CarrierAssessment({ carrier }: CarrierAssessmentProps) {
  const { data: authority, refetch } = useAuthority(carrier.us_dot);
  const { parseAndFetchAuthority } = useCarrierProfileAPI();
  const { addSnackbar } = useSnackbarStack();
  const isRenewalEnabled = useFeatureToggle(
    'carrier-insurance-renewal.enabled.ui',
  );

  const isCarrierInternal = carrier.carrier_type === 'INTERNAL';

  const {
    handleSubmit: updateAuthorityInfo,
    isSubmitting: isUpdatingAuthorityInfo,
  } = useFormikEnhanced({
    initialValues: {},
    onSubmit: () => {
      if (!carrier.us_dot) {
        return Promise.reject(new Error('No USDOT was found'));
      }

      return parseAndFetchAuthority(carrier.us_dot);
    },
    onSubmitSuccess: () => {
      void refetch();
    },
    onSubmitFailure: () =>
      addSnackbar('Failed to updated FMCSA status', { variant: 'error' }),
  });

  return (
    <InfoCard size="large">
      <Stack space="medium">
        <Stack space="small">
          <Typography variant="h3">Assessment</Typography>

          <Stack space="xsmall">
            {isCarrierHasValidUSDOT(carrier) ? (
              <Columns space="xsmall" align="center">
                <Column width="content">
                  {authority?.operating_status === 'ACTIVE' ||
                  authority?.operating_status?.startsWith('AUTHORIZED FOR') ? (
                    <VerifiedDocumentIcon />
                  ) : (
                    <UnverifiedDocumentIcon />
                  )}
                </Column>
                <Column>
                  <Typography aria-label="carrier authority">
                    Authority: {authority?.operating_status || 'No status'}
                  </Typography>
                </Column>
              </Columns>
            ) : (
              <Columns space="xsmall" align="center">
                <Column width="content">
                  <InfoDocumentIcon />
                </Column>
                <Column>
                  <Tooltip title="This carrier’s state does not require them to own a USDOT number.">
                    <Link aria-label="carrier authority">No USDOT</Link>
                  </Tooltip>
                </Column>
              </Columns>
            )}

            <CarrierDocumentation carrier={carrier} />
          </Stack>

          {!isCarrierInternal && <Divider />}
        </Stack>

        {!isCarrierInternal && (
          <Stack space="medium">
            {isCarrierHasValidUSDOT(carrier) && (
              <Stack space="small">
                <Typography variant="h4">Authority</Typography>

                <Stack space={['xxsmall', 'xsmall']}>
                  {isUpdatingAuthorityInfo ? (
                    <SpinnedContainer aria-label="carrier authority loader">
                      <CircularProgress size={24} />
                    </SpinnedContainer>
                  ) : (
                    <>
                      <Columns collapseBelow="desktop">
                        <Column>
                          <Typography color="textSecondary">Status</Typography>
                        </Column>
                        <Column>
                          <Typography
                            aria-label="carrier authority status"
                            color="textPrimary"
                          >
                            {authority?.operating_status || 'No status'}
                          </Typography>
                        </Column>
                      </Columns>

                      <Columns collapseBelow="desktop">
                        <Column>
                          <Typography color="textSecondary">
                            Last checked
                          </Typography>
                        </Column>
                        <Column>
                          <Typography
                            component="span"
                            aria-label="carrier authority last checked"
                          >
                            <PaddedItem>
                              {authority?.updated_from_safer_date ? (
                                <FormattedDate
                                  variant="Date"
                                  format="DateISO"
                                  date={authority.updated_from_safer_date}
                                />
                              ) : (
                                'No data'
                              )}{' '}
                            </PaddedItem>
                            <Link
                              component="button"
                              onClick={() => updateAuthorityInfo()}
                            >
                              Update
                            </Link>
                          </Typography>
                        </Column>
                      </Columns>
                    </>
                  )}

                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://li-public.fmcsa.dot.gov/LIVIEW/pkg_carrquery.prc_carrlist?n_dotno=${
                      carrier.us_dot || ''
                    }`}
                    onClick={() =>
                      trackEvent('[STMS] Clicked View Carrier Profile on FMCSA')
                    }
                  >
                    View on FMCSA
                  </Link>
                </Stack>
              </Stack>
            )}

            {isRenewalEnabled ? (
              <CarrierInsurance insurances={carrier.insurances} />
            ) : (
              <CarrierInsuranceLegacy carrier={carrier} />
            )}
          </Stack>
        )}
      </Stack>
    </InfoCard>
  );
}
