import { Drawer, IconButton, MenuItem, Typography } from '@material-ui/core';
import { Close, Info } from '@material-ui/icons';
import { FormikPatternField, FormikTextField } from '@superdispatch/forms';
import { usePhoneService } from '@superdispatch/phones';
import {
  Color,
  Column,
  Columns,
  DrawerActions,
  DrawerContent,
  DrawerTitle,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { usePredictions } from 'shared/helpers/MapboxHelpers';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { USA_STATES } from 'superpay/data/MoovDTO';
import { useShipperProfile } from '../../shipper-profile/data/ShipperProfileAPI';
import {
  BusinessContactsDTO,
  businessContactsSchema,
} from '../data/BusinessDTO';

export type Source = 'Add Link' | 'Edit Icon';

interface BusinessContactsEditDrawerProps {
  open: boolean;
  onClose: () => void;
  initialValues: BusinessContactsDTO;
  onSave: (data: BusinessContactsDTO) => void;
  source?: Source;
}

export function BusinessContactsEditDrawer({
  open,
  onClose,
  initialValues,
  onSave,
  source,
}: BusinessContactsEditDrawerProps) {
  const formik = useAppFormik({
    initialValues: {
      phoneNumber: initialValues.phoneNumber,
      website: initialValues.website,
      streetAddress: initialValues.streetAddress,
      zip: initialValues.zip,
      city: initialValues.city,
      state: initialValues.state,
    },
    validationSchema: businessContactsSchema,
    onSubmit: (data) => {
      onSave(data);
      onClose();
      trackEvent('Shipper Updated Business Contacts in Drawer', {
        utm_medium: source,
      });
    },
  });

  const { data: profile } = useShipperProfile();
  const phoneService = usePhoneService();
  const { regionCode } = phoneService.APN.parsePhoneNumber(
    profile?.contact_phone || '',
  );
  const isUSProfilePhone = regionCode && regionCode !== 'US';

  const { setFieldValue, values } = formik;
  const { zip, city, state } = values;
  const isChangedZip = initialValues.zip !== zip;
  const shouldFillCity = !city || isChangedZip;
  const shouldFillState = !state || isChangedZip;
  const query = useSearchInput(
    (shouldFillCity || shouldFillState) && zip ? zip : '',
  );
  const { data: places = [] } = usePredictions(query);

  useEffect(() => {
    if (!places.length) {
      return;
    }
    if (shouldFillCity) {
      void setFieldValue('city', places[0]?.place);
    }

    if (shouldFillState) {
      void setFieldValue('state', places[0]?.region_short);
    }
  }, [shouldFillCity, shouldFillState, places, setFieldValue]);

  useEffect(() => {
    if (open) {
      trackEvent('Shipper Opened Business Contacts Drawer', {
        utm_medium: source,
      });
    }
  }, [source, open]);

  return (
    <Drawer open={open} onClose={onClose}>
      <FormikProvider value={formik}>
        <Form>
          <Box width={['initial', '432px']}>
            <DrawerTitle
              title="Business Contacts"
              endAction={
                <IconButton edge="end" onClick={onClose}>
                  <Close />
                </IconButton>
              }
            />

            <Stack space="small">
              <DrawerContent>
                <Stack space="medium">
                  <Stack space="medium">
                    <Typography variant="h4">Address</Typography>

                    <FormikTextField
                      label="Street Address"
                      name="streetAddress"
                      fullWidth={true}
                      inputProps={{ maxLength: 60 }}
                    />

                    <Columns space="small">
                      <Column width="1/2">
                        <FormikTextField
                          label="City"
                          name="city"
                          fullWidth={true}
                          inputProps={{ maxLength: 24 }}
                        />
                      </Column>

                      <Column width="1/4">
                        <FormikTextField
                          label="State"
                          name="state"
                          select={true}
                          fullWidth={true}
                          SelectProps={{ displayEmpty: true }}
                        >
                          <MenuItem value="" disabled={true}>
                            <Typography color="textSecondary">
                              Select
                            </Typography>
                          </MenuItem>
                          {USA_STATES.map((code) => (
                            <MenuItem key={code} value={code}>
                              {code}
                            </MenuItem>
                          ))}
                        </FormikTextField>
                      </Column>

                      <Column width="1/4">
                        <FormikTextField
                          label="ZIP"
                          name="zip"
                          fullWidth={true}
                        />
                      </Column>
                    </Columns>
                  </Stack>
                  <FormikPatternField
                    name="phoneNumber"
                    mask="_"
                    format="+1 ( ### ) ### - ####"
                    allowEmptyFormatting={true}
                    helperText={
                      isUSProfilePhone &&
                      'Profile contains a non-US phone number.'
                    }
                    label={
                      <Inline space="xxsmall">
                        <Typography>Phone</Typography>
                        <Typography color="textSecondary">
                          (US numbers only)
                        </Typography>
                      </Inline>
                    }
                  />
                  <FormikTextField
                    fullWidth={true}
                    label={
                      <Inline space="xxsmall">
                        <Typography>Website</Typography>
                        <Typography color="textSecondary">
                          (Optional)
                        </Typography>
                      </Inline>
                    }
                    name="website"
                  />
                </Stack>
              </DrawerContent>

              <DrawerActions>
                <Stack space="small">
                  <Box
                    backgroundColor="Blue50"
                    padding="xsmall"
                    borderRadius="small"
                  >
                    <Columns space="xsmall" align="center">
                      <Column width="content">
                        <Info fontSize="small" htmlColor={Color.Blue300} />
                      </Column>
                      <Column>
                        <Typography>
                          Updates here will also apply to your Profile.
                        </Typography>
                      </Column>
                    </Columns>
                  </Box>
                  <Button onClick={() => formik.handleSubmit()}>Save</Button>
                </Stack>
              </DrawerActions>
            </Stack>
          </Box>
        </Form>
      </FormikProvider>
    </Drawer>
  );
}
