import {
  Sidebar,
  SidebarContainer,
  SidebarMenuItem,
} from '@superdispatch/ui-lab';
import { AuthenticatedRouteObject } from 'AppRouter';
import { GeneralSettingsPage } from 'general-settings/GeneralSettingsPage';
import { SuperLoadboardSettingsPage } from 'loadboard-settings/SuperLoadboardSettingsPage';
import { ManageFieldsPage } from 'manage-fields/ManageFieldsPage';
import { ManageUsersPage } from 'manage-users/ManageUsersPage';
import { NotificationSettingsPage } from 'notification-settings/NotificationSettingsPage';
import {
  matchPath,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { superPayRoute } from 'superpay/SuperPayContainer';

export const settingsRoute: AuthenticatedRouteObject = {
  path: 'settings',
  Component: SettingsPage,
  children: [
    {
      path: 'general',
      Component: GeneralSettingsPage,
    },
    superPayRoute,
    {
      path: 'super-loadboard',
      Component: SuperLoadboardSettingsPage,
    },
    {
      path: 'users/*',
      Component: ManageUsersPage,
    },
    {
      path: 'manage-fields',
      handle: { isProtected: true },
      Component: ManageFieldsPage,
    },
    {
      path: 'notifications',
      Component: NotificationSettingsPage,
    },
    {
      path: '*?',
      element: <Navigate to="general" />,
    },
  ],
};

const settingsMenuItems = [
  {
    label: 'General',
    to: 'general',
  },
  {
    label: 'SuperPay',
    to: 'superpay',
  },
  {
    label: 'Super Loadboard',
    to: 'super-loadboard',
  },
  {
    label: 'Manage Users',
    to: 'users',
  },
  {
    label: 'Manage Order Fields',
    to: 'manage-fields',
  },
  {
    label: 'Notifications',
    to: 'notifications',
  },
];

function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useUserState();
  const isShipperTypeBroker = user?.shipper.shipper_type === 'BROKER';

  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  if (!isSettingsV2Enabled || !isShipperTypeBroker) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <SidebarContainer
      sidebar={
        <Sidebar title="Settings">
          {settingsMenuItems.map((item) => (
            <SidebarMenuItem
              key={item.to}
              selected={
                !!matchPath(`/settings/${item.to}/*`, location.pathname)
              }
              onClick={() => navigate(item.to)}
            >
              {item.label}
            </SidebarMenuItem>
          ))}
        </Sidebar>
      }
    >
      <Outlet />
    </SidebarContainer>
  );
}
