import { createSvgIcon } from 'shared/icons/IconUtils';

export const LightBulbIcon = createSvgIcon(
  'LightBulbIcon',
  <path
    d="M8.0013 3.99935C9.06217 3.99935 10.0796 4.42078 10.8297 5.17092C11.5799 5.92107 12.0013 6.93848 12.0013 7.99935C12.0013 9.47935 11.1946 10.7727 10.0013 11.466V12.666C10.0013 12.8428 9.93106 13.0124 9.80604 13.1374C9.68102 13.2624 9.51145 13.3327 9.33463 13.3327H6.66797C6.49116 13.3327 6.32159 13.2624 6.19656 13.1374C6.07154 13.0124 6.0013 12.8428 6.0013 12.666V11.466C4.80797 10.7727 4.0013 9.47935 4.0013 7.99935C4.0013 6.93848 4.42273 5.92107 5.17287 5.17092C5.92302 4.42078 6.94044 3.99935 8.0013 3.99935ZM9.33463 13.9993V14.666C9.33463 14.8428 9.2644 15.0124 9.13937 15.1374C9.01435 15.2624 8.84478 15.3327 8.66797 15.3327H7.33463C7.15782 15.3327 6.98826 15.2624 6.86323 15.1374C6.73821 15.0124 6.66797 14.8428 6.66797 14.666V13.9993H9.33463ZM13.3346 7.33268H15.3346V8.66602H13.3346V7.33268ZM0.667969 7.33268H2.66797V8.66602H0.667969V7.33268ZM8.66797 0.666016V2.66602H7.33463V0.666016H8.66797ZM3.2813 2.33268L4.7013 3.75935L3.75464 4.69935L2.33464 3.28602L3.2813 2.33268ZM11.3013 3.75268L12.7146 2.33268L13.668 3.28602L12.248 4.69935L11.3013 3.75268Z"
    fill="currentColor"
  />,
  { viewBox: '0 0 16 16' },
);
