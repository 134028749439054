import styled from 'styled-components';
import { SuperPaySystemAlert } from './SuperPaySystemAlert';
import { SystemAlert } from './SystemAlert';
import { AlertDTO, useAlerts, useMarkAlertAsRead } from './useAlerts';

const StyledAlertsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  max-width: 512px;
  margin: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0px 8px 10px 0px #00000024;
`;

export function SystemAlertsList() {
  const { alerts } = useAlerts();
  const markAlertAsRead = useMarkAlertAsRead();

  if (alerts.length === 0) {
    return null;
  }

  return (
    <StyledAlertsContainer>
      {alerts.map((alert) => {
        if (alert.type === 'SUPER_PAY') {
          return <SuperPaySystemAlert key={alert.guid} alert={alert} />;
        }

        return (
          <SystemAlert
            key={alert.guid}
            alert={alert}
            onClose={() =>
              markAlertAsRead(
                alert.type as Exclude<AlertDTO['type'], 'SUPER_PAY'>,
              )
            }
          />
        );
      })}
    </StyledAlertsContainer>
  );
}
