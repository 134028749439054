import { AppBar, Container, Toolbar } from '@material-ui/core';
import {
  Column,
  Columns,
  SuperDispatchTheme,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { ReactNode } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled, { css } from 'styled-components';

const PageLayoutHeader = styled(AppBar)`
  min-width: 0;
  max-width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const StyledContainer = styled(Container)(
  ({ theme }: { theme: SuperDispatchTheme }) => css`
    padding-left: 32px;
    padding-right: 32px;
    max-width: calc(816px + 16px);
    margin-bottom: 32px;
    margin-top: 16px;
    ${theme.breakpoints.down('md')} {
      padding: 0;
      margin-bottom: 0;
    }
  `,
);

interface SuperPayLayoutProps {
  children: NonNullable<ReactNode>;
  headerActions?: ReactNode;
}

export function SuperPayPageLayout({
  children,
  headerActions,
}: SuperPayLayoutProps) {
  const isMobile = useResponsiveValue(true, false);
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  return (
    <PageLayout
      header={
        <PageLayoutHeader position={isMobile ? 'relative' : 'sticky'}>
          <Toolbar>
            <Columns align="center">
              <Box
                width="100%"
                marginLeft={isSettingsV2Enabled ? 'small' : undefined}
              >
                <Column>
                  <TextBox variant="heading-2">SuperPay</TextBox>
                </Column>
              </Box>
              {headerActions && (
                <Column width="content">{headerActions}</Column>
              )}
            </Columns>
          </Toolbar>
        </PageLayoutHeader>
      }
    >
      <StyledContainer maxWidth="md">{children}</StyledContainer>
    </PageLayout>
  );
}
