import { apiInstance } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

export type OrderPostingStatisticsDTO = InferType<
  typeof postingStatisticsSchema
>;
const postingStatisticsSchema = yupObject({
  load_guid: yupString(),
  carriers_notified: yupNumber(),
  search_appearance: yupNumber(),
  clicks: yupNumber(),
  saves: yupNumber(),
  requests: yupNumber(),
});

export function usePostingStatistics(guid: string) {
  const { requestResource } = apiInstance;

  return useAPIQuery(['order', 'statistics', { guid }], () =>
    requestResource(`/internal/loadboard/orders/${guid}/statistics`, (data) =>
      postingStatisticsSchema.cast(data),
    ),
  );
}
