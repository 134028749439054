import { DialogContent, Divider, Typography } from '@material-ui/core';
import { AccessTime, DateRange } from '@material-ui/icons';
import { FormattedDate } from '@superdispatch/dates';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, DescriptionLineItem } from '@superdispatch/ui-lab';
import { Price } from 'core/Price';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useSuperPayNowPreview } from 'shared/helpers/superpay/SuperPayAPI';
import { SuperPayLogo } from 'shared/icons/SuperPayLogo';
import Order from 'shared/types/order';
function NonSuperPayOrdersInfo({
  nonSuperPayOrdersCount,
}: {
  nonSuperPayOrdersCount: number;
}) {
  return (
    <Typography color="textSecondary">
      {nonSuperPayOrdersCount}{' '}
      {formatPlural(nonSuperPayOrdersCount, 'order changes', 'orders change')}{' '}
      to <SuperPayLogo /> · 1-3 Business Days
    </Typography>
  );
}

interface SuperPayNowPreviewProps {
  orders: Order[];
  nonSuperPayOrdersCount: number;
}

export function SuperPayNowPreview({
  orders,
  nonSuperPayOrdersCount,
}: SuperPayNowPreviewProps) {
  const ordersCount = orders.length;

  const easternTime = DateTime.now().setZone('America/New_York');
  const hasTimeWarning = easternTime.hour >= 15;
  const ordersGuids = useMemo(
    () => orders.map((order) => order.guid),
    [orders],
  );
  const { data } = useSuperPayNowPreview(ordersGuids);

  return (
    <DialogContent>
      <Stack space="small">
        <Typography>
          {ordersCount} {formatPlural(ordersCount, 'order', 'orders')} will be
          SuperPaid Now.
        </Typography>

        {!!data?.already_charged && (
          <DescriptionLineItem
            title={
              <Typography color="textSecondary">Already Charged</Typography>
            }
          >
            <Typography variant="h5">
              <Price value={data.already_charged} />
            </Typography>
          </DescriptionLineItem>
        )}

        {!!data?.charge_due && (
          <DescriptionLineItem
            title={<Typography color="textSecondary">Charge Due</Typography>}
          >
            <Typography variant="h5">
              <Price value={data.charge_due} />
            </Typography>
          </DescriptionLineItem>
        )}

        {!!data?.charge_scheduled_date && (
          <DescriptionLineItem
            title={
              <Typography color="textSecondary">Charge Scheduled</Typography>
            }
          >
            <Typography variant="h5">
              <FormattedDate
                variant="Date"
                format="DateISO"
                date={data.charge_scheduled_date}
              />
            </Typography>
          </DescriptionLineItem>
        )}

        {!!data?.deposit_expected_date && (
          <DescriptionLineItem
            title={
              <Typography color="textSecondary">Deposit Expected</Typography>
            }
          >
            <Typography variant="h5">
              <FormattedDate
                variant="Date"
                format="DateISO"
                date={data.deposit_expected_date}
              />
            </Typography>
          </DescriptionLineItem>
        )}

        <Box borderRadius="small" backgroundColor="Silver200" padding="small">
          <Stack space="small">
            <Columns align="center" space="small">
              <Column width="content">
                <DateRange color="action" />
              </Column>
              <Column>
                <Typography color="textSecondary">
                  The dates shown are the first charge and last deposit. For
                  multiple orders, check each for exact dates.
                </Typography>
              </Column>
            </Columns>
            {hasTimeWarning && (
              <>
                <Divider />
                <Columns align="center" space="small">
                  <Column width="content">
                    <AccessTime color="action" />
                  </Column>
                  <Column>
                    <Typography color="textSecondary">
                      Payments initiated after 4 PM ET will be processed on the
                      next business day.
                    </Typography>
                  </Column>
                </Columns>
              </>
            )}
          </Stack>
        </Box>

        {!!nonSuperPayOrdersCount && (
          <>
            <Divider />
            <Stack space="xsmall">
              <NonSuperPayOrdersInfo
                nonSuperPayOrdersCount={nonSuperPayOrdersCount}
              />
              <Typography color="textSecondary">
                Inspection type will be changed to Advanced.
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
    </DialogContent>
  );
}
