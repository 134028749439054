import { useQueryClient } from 'react-query';
import { apiInstance, APIPageResponse } from 'shared/api/API';
import { useAPIPageQuery } from 'shared/api/APIPageQuery';
import { yupNumber, yupObject, yupString } from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const ALERT_TYPES = [
  'SUPER_PAY',
  'CENTRAL_DISPATCH_INCORRECT_CREDENTIALS',
  'CENTRAL_DISPATCH_MFA_SESSION_EXPIRED',
  'CENTRAL_DISPATCH_PLAN_LIMIT',
  'CENTRAL_DISPATCH_POST_INTERNAL_ISSUE',
  'CENTRAL_DISPATCH_DELETE_INTERNAL_ISSUE',
] as const;

const ALERT_ACTION_TYPES = [
  'CONTACT_SUPPORT',
  'UPDATE_CENTRAL_DISPATCH_CREDENTIALS',
] as const;

const ALERT_SEVERITY_TYPES = ['WARNING', 'ERROR'] as const;

const alertSchema = yupObject({
  type: yupString().oneOf(ALERT_TYPES),
  guid: yupString(),
  severity_type: yupString().oneOf(ALERT_SEVERITY_TYPES),
  code: yupString().nullable(),
  name: yupString(),
  description: yupString(),
  action_type: yupString().oneOf(ALERT_ACTION_TYPES),
  action: yupString(),
  order_guid: yupString().nullable(),
  order_number: yupString().nullable(),
  order_count: yupNumber().required(),
});

export type AlertDTO = InferType<typeof alertSchema>;

const ALERTS_QUERY_KEY = ['internal', 'alerts'] as const;

export function useAlerts() {
  const { data } = useAPIPageQuery<AlertDTO>(
    ALERTS_QUERY_KEY,
    () => {
      return apiInstance.requestPage(
        '/internal/alerts',
        (json) => json as AlertDTO,
      );
    },
    { schema: alertSchema },
  );

  return {
    alerts: data?.objects ?? [],
  };
}

export function useMarkAlertAsRead() {
  const queryClient = useQueryClient();

  /**
   * Due to requirement for SuperPay alert to be available even after it's been
   * marked as read, we are handling it's read state differently.
   */
  return function markAlertAsRead(
    type: Exclude<AlertDTO['type'], 'SUPER_PAY'>,
  ) {
    queryClient.setQueryData<APIPageResponse<AlertDTO>['data'] | undefined>(
      ALERTS_QUERY_KEY,
      (prev) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          objects: prev.objects.filter((alert) => alert.type !== type),
        };
      },
    );

    /**
     * Intentionally skipping loading state or error handling, as it's not critical
     * functionality and we are relying on optimistic update to remove the alert.
     */
    void apiInstance.request('PUT /internal/alerts/{type}/mark-as-read', {
      type,
    });
  };
}
