import { Check, Lock, Notifications, Timer, Warning } from '@material-ui/icons';
import { Inline, Tag, TagProps, useResponsiveValue } from '@superdispatch/ui';
import { AnchorButton, AnchorButtonProps, Button } from '@superdispatch/ui-lab';
import { ReactNode, useState } from 'react';
import { OnboardingOnRequestReceivedNudgingDialog } from '../../onboarding/OnboardingOnRequestReceivedNudgingDialog';
import { VerificationApplicationDialog } from '../../shipper-profile/core/VerificationApplicationDialog';
import { useVerificationApplication } from '../../shipper-profile/data/VerificationApplicationAPI';
import { useFeatureToggle } from '../data/FeatureToggle';
import { trackEvent } from '../helpers/AnalyticsHelpers';
import {
  readStorageItem,
  useStorageValue,
  writeStorageItem,
} from '../helpers/LocalStorage';
import { IconBox } from '../icons/IconBox';
import { HeaderBanner } from '../ui/HeaderBanner';

interface GenericStatusBannerProps {
  tagIcon: ReactNode;
  tagText: string;
  tagProps: Omit<TagProps, 'variant'> & { variant?: TagProps['variant'] };
  buttonProps: AnchorButtonProps;
  buttonText: string;
}

function GenericStatusBanner({
  tagIcon,
  tagText,
  tagProps,
  buttonProps,
  buttonText,
}: GenericStatusBannerProps) {
  const isMobile = useResponsiveValue(true, false);
  return (
    <HeaderBanner
      text="Your verification status:"
      tag={
        <Tag variant="bold" {...tagProps}>
          <Inline space="none" verticalAlign="center">
            <IconBox>{tagIcon}</IconBox>
            {tagText}
          </Inline>
        </Tag>
      }
      actions={
        <AnchorButton
          size="small"
          variant="neutral"
          fullWidth={isMobile}
          {...buttonProps}
        >
          {buttonText}
        </AnchorButton>
      }
    />
  );
}

const VERIFIED_BANNER_DISMISSED = 'verified_banner_dismissed';
function shouldShowVerifiedBanner() {
  return !readStorageItem(VERIFIED_BANNER_DISMISSED);
}

function VerifiedBanner() {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'green' }}
      tagText="Verified"
      tagIcon={<Check fontSize="small" />}
      buttonText="Dismiss"
      buttonProps={{
        href: '#',
        onClick: () => {
          writeStorageItem(VERIFIED_BANNER_DISMISSED, 'true');
          trackEvent('Shipper Clicked on "Dismiss" Link in Verified Banner');
        },
      }}
    />
  );
}

function RevokedBanner() {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'red' }}
      tagText="Revoked"
      tagIcon={<Warning fontSize="small" />}
      buttonText="Learn More"
      buttonProps={{
        href: 'https://support.superdispatch.com/en/articles/9806978-reinstating-broker-authority',
        target: '_blank',
        onClick: () => {
          trackEvent(
            'Shipper Clicked on "Learn More" Link in Operating Authority Inactive Banner',
          );
        },
      }}
    />
  );
}

function PendingBanner() {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'yellow' }}
      tagText="Pending"
      tagIcon={<Timer fontSize="small" />}
      buttonText="Learn More"
      buttonProps={{
        href: '/profile',
        onClick: () => {
          trackEvent(
            'Shipper Clicked on "Learn More" Link in Pending Verification Status Banner',
          );
        },
      }}
    />
  );
}

function WaitingForCustomerBanner() {
  return (
    <GenericStatusBanner
      tagProps={{ color: 'red' }}
      tagText="Action Required"
      tagIcon={<Warning fontSize="small" />}
      buttonText="View Details"
      buttonProps={{
        href: '/profile',
        onClick: () => {
          trackEvent(
            'Shipper Clicked on "View Details" Link in Waiting For Customer Verification Status Banner',
          );
        },
      }}
    />
  );
}

interface NotSubmittedBannerProps {
  onClick: () => void;
}

function NotSubmittedBanner({ onClick }: NotSubmittedBannerProps) {
  const isMobile = useResponsiveValue(true, false);

  return (
    <HeaderBanner
      icon={<Lock fontSize="inherit" />}
      text="Get verified to unlock Super Dispatch"
      actions={
        <Button size="small" fullWidth={isMobile} onClick={onClick}>
          Get Verified Now
        </Button>
      }
    />
  );
}

function RequestReceivedBanner() {
  const isMobile = useResponsiveValue(true, false);
  const [dialogOpened, setDialogOpened] = useState(false);

  return (
    <>
      <HeaderBanner
        icon={<Notifications fontSize="inherit" />}
        text="You’ve got load requests. Review them now."
        actions={
          <>
            <Button
              variant="neutral"
              size="small"
              fullWidth={isMobile}
              onClick={() => setDialogOpened(true)}
            >
              Learn More
            </Button>
            <Button size="small" fullWidth={isMobile}>
              Review Requests
            </Button>
          </>
        }
      />
      <OnboardingOnRequestReceivedNudgingDialog open={dialogOpened} />
    </>
  );
}

type DialogType = 'get-verified';

interface HeaderBannersStackProps {
  onDialogOpen: (dialogType: DialogType) => void;
}

export function HeaderBannersStack({ onDialogOpen }: HeaderBannersStackProps) {
  const isVerificationOptimizationEnabled = useFeatureToggle(
    'verification-optimization.enabled.ui',
  );
  const isProductOnboardingV1Enabled = useFeatureToggle(
    'stms.product-onboarding-v1.enabled',
  );

  const hasClosedOnRequestReceivedNudgingDialog = useStorageValue(
    'is_request_received_onboarding_nudging_closed',
  );

  const { data: verificationInfo } = useVerificationApplication();

  if (verificationInfo?.status === 'REVOKED') {
    return <RevokedBanner />;
  }

  if (
    isVerificationOptimizationEnabled &&
    verificationInfo?.status === 'NOT_SUBMITTED'
  ) {
    return <NotSubmittedBanner onClick={() => onDialogOpen('get-verified')} />;
  }

  if (
    isVerificationOptimizationEnabled &&
    verificationInfo?.status === 'PENDING'
  ) {
    return <PendingBanner />;
  }

  if (
    isVerificationOptimizationEnabled &&
    verificationInfo?.status === 'WAITING_FOR_CUSTOMER'
  ) {
    return <WaitingForCustomerBanner />;
  }

  if (
    isVerificationOptimizationEnabled &&
    verificationInfo?.status === 'VERIFIED' &&
    shouldShowVerifiedBanner()
  ) {
    return <VerifiedBanner />;
  }

  if (
    isProductOnboardingV1Enabled &&
    !hasClosedOnRequestReceivedNudgingDialog
  ) {
    return <RequestReceivedBanner />;
  }

  return null;
}

export function HeaderBannerContainer() {
  const [currentDialog, setCurrentDialog] = useState<DialogType | null>(null);

  return (
    <>
      <HeaderBannersStack onDialogOpen={setCurrentDialog} />

      <VerificationApplicationDialog
        open={currentDialog === 'get-verified'}
        onClose={() => setCurrentDialog(null)}
      />
    </>
  );
}
