import { get } from 'lodash-es';
import { NotificationSettingsDTO } from 'notification-settings/data/NotificationSettingsDTO';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';

export function trackOrderRecommendations(
  updatedValues: Partial<NotificationSettingsDTO | Order>,
  requestsCount?: number,
) {
  const vehicles: VehicleDTO[] = get(updatedValues, 'vehicles') || [];

  const additionalProps = Object.fromEntries(
    Object.entries({
      updated_vehicle_type: vehicles.some((vehicle) => vehicle.type),
      updated_vehicle_photo: vehicles.some((vehicle) =>
        vehicle.photos?.some((photo) => photo.photo_url),
      ),
      updated_payment_method: get(updatedValues, 'payment.method'),
      updated_payment_term: get(updatedValues, 'payment.terms'),
      set_recommended_price: 'recommended_price' in updatedValues,
      enabled_SMS: get(updatedValues, 'sms_notification_phone_number'),
      enabled_email: get(
        updatedValues,
        'is_personal_email_notifications_enabled',
      ),
      enabled_push: get(
        updatedValues,
        'is_personal_push_notifications_enabled',
      ),
    })
      .filter(([_, value]) => Boolean(value))
      .map(([key]) => [key, 'yes']),
  );

  trackEvent('Shipper Applied Recommendation', {
    utm_medium: 'Order Requests',
    requestsCount: requestsCount || 0,
    ...additionalProps,
  });
}
