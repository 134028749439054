import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { isSuperPayStatusAvailable } from 'core/SuperPayUtils';
import { FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useUserState } from 'shared/data/AppUserState';
import { useProductTiers } from 'shared/data/TiersUtils';
import Order from 'shared/types/order';
import { User } from 'shared/types/user';
import { Attachments, useAttachments } from './Attachments';
import { CarrierPayment } from './CarrierPayment';
import { CustomerCounterpartyFields } from './counterparty/CustomerCounterpartyFields';
import { CustomerPayment } from './CustomerPayment';
import { Dispatcher } from './Dispatcher';
import { Instructions } from './Instructions';
import { LoadID } from './LoadID';
import { OrderFormValues } from './OrderForm';
import { Step } from './Step';
import { Vehicles } from './vehicles/Vehicles';

const exceptionalStatuses = ['canceled', 'declined'];

export interface FieldsProps {
  order: Partial<Order>;
  users: User[];
  source: 'order_edit_page' | 'order_create_page';
}

export const Fields: React.ComponentType<FieldsProps> = ({
  users,
  order,
  source,
}) => {
  const { user } = useUserState();
  const { values, initialValues, setFieldValue } =
    useFormikContext<OrderFormValues>();

  const {
    id,
    guid,
    customer,
    pickup,
    delivery,
    loadboard_instructions,
    instructions,
    customer_payment,
    payment,
  } = values;

  const onValueChange = useCallback(
    (fieldName: string, value: unknown) => {
      void setFieldValue(fieldName, value);
    },
    [setFieldValue],
  );

  const superPayStatus = useMemo(
    () => isSuperPayStatusAvailable(values),
    [values],
  );

  const { attachments, addAttachment, removeAttachment, editAttachment } =
    useAttachments({
      attachments: values.attachments,
    });
  const platform = useResponsiveValue('mobile', 'tablet');
  const isMobile = platform === 'mobile';

  const { isAdvancedTier } = useProductTiers();

  const isInstructionsVisible =
    user?.order_form_settings.is_loadboard_instructions_visible ||
    initialValues.loadboard_instructions ||
    user?.order_form_settings.is_instructions_visible ||
    initialValues.instructions;

  const isCustomerVisible =
    user && isAdvancedTier
      ? user.order_form_settings.is_customer_block_visible ||
        (user.order_form_settings.customer.is_address_visible &&
          customer.address) ||
        (user.order_form_settings.customer.is_address_visible &&
          customer.city) ||
        (user.order_form_settings.customer.is_address_visible &&
          customer.state) ||
        (user.order_form_settings.customer.is_address_visible &&
          customer.zip) ||
        customer.name ||
        (user.order_form_settings.customer.is_notes_visible && customer.notes)
      : false;

  // pending order may have multiple pending offers but not active offer
  const isAdjustedDateEnabled = useMemo(() => {
    return (
      values.status === 'pending' ||
      (!!values.active_offer &&
        !exceptionalStatuses.includes(values.active_offer.status))
    );
  }, [values.status, values.active_offer]);

  return (
    <Box p={3}>
      <Grid container={true} spacing={2}>
        <Grid
          item={true}
          xs={12}
          sm={6}
          md={12}
          data-intercom-target="order id section"
        >
          <LoadID values={values} source={source} />
        </Grid>

        <Grid item={true} xs={12} data-intercom-target="vehicles section">
          <FieldArray name="vehicles">
            {({ remove, push, replace }) => (
              <Vehicles
                remove={remove}
                push={push}
                replace={replace}
                id={id}
                guid={guid}
                isSuperPayStatusAvailable={superPayStatus}
              />
            )}
          </FieldArray>
        </Grid>

        {(isCustomerVisible || isInstructionsVisible) &&
          user?.shipper.shipper_type === 'BROKER' && (
            <Grid item={true} container={true} xs={12} sm={12}>
              {isCustomerVisible && (
                <Grid
                  item={true}
                  xs={12}
                  sm={true}
                  style={{
                    paddingRight: isMobile ? 0 : '8px',
                  }}
                >
                  <Card aria-label="Customer">
                    <CardContent>
                      <Stack space="small">
                        <Typography variant="h3">Customer</Typography>

                        <CustomerCounterpartyFields
                          isOrderCreate={source === 'order_create_page'}
                          customer={values.customer}
                          setFieldValue={setFieldValue}
                        />
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              {isInstructionsVisible && (
                <Grid
                  item={true}
                  xs={12}
                  sm={true}
                  style={{
                    paddingLeft: isCustomerVisible && !isMobile ? '8px' : '0',
                    paddingRight: isCustomerVisible || isMobile ? '0' : '8px',
                    paddingTop: isCustomerVisible && isMobile ? '16px' : '0',
                  }}
                >
                  <Instructions
                    instructions={instructions}
                    loadboard_instructions={loadboard_instructions}
                  />
                </Grid>
              )}
            </Grid>
          )}

        <Grid item={true} xs={12} sm={6}>
          <Step
            title="Pickup"
            name="pickup"
            stepData={pickup}
            customer={customer}
            isAdjustedDateEnabled={isAdjustedDateEnabled}
            isOrderCreate={source === 'order_create_page'}
            adjustedDateLabel="Adjusted Pickup Date"
          />
        </Grid>

        <Grid item={true} xs={12} sm={6}>
          <Step
            title="Delivery"
            name="delivery"
            stepData={delivery}
            customer={customer}
            isAdjustedDateEnabled={isAdjustedDateEnabled}
            isOrderCreate={source === 'order_create_page'}
            adjustedDateLabel="Adjusted Delivery Date"
          />
        </Grid>

        {user?.shipper.shipper_type === 'BROKER' && (
          <Grid item={true} xs={12} sm={6}>
            <CarrierPayment
              values={values}
              onValueChange={onValueChange}
              source={
                source === 'order_create_page' ? 'New Order' : 'Order Edit'
              }
              order={order}
            />
          </Grid>
        )}

        {user?.shipper.shipper_type === 'BROKER' && isAdvancedTier && (
          <Grid item={true} xs={12} sm={6}>
            <CustomerPayment
              customer_payment={customer_payment}
              payment={payment}
              onValueChange={onValueChange}
            />
          </Grid>
        )}

        {user?.shipper.shipper_type === 'CUSTOMER' && (
          <Grid item={true} xs={12} sm={6}>
            <Instructions
              instructions={instructions}
              loadboard_instructions={loadboard_instructions}
            />
          </Grid>
        )}

        {user?.shipper.shipper_type === 'BROKER' && (
          <Grid item={true} xs={12} sm={6}>
            <Dispatcher users={users} order={order} />
          </Grid>
        )}

        <Grid item={true} xs={12} sm={6}>
          <Attachments
            title={
              user?.shipper.shipper_type === 'BROKER'
                ? 'Attachments'
                : 'Attachments for Shippers'
            }
            orderId={id}
            attachments={attachments}
            addAttachment={addAttachment}
            removeAttachment={removeAttachment}
            editAttachment={editAttachment}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
