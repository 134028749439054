import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { useOrderCache } from '../../orders/data/OrderAPI';
import { useSuperPayCancel } from '../data/SuperPayAPI';
import { cancelSchema } from '../data/SuperPayDTO';

interface SuperPayCancelFormProps {
  orderId: number;
  orderGuid: string;
  onClose: () => void;
}
export function useSuperPayCancelForm({
  orderId,
  orderGuid,
  onClose,
}: SuperPayCancelFormProps) {
  const { mutateAsync: cancelSuperPay, isLoading } = useSuperPayCancel(orderId);
  const { invalidateOrder } = useOrderCache();
  const { addSnackbar } = useSnackbarStack();

  const form = useAppFormik({
    validationSchema: cancelSchema,
    initialValues: {
      method: '',
      terms: '5_days',
    },
    onSubmit: (values) => cancelSuperPay(values),
    onSubmitSuccess: () => {
      addSnackbar('SuperPay Payment Canceled Successfully.', {
        variant: 'success',
      });
      void invalidateOrder(orderGuid);
      onClose();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return { form, isLoading };
}
