import { Snackbar } from '@superdispatch/ui';
import { useUserState } from 'shared/data/AppUserState';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useSuperPayAlert } from 'shared/helpers/superpay/SuperPayAPI';
import styled from 'styled-components';
import { SuperPayContentAlert } from './SuperPayAlert';

const AlertSnackbar = styled(Snackbar)`
  padding: 0;
  background: none;
  max-width: 512px;

  & .SDSnackbar-message {
    padding: 0;
    display: block;
    & > span {
      padding: 0;
    }
  }

  @media print {
    display: none;
  }
`;

const SUPERPAY_NOTIFICATION_KEY = 'superpay_notifications';
export function SuperPayNotifications() {
  const { user } = useUserState();
  const hasSuperPayAlerts = !!user?.shipper.super_pay_settings?.alert_status;
  const { data: alertData } = useSuperPayAlert(hasSuperPayAlerts);
  const lastNotificationGuid = useStorageValue(SUPERPAY_NOTIFICATION_KEY);
  const isOpen = lastNotificationGuid !== alertData?.guid && hasSuperPayAlerts;

  if (!alertData) {
    return null;
  }

  return (
    <AlertSnackbar
      open={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      ContentProps={{
        style: {
          padding: 0,
          background: 'none',
        },
      }}
    >
      <SuperPayContentAlert
        source="Alert"
        titlePrefix="SuperPay"
        alertData={alertData}
        onClose={() =>
          writeStorageItem(SUPERPAY_NOTIFICATION_KEY, alertData.guid)
        }
      />
    </AlertSnackbar>
  );
}
