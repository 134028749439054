import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useEffect } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPaySettingsCache } from 'shared/helpers/superpay/SuperPayAPI';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import { SuperPayOnboardingPageLayout } from 'superpay/SuperPayOnboardingPageLayout';
import { BusinessContacts } from './BusinessContacts';
import { BusinessDetails } from './BusinessDetails';
import { SuperPayTermsConditions } from './SuperPayTermsConditions';
import { useBusinessDetailsForm } from './useBusinessDetailsForm';

export function BusinessDetailsPage() {
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const isMobile = useResponsiveValue(true, false);
  const formik = useBusinessDetailsForm({
    onComplete: () => {
      trackEvent('Shipper Submitted Business Details');
      invalidateSuperPaySettings();
    },
  });

  const { isSubmitting, isValid } = formik;

  useEffect(() => {
    trackEvent('Shipper Opened Business Details');
    logPaymentInfo('Shipper Opened Business Details', 'BusinessDetailsForm');
  }, []);

  return (
    <SuperPayOnboardingPageLayout stepNumber={1}>
      <FormikProvider value={formik}>
        <Form aria-label="Business details">
          <Stack space="small">
            <Card square={isMobile}>
              <BusinessDetails />
            </Card>

            <Card square={isMobile}>
              <BusinessContacts />
            </Card>

            <Card square={isMobile}>
              <CardContent>
                <Stack space="small">
                  <SuperPayTermsConditions />
                  <Button
                    type="submit"
                    disabled={!isValid || isSubmitting}
                    pending={isSubmitting}
                  >
                    Continue
                  </Button>
                  {!isValid && (
                    <Typography color="error">
                      Complete all required fields to continue.
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Form>
      </FormikProvider>
    </SuperPayOnboardingPageLayout>
  );
}
