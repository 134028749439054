import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack, useResponsiveValue, useSnackbarStack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPaySettingsCache } from 'shared/helpers/superpay/SuperPayAPI';
import { MoovRepresentative } from 'superpay/data/MoovDTO';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import { useUpdateOwnersProvidedInfo } from '../data/MoovAPI';
import { OwnersList } from './OwnersList';

interface SuperPayOwnersListPageProps {
  owners: MoovRepresentative[];
  refetchOwnersList: () => void;
}

export function OwnersListContainer({
  owners,
  refetchOwnersList,
}: SuperPayOwnersListPageProps) {
  const isMobile = useResponsiveValue(true, false);
  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();
  const { addSnackbar } = useSnackbarStack();

  const { mutate: updateMoovAccount, isLoading } = useUpdateOwnersProvidedInfo({
    onSuccess() {
      invalidateSuperPaySettings();
      trackEvent('Shipper Submitted Representatives', {
        representative_quantity: owners.length,
      });
      logPaymentInfo(
        'Shipper Submitted Representatives',
        'RepresentativesList',
      );
    },
    onError(error) {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  return (
    <Card square={isMobile}>
      <CardContent>
        <Stack space="medium">
          <Stack>
            <Typography variant="h3">Owner/Representative</Typography>
            <Typography color="textSecondary">
              Please enter personal information and residential address of the
              majority owner or a high-level manager such as the CEO, CFO or
              COO.
            </Typography>
          </Stack>
          <Stack space="medium">
            <OwnersList owners={owners} refetchOwnersList={refetchOwnersList} />
          </Stack>
          <Stack space="small">
            <Typography>
              By clicking “Continue”, you certify that all business
              representatives are added.
            </Typography>
            <Button
              disabled={isLoading}
              pending={isLoading}
              onClick={() => {
                updateMoovAccount();
              }}
            >
              Continue
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
