const codeToMessageMap: Record<string, string> = {
  R01: 'Hi, I’m getting insufficient funds error and need to retry the payment.',
  R02: 'Hi, I’m getting a Bank Account Closed error and need to add a new bank account for SuperPay.',
  R03: 'Hi, I’m setting up my bank account for SuperPay and got an error “Account Not Located',
  R16: 'Hi, I’m getting Bank Account Frozen error and need to add a new bank account for SuperPay.',
  R29: 'Hi, I’m getting Originator Unauthorized error and need to add a new bank account for SuperPay.',
};

export function getIntercomMessageByAlertCode(code: string | null) {
  if (!code) {
    return '';
  }

  return codeToMessageMap[code] ?? '';
}
