import { renderChildren } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { usePermission } from 'shared/data/UserPermissions';
import { useSuperPaySettings } from 'shared/helpers/superpay/SuperPayAPI';
import { OnBoardingStatus } from './data/SuperPayDTO';

export const ROUTES_SUPERPAY = {
  main: '/superpay',
  businessDetails: '/superpay/business-details',
  owners: '/superpay/owners',
  bankAccount: '/superpay/bank-account',
  bankDetails: '/superpay/bank-details',
  bankVerification: '/superpay/bank-details/verify-bank-account',
};

const getPath = (
  status?: OnBoardingStatus | null,
  canManageSuperPay = true,
): string[] => {
  if (!canManageSuperPay) {
    switch (status) {
      case 'PENDING':
      case 'COMPLETED':
        return [ROUTES_SUPERPAY.bankDetails];
      default:
        return [ROUTES_SUPERPAY.main];
    }
  }

  switch (status) {
    case 'STARTED':
      return [ROUTES_SUPERPAY.owners];
    case 'OWNERS_PROVIDED':
      return [ROUTES_SUPERPAY.bankAccount];
    case 'PENDING':
    case 'COMPLETED':
      return [ROUTES_SUPERPAY.bankDetails];
    default:
      return [ROUTES_SUPERPAY.main, ROUTES_SUPERPAY.businessDetails];
  }
};

export function SuperPayRoute({
  children,
  path,
}: {
  children: ReactNode;
  path: string;
}) {
  const { search } = useLocation();
  const { data, isLoading } = useSuperPaySettings();
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const pathsFromStatus = getPath(data?.on_boarding_status, canManageSuperPay);
  const isAvailable = pathsFromStatus.some((statusPath) => statusPath === path);
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  if (isLoading) {
    return null;
  }

  if (!isAvailable && pathsFromStatus[0]) {
    return (
      <Navigate
        to={{
          pathname: isSettingsV2Enabled
            ? `/settings${pathsFromStatus[0]}`
            : pathsFromStatus[0],
          search,
        }}
      />
    );
  }
  return renderChildren(children);
}
