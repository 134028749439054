import { Card, CardContent } from '@material-ui/core';
import { Stack, useResponsiveValue } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { Form, FormikProvider } from 'formik';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useSuperPaySettings } from 'shared/helpers/superpay/SuperPayAPI';
import { BankAccountDetails } from './bank-details/BankAccountDetails';
import { SuperPayPageLayout } from './SuperPayPageLayout';
import { ExpeditedPaySettings } from './workflow/ExpeditedPaySettings';
import { useWorkflowSettingsForm } from './workflow/useWorkflowSettingsForm';
import { WorkflowSettings } from './workflow/WorkflowSettings';

export function SuperPaySettingsPage() {
  const isSuperPayExpeditedPayEnabled = useFeatureToggle(
    'payments.expedited-pay.access.ui',
  );
  const { data: superPaySettings } = useSuperPaySettings();
  const settingsForm = useWorkflowSettingsForm();
  const { isSubmitting, dirty, handleSubmit, values } = settingsForm;
  const isMobile = useResponsiveValue(true, false);
  const isAutomatic = values.superpay_flow === 'AUTOMATIC';
  const isDisabledButton =
    (!superPaySettings?.can_move_to_automatic_flow &&
      superPaySettings?.superpay_flow === 'MANUAL' &&
      isAutomatic) ||
    !dirty;

  return (
    <SuperPayPageLayout
      headerActions={
        !isMobile && (
          <Button
            type="submit"
            pending={isSubmitting}
            disabled={isDisabledButton}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        )
      }
    >
      <Box paddingLeft={['none', 'small']} paddingRight={['none', 'small']}>
        <FormikProvider value={settingsForm}>
          <Form aria-label="SuperPay settings">
            <Stack space="small">
              <BankAccountDetails />
              {isSuperPayExpeditedPayEnabled && (
                <Box margin="auto" maxWidth="532px">
                  <ExpeditedPaySettings />
                </Box>
              )}
              <Box margin="auto" maxWidth="532px">
                <WorkflowSettings superPaySettings={superPaySettings} />
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
        {isMobile && (
          <Box position="sticky" bottom="0" marginTop="small">
            <Card>
              <CardContent>
                <Button
                  type="submit"
                  pending={isSubmitting}
                  disabled={isDisabledButton}
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>
    </SuperPayPageLayout>
  );
}
