import { Link, LinkProps, Tooltip, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Color, Column, Columns, renderChildren } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { isSuperPayStatusAvailable } from 'core/SuperPayUtils';
import { useFormikContext } from 'formik';
import { useOrderPaymentFlags } from 'orders/data/OrderPaymentFlagsAPI';
import { forwardRef, ReactElement, ReactNode, useState } from 'react';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { SuperPayCancelDialog } from '../../superpay/cancel/SuperPayCancelDialog';
import { SuperPayEditDrawer } from '../../superpay/SuperPayEditDrawer';
import { useOrderCache } from '../data/OrderAPI';
import { OrderFormVehicleDTO } from './form/data/OrderFormVehicleDTO';
import { OrderFormValues } from './form/OrderForm';

const PointerLink = styled(Link)`
  cursor: pointer;
`;

const TooltipLink = styled(PointerLink)`
  color: white;
  pointer-events: auto;
`;

const TooltipElementContainer = styled.div`
  &:before {
    content: '';
    position: absolute;
    height: 55px;
    width: 100%;
    z-index: 1;
  }
`;

interface SuperPayEditProps {
  open: boolean;
  onClose: () => void;
}
function SuperPayEdit({ open, onClose }: SuperPayEditProps) {
  const formik = useFormikContext<OrderFormValues>();
  const { invalidateOrder } = useOrderCache();
  return (
    <SuperPayEditDrawer
      open={open}
      order={formik.values}
      onClose={onClose}
      onSuccess={({ editableVehicles, terms, method }) => {
        if (!formik.dirty) {
          void invalidateOrder(formik.values.guid);
        } else {
          const vehicles: OrderFormVehicleDTO[] = [];
          formik.values.vehicles.forEach((vehicle, index) => {
            const editableVehicle = editableVehicles[index];
            if (editableVehicle?.is_active) {
              vehicles.push({ ...vehicle, price: editableVehicle.price });
            }
          });

          void formik.setFieldValue('payment.terms', terms);
          void formik.setFieldValue('payment.method', method);
          void formik.setFieldValue('vehicles', vehicles);

          if (method !== 'superpay') {
            void formik.setFieldValue('payment.reference_number', '');
          }
        }
      }}
    />
  );
}

type BannerSource = 'vehicles' | 'payment';

function getTitleBanner(source: BannerSource) {
  return source === 'vehicles'
    ? 'To change Carrier Price or remove vehicle, go to'
    : 'To change payment, go to';
}

export function EditSuperPayCancelButton({
  color,
}: {
  color?: LinkProps['color'];
}) {
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  return (
    <>
      <PointerLink onClick={() => setOpenCancelDialog(true)} color={color}>
        Cancel SuperPay
      </PointerLink>
      <SuperPayCancelDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      />
    </>
  );
}

function EditButton({ source }: { source: BannerSource }) {
  const [openEditDrawer, setOpenEditDrawer] = useState<boolean>(false);

  return (
    <>
      {getTitleBanner(source)}{' '}
      <PointerLink
        onClick={() => {
          setOpenEditDrawer(true);
          trackEvent('Shipper Clicked Edit SuperPay', {
            utm_medium: 'Order Edit',
            utm_content: 'Banner',
          });
        }}
      >
        edit SuperPay.
      </PointerLink>
      <SuperPayEdit
        open={openEditDrawer}
        onClose={() => setOpenEditDrawer(false)}
      />
    </>
  );
}

export function EditSuperPayOrderBanner({ source }: { source: BannerSource }) {
  const isCancelSuperPayEnabled = useFeatureToggle(
    'payments.new-cancel-superpay.ui.enabled',
  );

  return (
    <Box
      aria-label="edit superpay banner"
      backgroundColor="Blue50"
      padding="xsmall"
      borderRadius="small"
      maxWidth="fit-content"
    >
      <Columns space="xxsmall" align="center">
        <Column width="content">
          <Info fontSize="small" htmlColor={Color.Blue500} />
        </Column>
        <Column>
          <Typography>
            <EditButton source={source} />
            {isCancelSuperPayEnabled && (
              <>
                To change payment method <EditSuperPayCancelButton />
              </>
            )}
          </Typography>
        </Column>
      </Columns>
    </Box>
  );
}

interface TooltipElementProps {
  children?: ReactNode;
}

const TooltipElement = forwardRef<HTMLElement, TooltipElementProps>(
  ({ children, ...props }, ref) => (
    <span {...props} ref={ref}>
      {children}
    </span>
  ),
);

TooltipElement.displayName = 'TooltipElement';

type ToolTipSource = 'total_price' | 'vehicle_price' | 'remove_vehicle';

interface EditTooltipProps {
  order?: OrderFormValues | Order;
  children: ReactElement;
  source: ToolTipSource;
}

function getTitleToolTip(source: ToolTipSource) {
  return source === 'total_price'
    ? 'To change Total Carrier Price'
    : source === 'vehicle_price'
    ? 'To change Carrier Price'
    : 'To remove a vehicle';
}

export function EditSuperPayOrderTooltip(props: EditTooltipProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { source, order, children } = props;
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);

  return (
    <>
      {!order || !isSuperPayStatusAvailable(order) ? (
        renderChildren(children)
      ) : orderPaymentFlags?.can_be_edited ? (
        <Tooltip
          aria-label="edit superpay tooltip"
          arrow={true}
          leaveDelay={1000}
          title={
            <Box maxWidth="200px">
              <Typography>
                {getTitleToolTip(source)}
                ,
                <br />
                go to{' '}
                <TooltipLink
                  onClick={() => {
                    setOpen(true);
                    trackEvent('Shipper Clicked Edit SuperPay', {
                      utm_medium: 'Order Edit',
                      utm_content: 'Tooltip',
                    });
                  }}
                >
                  Edit SuperPay.
                </TooltipLink>
              </Typography>
            </Box>
          }
        >
          <TooltipElement>{children}</TooltipElement>
        </Tooltip>
      ) : (
        <Tooltip
          aria-label="edit superpay disabled tooltip"
          arrow={true}
          title={
            <Typography>
              Edit is not available as payment
              <br />
              processing has already started.
            </Typography>
          }
        >
          <TooltipElement>{children}</TooltipElement>
        </Tooltip>
      )}
      <SuperPayEdit open={open} onClose={() => setOpen(false)} />
    </>
  );
}

interface EditSuperPayTermsTooltipProps {
  order: OrderFormValues | Order | undefined;
  children: ReactElement;
  source: 'edit_superpay_button' | 'payment_terms';
}

export function EditSuperPayDisabledTooltip({
  order,
  children,
  source,
}: EditSuperPayTermsTooltipProps) {
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);
  return isSuperPayStatusAvailable(order) &&
    !orderPaymentFlags?.can_be_edited ? (
    <Tooltip
      aria-label="edit superpay disabled tooltip"
      arrow={true}
      title={
        <Typography>
          {source === 'edit_superpay_button'
            ? 'Not available'
            : 'Edit is not available'}{' '}
          as payment
          <br /> processing has already started.
        </Typography>
      }
    >
      <TooltipElement>{children}</TooltipElement>
    </Tooltip>
  ) : (
    renderChildren(children)
  );
}

interface EditSuperPayMethodDisabledTooltipProps {
  order: OrderFormValues | Order | undefined;
  children: ReactElement;
}
export function EditSuperPayMethodDisabledTooltip({
  order,
  children,
}: EditSuperPayMethodDisabledTooltipProps) {
  const isCancelSuperPayEnabled = useFeatureToggle(
    'payments.new-cancel-superpay.ui.enabled',
  );
  const orderPaymentFlags = useOrderPaymentFlags(order?.id);
  return orderPaymentFlags?.can_be_edited && isCancelSuperPayEnabled ? (
    <Tooltip
      arrow={true}
      title="To change the payment method, cancel SuperPay"
      placement="right-start"
    >
      <TooltipElementContainer>
        <TooltipElement>{children}</TooltipElement>
      </TooltipElementContainer>
    </Tooltip>
  ) : (
    renderChildren(children)
  );
}

interface EditSuperPayButtonProps {
  order: OrderFormValues;
}
export function EditSuperPayButton({ order }: EditSuperPayButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const orderPaymentFlags = useOrderPaymentFlags(order.id);
  return (
    <>
      <EditSuperPayDisabledTooltip order={order} source="edit_superpay_button">
        <Button
          variant="neutral"
          disabled={!orderPaymentFlags?.can_be_edited}
          onClick={() => {
            setOpen(true);
            trackEvent('Shipper Clicked Edit SuperPay', {
              utm_medium: 'Order Edit',
              utm_content: 'Edit SuperPay Button',
            });
          }}
        >
          Edit SuperPay
        </Button>
      </EditSuperPayDisabledTooltip>
      <SuperPayEdit open={open} onClose={() => setOpen(false)} />
    </>
  );
}
