import { useSnackbarStack } from '@superdispatch/ui';
import { useAppFormik } from 'shared/form/AppFormik';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useShipperProfile } from 'shipper-profile/data/ShipperProfileAPI';
import { useCreateBankAccount } from 'superpay/data/MoovAPI';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import { bankSchema } from '../data/BankDTO';

interface BankAccountFormProps {
  onComplete: () => void;
}

export function useBankAccountForm({ onComplete }: BankAccountFormProps) {
  const { addSnackbar } = useSnackbarStack();
  const { data: profile } = useShipperProfile();
  const { mutateAsync: createShipperBankAccount } = useCreateBankAccount();

  return useAppFormik({
    initialValues: {
      ...bankSchema.getDefault(),
      holderName: profile?.legal_company_name,
    },
    validationSchema: bankSchema,
    onSubmit: (data) => createShipperBankAccount(data),
    onSubmitSuccess: () => {
      trackEvent('Shipper Submitted Bank Details');
      onComplete();
      logPaymentInfo('Shipper Submitted Bank Details', 'VerifyBankAccountForm');
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });
}
