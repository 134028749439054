import {
  Divider,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Error, Info, Warning } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormikCurrencyField, FormikTextField } from '@superdispatch/forms';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  isValidSuperPayTerm,
  PaymentMethod,
} from '@superdispatch/sdk';
import { Color, Column, Columns, Inline, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { usePaymentOptions } from 'core/SuperPayUtils';
import { useField } from 'formik';
import { useEffect } from 'react';
import { AllPaymentTerm } from 'shared/dto/Order/CarrierPaymentDTO';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import styled from 'styled-components';
import { useFeatureToggle } from '../shared/data/FeatureToggle';

const DashedSeparate = styled(Box)`
  border-bottom: 1px dashed;
  margin-left: 8px;
  margin-right: 8px;
  width: 100%;
  border-color: ${Color.Silver400};
`;

const TitleNoWrap = styled(Typography)`
  white-space: nowrap;
`;

const BoxBaseLine = styled(Box)`
  align-items: baseline;
`;

const TableCellStyled = styled(TableCell)`
  border: none;
  padding: 8px 4px 8px 0;
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

interface SuperPayEditableVehicle {
  guid: string;
  price: number;
  is_active: boolean;
  title: string;
}

interface VehiclesTableProps {
  isDisabled?: boolean;
  editableVehicles: SuperPayEditableVehicle[];
  isPaymentMethodChanged: boolean;
}

interface ResultsInformationProps {
  superPayChargedAmount: number;
  totalCarrierPrice: number;
  totalCarrierPriceMinusFee: number;
  expeditedPayFeeAmount: number;
  refundAmount: number;
  isPaymentMethodChanged: boolean;
  isTotalCarrierPriceNotBeZero: boolean;
}

interface VehicleRowProps {
  isDeleteActive: boolean;
  index: number;
  isPaymentMethodChanged: boolean;
  isDisabled?: boolean;
}

export function PaymentMethods({
  isPaymentMethodChanged,
}: {
  isPaymentMethodChanged: boolean;
}) {
  const [{ value: methodValue }] = useField<PaymentMethod>('method');
  const [{ value: termValue }, , { setValue: setTerm }] =
    useField<AllPaymentTerm>('terms');
  const { paymentMethods, paymentTerms } = usePaymentOptions(methodValue);

  return (
    <Stack space="xsmall">
      <Columns space="small">
        <Column>
          <FormikTextField
            select={true}
            fullWidth={true}
            name="method"
            label="Method"
            onChange={({ target: { value } }) => {
              if (value === 'superpay' && !isValidSuperPayTerm(termValue)) {
                setTerm('1_3_days');
              } else if (
                methodValue === 'superpay' &&
                value !== 'superpay' &&
                termValue === '1_3_days'
              ) {
                setTerm('other');
              }
            }}
          >
            {paymentMethods.map((value) => (
              <MenuItem key={value} value={value}>
                {formatPaymentMethod(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
        <Column>
          <FormikTextField
            select={true}
            fullWidth={true}
            disabled={methodValue === 'superpay'}
            defaultValue="other"
            name="terms"
            label="Terms"
          >
            {paymentTerms.map((value) => (
              <MenuItem key={value} value={value}>
                {formatPaymentTerm(value)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Column>
      </Columns>
      {isPaymentMethodChanged && (
        <Box color="Red500">
          It will not be possible to change the method back to SuperPay.
        </Box>
      )}
    </Stack>
  );
}

export function ResultsInformation({
  superPayChargedAmount,
  totalCarrierPrice,
  totalCarrierPriceMinusFee,
  expeditedPayFeeAmount,
  refundAmount,
  isPaymentMethodChanged,
  isTotalCarrierPriceNotBeZero,
}: ResultsInformationProps) {
  const isCancelSuperPayEnabled = useFeatureToggle(
    'payments.new-cancel-superpay.ui.enabled',
  );

  const showRefundText =
    (superPayChargedAmount !== totalCarrierPriceMinusFee ||
      isPaymentMethodChanged) &&
    !!refundAmount;

  const isExceededInitialPrice =
    totalCarrierPriceMinusFee > superPayChargedAmount;

  const totalCarrierTextColor = isCancelSuperPayEnabled
    ? 'initial'
    : isTotalCarrierPriceNotBeZero || isExceededInitialPrice
    ? 'error'
    : 'initial';

  return (
    <Box paddingLeft="none" paddingRight="none">
      <Stack space="small">
        {showRefundText && !isTotalCarrierPriceNotBeZero && (
          <Box backgroundColor="Yellow50" padding="xsmall" borderRadius="small">
            <Columns space="xsmall" align="top">
              <Column width="content">
                <Box top={['auto', '2px']} position="relative">
                  <Warning fontSize="small" htmlColor={Color.Yellow300} />
                </Box>
              </Column>
              <Column>
                {isCancelSuperPayEnabled ? (
                  <Stack space="small">
                    <Typography>
                      Since SuperPay charge was initiated and the combined
                      vehicle price is less than the original Total Carrier
                      Price, a refund will be issued.
                    </Typography>
                    <Typography>
                      Refunds take 1-3 business days to complete.
                    </Typography>
                  </Stack>
                ) : (
                  <Typography>
                    Because the combined vehicle price is less than the original
                    Total Carrier Price, a refund will be triggered.
                  </Typography>
                )}
              </Column>
            </Columns>
          </Box>
        )}
        <Divider />
        <Stack space="xsmall">
          <Box display="flex" aria-label="superpay total price">
            <BoxBaseLine width="270px" display="flex">
              <TitleNoWrap variant="body1" color={totalCarrierTextColor}>
                Total Carrier Price
              </TitleNoWrap>
              <DashedSeparate />
            </BoxBaseLine>
            <Typography variant="body1" color={totalCarrierTextColor}>
              {formatCurrency(totalCarrierPrice)}
            </Typography>
          </Box>

          {!!expeditedPayFeeAmount && (
            <Box display="flex" aria-label="superpay total price">
              <BoxBaseLine width="270px" display="flex">
                <TitleNoWrap>Expedite Fee</TitleNoWrap>
                <DashedSeparate />
              </BoxBaseLine>
              <Typography>{formatCurrency(expeditedPayFeeAmount)}</Typography>
            </Box>
          )}

          <Box display="flex" aria-label="superpay charged">
            <BoxBaseLine width="270px" display="flex">
              <TitleNoWrap>SuperPay Charged</TitleNoWrap>
              <DashedSeparate />
            </BoxBaseLine>
            <Typography color="textSecondary">
              {formatCurrency(superPayChargedAmount)}
            </Typography>
          </Box>

          {isTotalCarrierPriceNotBeZero ? (
            isCancelSuperPayEnabled ? (
              <Box
                backgroundColor="Red50"
                padding="xsmall"
                borderRadius="small"
              >
                <Columns space="xsmall">
                  <Column width="content">
                    <Box top={['auto', '2px']} position="relative">
                      <Error fontSize="small" htmlColor={Color.Red300} />
                    </Box>
                  </Column>
                  <Column>
                    <Stack space="small">
                      <Typography>
                        Total Carrier Price must be greater than $0 for SuperPay
                        charge to be initiated.
                      </Typography>
                      <Typography>
                        To proceed with a $0 price,{' '}
                        <PointerLink color="primary" onClick={() => null}>
                          change payment method.
                        </PointerLink>
                      </Typography>
                    </Stack>
                  </Column>
                </Columns>
              </Box>
            ) : (
              <Typography color="error">
                Total Carrier Price cannot be $0 before SuperPay Charge
                Initiated. To change the price to $0, change payment method.
              </Typography>
            )
          ) : showRefundText ? (
            <Stack space="small">
              <Box display="flex" aria-label="superpay refund">
                <BoxBaseLine width="270px" display="flex">
                  <TitleNoWrap>Refund</TitleNoWrap>
                  <DashedSeparate />
                </BoxBaseLine>
                <Typography color="textSecondary">
                  {formatCurrency(refundAmount)}
                </Typography>
              </Box>
              {isPaymentMethodChanged ? (
                <Box
                  padding="xsmall"
                  backgroundColor="Blue50"
                  borderRadius="small"
                >
                  <Columns space="xxsmall">
                    <Column width="content">
                      <Box top={['auto', '2px']} position="relative">
                        <Info fontSize="small" htmlColor={Color.Blue500} />
                      </Box>
                    </Column>
                    <Column>
                      <Box fontSize="14px">
                        Refund completion may take 1-3 business days.
                      </Box>
                    </Column>
                  </Columns>
                </Box>
              ) : (
                <TextBox>
                  Refund will be processed when the carrier payment is
                  initiated. Refund completion may take 1-3 business days.
                </TextBox>
              )}
            </Stack>
          ) : (
            isExceededInitialPrice && (
              <Box maxWidth={['initial', '75%']}>
                <Typography color="error">
                  {`Total Carrier Price can NOT be over the initial ${formatCurrency(
                    superPayChargedAmount,
                    { minimumFractionDigits: 0 },
                  )} value.`}
                </Typography>
              </Box>
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

function VehicleRow({
  title,
  isDeleteActive,
  index,
  isPaymentMethodChanged,
  isDisabled,
}: VehicleRowProps & SuperPayEditableVehicle) {
  const priceFieldName = `editableVehicles.${index}.price`;
  const activeFieldName = `editableVehicles.${index}.is_active`;
  const showDeleteButton =
    isDeleteActive && !isPaymentMethodChanged && !isDisabled;

  const [
    { value: priceValue },
    { initialValue: initialPriceValue = 0 },
    { setValue: setPrice },
  ] = useField<number>(priceFieldName);
  const [{ value: activeValue }, , { setValue: setActive }] =
    useField<boolean>(activeFieldName);

  useEffect(() => {
    if (!isPaymentMethodChanged) {
      return;
    }

    if (priceValue !== initialPriceValue) {
      void setPrice(initialPriceValue);
    }

    if (!activeValue) {
      void setActive(true);
    }
  }, [
    isPaymentMethodChanged,
    activeValue,
    initialPriceValue,
    priceValue,
    setActive,
    setPrice,
  ]);

  if (!activeValue) {
    return null;
  }
  return (
    <TableRow>
      <TableCellStyled width="263px">{title}</TableCellStyled>
      <TableCellStyled>
        <Inline verticalAlign="center" noWrap={true}>
          <Box width="95px">
            <FormikCurrencyField
              disabled={isPaymentMethodChanged || isDisabled}
              name={priceFieldName}
              placeholder="0.00"
              fullWidth={true}
              inputProps={{
                allowNegative: false,
                maxLength: 11,
                fixedDecimalScale: true,
              }}
              onBlur={() => {
                if (!priceValue) {
                  void setPrice(0);
                }
              }}
            />
          </Box>
          {showDeleteButton && (
            <IconButton
              aria-label="remove vehicle"
              size="small"
              onClick={() => {
                void setActive(false);
                void setPrice(0);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Inline>
      </TableCellStyled>
    </TableRow>
  );
}

export function VehiclesTable({
  isDisabled,
  editableVehicles,
  isPaymentMethodChanged,
}: VehiclesTableProps) {
  const isDeleteActive =
    editableVehicles.filter(({ is_active }) => is_active).length > 1;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellStyled>
            <TextBox noWrap={true} color="secondary" variant="heading-5">
              Vehicles
            </TextBox>
          </TableCellStyled>
          <TableCellStyled>
            <TextBox noWrap={true} color="secondary" variant="heading-5">
              Carrier Price
            </TextBox>
          </TableCellStyled>
        </TableRow>
      </TableHead>
      <TableBody>
        {editableVehicles.map((item, index) => (
          <VehicleRow
            {...item}
            isDisabled={isDisabled}
            isDeleteActive={isDeleteActive}
            isPaymentMethodChanged={isPaymentMethodChanged}
            key={item.guid}
            index={index}
          />
        ))}
      </TableBody>
    </Table>
  );
}
