import { Box } from '@superdispatch/ui-lab';
import { PaywallHeader } from './core/PaywallHeader';
import { PaywallTrialExpired } from './PaywallTrialExpired';

export function Paywall() {
  return (
    <>
      <PaywallHeader />
      <Box padding="large">
        <PaywallTrialExpired />
      </Box>
    </>
  );
}
