import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Container,
  FormControlLabel,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ExpandMore, Info } from '@material-ui/icons';
import { FormikCheckboxField, useFormikEnhanced } from '@superdispatch/forms';
import {
  Button,
  Color,
  Column,
  Columns,
  Inline,
  Stack,
  useResponsiveValue,
  useSnackbarStack,
} from '@superdispatch/ui';
import { FormikProvider } from 'formik';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { trackEventLegacy } from 'shared/helpers/AnalyticsHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { useOrderFieldsAPI } from './data/ManageFieldsAPI';
import { OrderFieldsDTO } from './data/OrderFieldsDTO';

const AppBarSettingsV2 = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

const StyledAccordion = styled(Accordion)`
  &.MuiAccordion-root:not(:first-child) {
    border-top: 0;
  }

  &.MuiAccordion-root.Mui-expanded,
  &.MuiAccordion-root.Mui-expanded + &.MuiAccordion-root {
    border-top: 1px solid ${Color.Silver400};
  }

  & .MuiAccordionSummary-root {
    min-height: 56px;
  }

  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 16px 0;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-containedPrimary.Mui-disabled:hover {
    background-color: ${Color.Blue100};
  }
`;

export function ManageFieldsPage() {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const { user, refetch } = useUserState();
  const { addSnackbar } = useSnackbarStack();
  const { updateFields } = useOrderFieldsAPI();
  const canUpdateFormSettings = useCanExecute('MANAGE_ORDER_FORM_SETTINGS');
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  const showNoPermissionsSnackbar = () => {
    if (!canUpdateFormSettings) {
      addSnackbar(
        <Stack space="none">
          <Typography>No edit permission.</Typography>
          <Typography>Request manager to update settings.</Typography>
        </Stack>,
      );
    }
  };

  useDocumentTitle('Manage Order Fields');

  const formik = useFormikEnhanced({
    initialValues: { ...user?.order_form_settings },
    async onSubmit(values) {
      return updateFields(values as OrderFieldsDTO);
    },
    onSubmitSuccess: (_, values) => {
      trackEventLegacy('Updated order fields settings', values);

      formik.setSubmitting(true);

      void refetch().then(() => formik.setSubmitting(false));

      addSnackbar('Order fields settings updated successfully', {
        variant: 'success',
      });
    },
    onSubmitFailure: () => {
      addSnackbar('Failed to update order fields settings', {
        variant: 'error',
      });
    },
  });

  const AppBarComponent = isSettingsV2Enabled ? AppBarSettingsV2 : AppBar;

  return (
    <PageLayout
      isContentLoading={!user}
      header={
        <AppBarComponent>
          <Toolbar>
            <Box width="100%" mx={2}>
              <Columns>
                <Column width="fluid">
                  <Typography variant="h2">Manage Order Fields</Typography>
                </Column>

                {platform === 'desktop' && (
                  <Column width="content">
                    <Button
                      disabled={
                        !formik.dirty || !formik.isValid || formik.isSubmitting
                      }
                      isLoading={formik.isSubmitting}
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        void formik.submitForm();
                      }}
                    >
                      Save
                    </Button>
                  </Column>
                )}
              </Columns>
            </Box>
          </Toolbar>
        </AppBarComponent>
      }
      footer={
        platform !== 'desktop' ? (
          <Box
            display="flex"
            position="fixed"
            bottom={platform === 'mobile' ? '50px' : '0'}
            bgcolor={Color.White}
            width="100%"
            px={2}
            py={2}
            justifyContent="flex-start"
            boxShadow={`inset 0px 1px 0px ${Color.Silver400}`}
          >
            <StyledButton
              disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              isLoading={formik.isSubmitting}
              variant="contained"
              type="submit"
              onClick={() => {
                void formik.submitForm();
              }}
            >
              Save
            </StyledButton>
          </Box>
        ) : null
      }
    >
      <FormikProvider value={formik}>
        <Container maxWidth="md">
          <Box my={2}>
            <form onSubmit={formik.handleSubmit}>
              <Stack space="small">
                <Typography color="textSecondary">
                  Manage visibility of fields in Order Creation and Edit pages
                  company-wide.
                </Typography>
                <Box pb={platform !== 'desktop' ? 16 : 0}>
                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h4">General Info</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack>
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="PO Number"
                              name="is_purchase_order_number_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Transport Type"
                              name="is_transport_type_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography variant="h4">Vehicle</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack>
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Color"
                              name="vehicle.is_color_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Lot #"
                              name="vehicle.is_lot_number_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography variant="h4">Instructions</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack>
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label={
                                <Inline verticalAlign="center">
                                  <Typography>
                                    Loadboard Instructions
                                  </Typography>
                                  <Tooltip title="Visible to carrier inside the Super Loadboard">
                                    <Info color="action" fontSize="small" />
                                  </Tooltip>
                                </Inline>
                              }
                              name="is_loadboard_instructions_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label={
                                <Inline verticalAlign="center">
                                  <Typography>Order Instructions</Typography>
                                  <Tooltip title="Visible to carrier before and after accepting the load offer">
                                    <Info color="action" fontSize="small" />
                                  </Tooltip>
                                </Inline>
                              }
                              name="is_instructions_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Columns align="center">
                        <Column width="fluid">
                          <Typography variant="h4">Customer</Typography>
                        </Column>
                        <Column width="content">
                          <FormControlLabel
                            label=""
                            labelPlacement="start"
                            control={
                              <Switch
                                name="is_customer_block_visible"
                                checked={
                                  formik.values.is_customer_block_visible
                                }
                                disabled={!canUpdateFormSettings}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                onChange={(event) =>
                                  formik.setFieldValue(
                                    'is_customer_block_visible',
                                    event.target.checked,
                                  )
                                }
                              />
                            }
                          />
                        </Column>
                      </Columns>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack space="medium">
                          <Stack>
                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Type"
                                name="customer.is_business_type_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Address"
                                name="customer.is_address_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Phone"
                                name="customer.is_phone_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Billing Email"
                                name="customer.is_email_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Notes for Customer"
                                name="customer.is_notes_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>
                          </Stack>

                          <Stack>
                            <Typography variant="h5">Contact</Typography>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Title"
                                name="customer.is_contact_title_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Mobile"
                                name="customer.is_contact_mobile_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Email"
                                name="customer.is_contact_email_visible"
                                disabled={
                                  !canUpdateFormSettings ||
                                  !formik.values.is_customer_block_visible
                                }
                              />
                            </div>
                          </Stack>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography variant="h4">Pickup</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack space="medium">
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Type"
                              name="pickup.venue.is_business_type_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <Stack>
                            <Typography variant="h5">Contact</Typography>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Title"
                                name="pickup.venue.is_contact_title_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Mobile"
                                name="pickup.venue.is_contact_mobile_phone_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Email"
                                name="pickup.venue.is_contact_email_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>
                          </Stack>

                          <Stack>
                            <Typography variant="h5">Dates</Typography>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="1st Avail. Pickup Date"
                                name="pickup.is_first_available_pickup_date_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Customer Pickup Date"
                                name="pickup.is_scheduled_at_by_customer_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Adjusted Pickup Date"
                                name="pickup.is_adjusted_date_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>
                          </Stack>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography variant="h4">Delivery</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack space="medium">
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Type"
                              name="delivery.venue.is_business_type_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <Stack>
                            <Typography variant="h5">Contact</Typography>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Title"
                                name="delivery.venue.is_contact_title_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Mobile"
                                name="delivery.venue.is_contact_mobile_phone_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Email"
                                name="delivery.venue.is_contact_email_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>
                          </Stack>

                          <Stack>
                            <Typography variant="h5">Dates</Typography>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Customer Delivery Date"
                                name="delivery.is_scheduled_at_by_customer_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>

                            <div onClick={showNoPermissionsSnackbar}>
                              <FormikCheckboxField
                                label="Adjusted Delivery Date"
                                name="delivery.is_adjusted_date_visible"
                                disabled={!canUpdateFormSettings}
                              />
                            </div>
                          </Stack>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography variant="h4">Carrier Payment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack>
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Due from Carrier"
                              name="payment.is_broker_fee_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Paid Amount"
                              name="payment.is_amount_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Reference Number"
                              name="payment.is_reference_number_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Sent Date"
                              name="payment.is_sent_date_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>

                  <StyledAccordion>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel8a-content"
                      id="panel8a-header"
                    >
                      <Typography variant="h4">Customer Payment</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box pl={2}>
                        <Stack>
                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Deposit"
                              name="customer_payment.is_deposit_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="COD/COP Amount"
                              name="customer_payment.is_cod_cop_amount_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Paid Amount"
                              name="customer_payment.is_amount_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Paid Method"
                              name="customer_payment.is_method_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Reference Number"
                              name="customer_payment.is_reference_number_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>

                          <div onClick={showNoPermissionsSnackbar}>
                            <FormikCheckboxField
                              label="Received Date"
                              name="customer_payment.is_received_date_visible"
                              disabled={!canUpdateFormSettings}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>
                </Box>
              </Stack>
            </form>
          </Box>
        </Container>
      </FormikProvider>
    </PageLayout>
  );
}
