import { hasOnlyDigits, ONLY_DIGITS_PATTERN } from 'shared/utils/StringUtils';
import {
  yupBoolean,
  yupEnum,
  yupNumber,
  yupObject,
  yupPhone,
  yupString,
} from 'shared/utils/YupUtils';
import { JOB_TITLES, USA_STATES } from 'superpay/data/MoovDTO';
import { InferType } from 'yup';

export const ownerAddFormValidationSchema = yupObject({
  name: yupObject({
    firstName: yupString().max(64).required('Enter first name').defined(),
    lastName: yupString().max(64).required('Enter last name').defined(),
  }),
  phone: yupPhone().required('Enter phone number').defined().nullable(),
  email: yupString().max(255).email().required('Invalid email').defined(),
  birthDate: yupObject({
    month: yupNumber().min(1).max(12).required(),
    day: yupNumber()
      .min(1)
      .required()
      .test('isDayValid', 'Invalid Date of Birth', (value, context) => {
        const { month, year } = context.parent;
        return !!(
          value &&
          month &&
          year &&
          value <= new Date(year, month, 0).getDate()
        );
      }),
    year: yupNumber().min(1900).max(new Date().getFullYear()).required(),
  }),
  address: yupObject({
    addressLine1: yupString()
      .max(60)
      .required('Enter street address')
      .defined(),
    city: yupString().max(24).required('Enter city').defined(),
    stateOrProvince: yupString()
      .oneOf(USA_STATES, 'Invalid state')
      .required('Enter state')
      .defined(),
    postalCode: yupString()
      .test('only zip digits', 'Invalid ZIP', hasOnlyDigits)
      .length(5)
      .required('Invalid ZIP')
      .defined(),
  }),
  governmentID: yupObject({
    ssn: yupObject({
      full: yupString()
        .matches(ONLY_DIGITS_PATTERN, 'Invalid SSN')
        .length(9)
        .required('Invalid SSN')
        .defined()
        .nullable(),
    }),
  }),
  responsibilities: yupObject({
    isController: yupBoolean().default(false),
    ownershipPercentage: yupNumber()
      .min(25, 'Percent ownership cannot be less than 25')
      .max(100, 'Percent ownership cannot be more than 100')
      .required('Invalid percent ownership')
      .defined(),
    jobTitle: yupEnum(JOB_TITLES, 'manager').required(),
  }),
});

export type OwnerAddFormValues = InferType<typeof ownerAddFormValidationSchema>;
