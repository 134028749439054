import { useAPI } from 'shared/api/API';
import { useAPIQuery } from 'shared/api/APIQuery';
import {
  yupArray,
  yupNumber,
  yupObject,
  yupString,
} from 'shared/utils/YupUtils';
import { InferType } from 'yup';

const subscriptionPlanGroupCodes = [
  'express',
  'pro-100',
  'pro-200',
  'pro-500',
  'pro-1000',
  'pro-2500',
  'pro-5000',
  'pro-10000',
] as const;

export const subscriptionPlanCodes = [
  'express-monthly',

  'pro-100-monthly',
  'pro-100-yearly',

  'pro-200-monthly',
  'pro-200-yearly',

  'pro-500-monthly',
  'pro-500-yearly',

  'pro-1000-monthly',
  'pro-1000-yearly',

  'pro-2500-monthly',
  'pro-2500-yearly',

  'pro-5000-monthly',
  'pro-5000-yearly',

  'pro-10000-yearly',
] as const;

const subscriptionPlanSchema = yupObject({
  plan_code: yupString().oneOf(subscriptionPlanCodes).required(),
  volume_min: yupNumber().required(),
  volume_max: yupNumber().required(),
  max_monthly_posted_vins: yupNumber().required(),
  monthly_price: yupNumber().required(),
  overage_fee: yupNumber().required(),
});

const subscriptionPlanGroupSchema = yupObject({
  plan_group_code: yupString().oneOf(subscriptionPlanGroupCodes).required(),
  name: yupString().required(),
  plans: yupObject({
    yearly: subscriptionPlanSchema.notRequired(),
    monthly: subscriptionPlanSchema.notRequired(),
  }).required(),
  non_discountable_annual_price: yupNumber(),
  annual_price: yupNumber(),
  discount_percent: yupNumber(),
});

const subscriptionPlanGroupsSchema = yupArray(subscriptionPlanGroupSchema);

export type SubscriptionPlanGroup = InferType<
  typeof subscriptionPlanGroupSchema
>;

export type SubscriptionPlanGroupCode =
  SubscriptionPlanGroup['plan_group_code'];

export type SubscriptionPlan = InferType<typeof subscriptionPlanSchema>;
export type SubscriptionPlanCode = SubscriptionPlan['plan_code'];

export function useSubscriptionPlans() {
  const { requestJSON } = useAPI();
  const { data: subscriptionPlanGroups, isLoading } = useAPIQuery(
    ['subscription-plans', 'plans'],
    () =>
      requestJSON('GET /internal/subscription-plans').then((data) => {
        return subscriptionPlanGroupsSchema.cast(data);
      }),
  );

  return {
    subscriptionPlanGroups,
    isSubscriptionPlansLoading: isLoading,
  };
}
