import { Grid } from '@material-ui/core';
import { Color, Inline, OverflowText, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { startCase } from 'lodash-es';
import { ViewRouteLink } from 'shared/ui/ViewRoute';
import { formatMiles } from 'shared/utils/MeasurementUtils';
import { joinStrings } from 'shared/utils/StringUtils';
import styled from 'styled-components';

const GutterRoot = styled.div`
  height: 100%;
  padding: 4px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GutterPoint = styled.div`
  width: 8px;
  height: 8px;
  display: block;
  border-radius: 50%;

  &[data-start='true'] {
    background: ${Color.Yellow300};
  }

  &[data-middle='true'] {
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid ${Color.Dark100};
  }

  &[data-end='true'] {
    background: #1fa84d;
  }
`;

const GutterLine = styled.div`
  flex: 1;
  width: 2px;
  background: repeating-linear-gradient(
    ${Color.Silver500},
    ${Color.Silver500} 3px,
    transparent 3px,
    transparent 5px
  );
`;

export function formatPostingAddress(
  city: string | null,
  state: string | null,
  zip: string | null,
): string {
  const cityAndState = joinStrings(
    ', ',
    startCase(city?.toLowerCase()),
    state?.toUpperCase(),
  );

  return joinStrings(' ', cityAndState, zip);
}

function Gutter() {
  return (
    <GutterRoot>
      <GutterPoint data-start="true" />
      <GutterLine />
      <GutterPoint data-end="true" />
    </GutterRoot>
  );
}

interface Address {
  city?: string | null;
  state?: string | null;
  zip?: string | null;
}

export interface PostingWaypointsProps {
  pickup: Address;
  delivery: Address;
  distanceMiles?: number | null;
}

export function LoadWaypoints({
  pickup,
  delivery,
  distanceMiles,
}: PostingWaypointsProps) {
  const origin = formatPostingAddress(
    pickup.city || null,
    pickup.state || null,
    pickup.zip || null,
  );
  const destination = formatPostingAddress(
    delivery.city || null,
    delivery.state || null,
    delivery.zip || null,
  );

  return (
    <Stack space="xxsmall">
      <Grid container={true} spacing={1} wrap="nowrap">
        <Grid item={true}>
          <Gutter />
        </Grid>

        <Grid item={true} xs={11}>
          <Stack space="xsmall">
            <OverflowText aria-label="Pickup address" disableUnderline={true}>
              {origin}
            </OverflowText>

            <OverflowText aria-label="Delivery address" disableUnderline={true}>
              {destination}
            </OverflowText>
          </Stack>
        </Grid>
      </Grid>

      <Inline space="xxsmall">
        <ViewRouteLink origin={origin} destination={destination} />
        {distanceMiles != null && (
          <TextBox
            noWrap={true}
            wrapOverflow={true}
            color="secondary"
            aria-label="Delivery address"
          >
            &#xb7; {formatMiles(distanceMiles)}
          </TextBox>
        )}
      </Inline>
    </Stack>
  );
}
