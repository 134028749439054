import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Link,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { Inline, Stack, useResponsiveValue } from '@superdispatch/ui';
import { useState } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { useCanExecute } from 'shared/data/UserPermissions';
import { useSearchInput } from 'shared/helpers/ReactHelpers';
import { useTryBack } from 'shared/helpers/RouteHelpers';
import { useDocumentTitle } from 'shared/layout/DocumentTitle';
import { PageLayout } from 'shared/layout/PageLayout';
import styled from 'styled-components';
import { useUsersList } from './data/ManageUsersAPI';
import { ManageUsersFooter } from './ManageUsersFooter';
import { User } from './User';
import { UserCreateDrawer } from './UserCreateDrawer';
import { UserEditDrawer } from './UserEditDrawer';
import { UserResetPasswordDrawer } from './UserResetPasswordDrawer';
import { UserSearchField } from './UserSearchField';

const AppBarSettingsV2 = styled(AppBar)`
  border-left: none;
  border-top: none;
`;

const StyledBox = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledButton = styled(Button)`
  min-width: 120px;
`;

export function ManageUsersPage() {
  const platform = useResponsiveValue('mobile', 'tablet', 'desktop');
  const isDesktop = platform === 'desktop';
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useSearchInput(query);
  const { user: currentUser } = useUserState();
  const canManageUser = useCanExecute('USER_MANAGEMENT');
  const [page, setPage] = useState<number>(0);
  const { data, refetch } = useUsersList(page, 10, debouncedQuery);
  let { objects: users = [], pagination } = data || {};
  const navigate = useNavigate();
  const { tryBack } = useTryBack();
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');
  const createDrawerMatch = useMatch(
    isSettingsV2Enabled ? '/settings/users/create' : '/users/create',
  );
  const editDrawerMatch = useMatch(
    isSettingsV2Enabled
      ? '/settings/users/edit/:user_guid'
      : '/users/edit/:user_guid',
  );
  const resetPassswordDrawerMatch = useMatch(
    isSettingsV2Enabled
      ? '/settings/users/reset-password/:user_guid'
      : '/users/reset-password/:user_guid',
  );

  useDocumentTitle('Manage Users');

  function handlePageChange({ selected: selectedPage }: { selected: number }) {
    setPage(selectedPage);
  }

  function handleSearch(searchQuery: string) {
    setQuery(searchQuery);
  }

  const openUserCreateDrawer = () =>
    navigate(isSettingsV2Enabled ? '/settings/users/create' : '/users/create');
  const closeUserManagementDrawer = () =>
    tryBack({ pathname: isSettingsV2Enabled ? '/settings/users' : '/users' });

  const AppBarComponent = isSettingsV2Enabled ? AppBarSettingsV2 : AppBar;

  return (
    <PageLayout
      isContentLoading={!currentUser}
      header={
        <AppBarComponent>
          <Toolbar>
            <Box minWidth={0} flex="1" ml={isSettingsV2Enabled ? 2 : undefined}>
              <Typography variant="h2">Users</Typography>
            </Box>

            <UserSearchField query={query} handleSearch={handleSearch} />

            <StyledBox flex={isDesktop ? '1' : '0'} ml={1}>
              {canManageUser && (
                <StyledButton
                  variant="contained"
                  type="submit"
                  onClick={openUserCreateDrawer}
                >
                  Add User
                </StyledButton>
              )}
            </StyledBox>
          </Toolbar>
        </AppBarComponent>
      }
      footer={
        <ManageUsersFooter
          page={pagination?.page}
          totalPages={pagination?.total_pages}
          handlePageChange={handlePageChange}
        />
      }
    >
      <>
        <UserCreateDrawer
          open={!!createDrawerMatch}
          onClose={closeUserManagementDrawer}
          onUpdate={() => void refetch()}
        />

        <UserEditDrawer
          open={!!editDrawerMatch}
          onClose={closeUserManagementDrawer}
          onUpdate={() => void refetch()}
          user={users.find(
            (user) => user.guid === editDrawerMatch?.params.user_guid,
          )}
        />

        <UserResetPasswordDrawer
          open={!!resetPassswordDrawerMatch}
          onClose={closeUserManagementDrawer}
          onUpdate={() => void refetch()}
          user={users.find(
            (user) => user.guid === resetPassswordDrawerMatch?.params.user_guid,
          )}
        />

        <Container maxWidth="md" disableGutters={!isSettingsV2Enabled}>
          <Box my={2}>
            <Stack space="small">
              <Box display="flex" justifyContent="flex-end" pr={2}>
                <Inline space="xxsmall" verticalAlign="center">
                  <Help fontSize="small" color="action" />

                  <Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://support.superdispatch.com/en/articles/5952237-user-roles-and-their-default-permissions"
                  >
                    How do User Roles Work
                  </Link>
                </Inline>
              </Box>
              <Card>
                <CardContent>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <User
                        user={user}
                        key={user.guid}
                        onUpdate={() => void refetch()}
                      />
                    ))
                  ) : (
                    <Typography>No users found</Typography>
                  )}
                </CardContent>
              </Card>
            </Stack>
          </Box>
        </Container>
      </>
    </PageLayout>
  );
}
