import {
  ButtonBase,
  Drawer,
  IconButton,
  Link,
  Tab,
  Tabs,
  Tooltip,
} from '@material-ui/core';
import {
  ArrowForward,
  Close,
  FlagOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
} from '@material-ui/icons';
import {
  Color,
  Column,
  Columns,
  DrawerContent,
  DrawerTitle,
  Inline,
  Stack,
} from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { MapboxDirectionsMapLazy } from 'shared/helpers/MapboxDirectionsMapLazy';
import { useTrackEventWithSource } from 'shared/helpers/SourceManager';
import { LocationsIcon } from 'shared/icons/LocationsIcon';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import Order from 'shared/types/order';
import {
  makeAddressCityStateZip,
  makeCityStateZip,
} from 'shared/utils/AddressUtils';
import { kmToMile } from 'shared/utils/MeasurementUtils';
import styled from 'styled-components';
import { PricePerVehicles } from './PricePerVehicles';
import {
  isCanadianAddress,
  validateOrder,
} from './PricingRecommendationHelpers';
import { RecentLoads } from './RecentLoads';

function formatDistance(distance: number) {
  return `${kmToMile(distance / 1000, 1)} mi`;
}

function trackIncorrectPriceReport(
  order: Partial<Order>,
  distance: number | undefined,
) {
  trackEvent('Shipper Clicked Report Incorrect Price on Pricing Insights', {
    order_id: order.id,
    order_guid: order.guid,
    order_number: order.number,
    order_created_at: order.created_at,
    price: order.price,
    pickup: makeCityStateZip(order.pickup?.venue),
    pickup_latitude: order.pickup?.latitude,
    pickup_longitude: order.pickup?.longitude,
    delivery_latitude: order.delivery?.latitude,
    delivery_longitude: order.delivery?.longitude,
    distance_km: distance ? distance / 1000 : undefined,
    delivery: makeCityStateZip(order.delivery?.venue),
    recommended_price: order.recommended_price,
    transport_type: order.transport_type,
    vehicles: JSON.stringify(
      order.vehicles?.map((vehicle) => ({
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        type: vehicle.type,
        is_inoperable: vehicle.is_inoperable,
        vin: vehicle.vin,
        curb_weight: vehicle.curb_weight,
      })),
    ),
  });
}

function ExpandButton({
  isExpanded,
  onClick,
  children,
}: {
  children: ReactNode;
  onClick: () => void;
  isExpanded: boolean;
}) {
  return (
    <ButtonBase onClick={onClick}>
      <Inline space="xsmall" verticalAlign="center">
        <TextBox color="secondary">{children}</TextBox>

        {isExpanded ? (
          <KeyboardArrowUp color="action" />
        ) : (
          <KeyboardArrowDown color="action" />
        )}
      </Inline>
    </ButtonBase>
  );
}

const Baseline = styled.div<{ gap: number }>`
  display: flex;
  align-items: baseline;

  gap: ${({ gap }) => gap}px;
`;

interface PricingInsightsDrawerContentProps {
  order: Partial<Order>;
  onClose: () => void;
}

export function PricingInsightsDrawerContent({
  order,
  onClose,
}: PricingInsightsDrawerContentProps) {
  const trackEventWithSource = useTrackEventWithSource();

  const [tab, setTab] = useState('recent-moves');
  const [isMapExpanded, setMapExpanded] = useState(false);
  const [isPriceTableExpanded, setPriceTableExpanded] = useState(false);
  const [distance, setDistance] = useState<number | undefined>(
    order.distance_meters,
  );

  const [origin, destination] = useMemo(
    () => [
      order.pickup && makeAddressCityStateZip(order.pickup.venue),
      order.delivery && makeAddressCityStateZip(order.delivery.venue),
    ],
    [order.pickup, order.delivery],
  );

  const validateErrorText = useMemo(() => validateOrder(order), [order]);

  useEffect(() => {
    const openedAt = DateTime.now();
    const filledVehicles = order.vehicles?.filter((x) => x.type !== 'other');

    trackEventWithSource('[STMS] Opened Pricing Insights Modal', {
      origin,
      destination,
      filled_vehicle_count: filledVehicles?.length,
    });

    return () => {
      const difference = DateTime.now().diff(openedAt).as('seconds');
      trackEvent('[STMS] Closed Pricing Insights Modal', {
        duration: Math.round(difference),
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to track open and close events
  }, []);

  return (
    <DrawerContent>
      <Stack space="large">
        <Stack>
          <Columns space="small" collapseBelow="tablet">
            <Column>
              <Inline space="xsmall" verticalAlign="center">
                <LocationsIcon fontSize="small" color="action" />

                {order.pickup && (
                  <TextBox color="secondary">
                    {makeCityStateZip(order.pickup.venue)}
                  </TextBox>
                )}

                <ArrowForward fontSize="small" color="action" />

                {order.delivery && (
                  <TextBox color="secondary">
                    {makeCityStateZip(order.delivery.venue)}
                  </TextBox>
                )}

                {!!distance && (
                  <TextBox color="secondary">
                    {' '}
                    · {formatDistance(distance)}
                  </TextBox>
                )}
              </Inline>
            </Column>

            <Column width="content">
              <ExpandButton
                isExpanded={isMapExpanded}
                onClick={() => {
                  setMapExpanded((prev) => !prev);
                  trackEvent('Shipper Clicked Map View on Pricing Insights');
                }}
              >
                {isMapExpanded ? 'Hide' : 'Show'} Map View
              </ExpandButton>
            </Column>
          </Columns>

          {origin && destination && (
            // always render in order to run Mapbox directions
            <Box paddingLeft="medium" borderRadius="small">
              <MapboxDirectionsMapLazy
                origin={origin}
                destination={destination}
                style={
                  isMapExpanded
                    ? { width: '100%', height: 400 }
                    : { visibility: 'hidden' }
                }
                onRoute={(route) => {
                  if (route[0]) {
                    setDistance(route[0].distance);
                  }
                }}
              />
            </Box>
          )}
        </Stack>

        <div data-intercom-target="Recommended Price">
          <Stack space="xsmall">
            <Inline space="xsmall" verticalAlign="center">
              <RecommendedPriceIcon fontSize="small" color="action" />
              <TextBox variant="heading-4">Recommended Price</TextBox>
            </Inline>

            <Box paddingLeft="medium">
              <Stack space="small">
                <Columns align="center" collapseBelow="tablet" space="xsmall">
                  <Column>
                    {validateErrorText?.length ? (
                      <Box
                        padding="xsmall"
                        borderRadius="small"
                        display="inline-block"
                        backgroundColor="Yellow50"
                      >
                        <Columns space="xsmall" align="center">
                          <Column width="content">
                            <Warning
                              fontSize="small"
                              htmlColor={Color.Yellow300}
                            />
                          </Column>
                          <Column width="adaptive">
                            <TextBox>{validateErrorText}</TextBox>
                          </Column>
                        </Columns>
                      </Box>
                    ) : order.recommended_price ? (
                      <Baseline gap={16}>
                        <Baseline gap={8}>
                          <TextBox variant="heading-1">
                            {formatCurrency(order.recommended_price, {
                              maximumFractionDigits: 0,
                            })}
                          </TextBox>

                          {!!distance && (
                            <Tooltip title="Price per Vehicle">
                              <Link color="textSecondary">
                                {formatCurrency(
                                  order.recommended_price /
                                    kmToMile(distance / 1000) /
                                    (order.vehicles?.length || 1),
                                  { maximumFractionDigits: 2 },
                                )}
                                /mi
                              </Link>
                            </Tooltip>
                          )}
                        </Baseline>

                        <Inline space="xxsmall" verticalAlign="center">
                          <FlagOutlined fontSize="small" color="action" />

                          <Link
                            href="#"
                            color="textSecondary"
                            data-intercom-target="report incorrect price"
                            onClick={() => {
                              onClose();
                              trackIncorrectPriceReport(order, distance);
                            }}
                          >
                            Report incorrect price
                          </Link>
                        </Inline>
                      </Baseline>
                    ) : (
                      <TextBox color="secondary">
                        Recommended Price not available for the chosen ZIP
                        codes.
                      </TextBox>
                    )}
                  </Column>

                  {!isCanadianAddress(order.pickup) &&
                    !isCanadianAddress(order.delivery) && (
                      <Column width="content">
                        <ExpandButton
                          isExpanded={isPriceTableExpanded}
                          onClick={() => {
                            setPriceTableExpanded((prev) => !prev);
                            trackEvent(
                              'Shipper Clicked Price per Vehicle Type on Pricing Insights',
                            );
                          }}
                        >
                          {isPriceTableExpanded ? 'Hide' : 'Show'} Price per
                          Type
                        </ExpandButton>
                      </Column>
                    )}
                </Columns>

                {isPriceTableExpanded && <PricePerVehicles order={order} />}
              </Stack>
            </Box>
          </Stack>
        </div>

        <Stack space="medium">
          <Box
            borderBottomWidth="small"
            borderColor="Silver400"
            data-intercom-target="Recent Moves & Postings Tabs"
          >
            <Tabs
              value={tab}
              onChange={(_, value) => {
                setTab(value);
                trackEvent(
                  value === 'recent-postings'
                    ? 'Shipper Clicked Posted to Super Loadboard Tab on Pricing Insights'
                    : 'Shipper Clicked Recent Moves Tab on Pricing Insights',
                );
              }}
            >
              <Tab value="recent-moves" label="Recent Moves" />
              <Tab value="recent-postings" label="Posted to Super Loadboard" />
            </Tabs>
          </Box>

          {tab === 'recent-moves' && <RecentLoads type="moves" order={order} />}

          {tab === 'recent-postings' && (
            <RecentLoads type="postings" order={order} />
          )}
        </Stack>
      </Stack>
    </DrawerContent>
  );
}

interface PricingInsightsDrawerProps extends PricingInsightsDrawerContentProps {
  open: boolean;
  onClose: () => void;
}

export function PricingInsightsDrawer({
  open,
  order,
  onClose,
}: PricingInsightsDrawerProps) {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={{ style: { maxWidth: 720, width: 720 } }}
    >
      <DrawerTitle
        title="Pricing Insights"
        endAction={
          <IconButton edge="end" onClick={onClose}>
            <Close aria-label="close" />
          </IconButton>
        }
      />

      <PricingInsightsDrawerContent order={order} onClose={onClose} />
    </Drawer>
  );
}
