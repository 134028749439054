import { VerificationDialogs } from 'shipper-profile/core/VerificationDialogs';
import { OrderActionsAnalyticsProperties } from '../../../shipper-profile/data/VerificationAnalyticsEvents';
import {
  APIOrderErrorResponse,
  isOrderError,
  OrderErrorModal,
} from './OrderErrorModal';

function isOrdersHaveVerificationErrors(error: APIOrderErrorResponse) {
  if (error.error_id === 'SHIPPER_NOT_VERIFIED') {
    return true;
  }

  if (!error.details) {
    return false;
  }

  const errors = Object.values(error.details);
  return errors.every(
    (orderError) =>
      typeof orderError !== 'string' &&
      orderError.error_id === 'SHIPPER_NOT_VERIFIED',
  );
}

interface OrderErrorsProps extends OrderActionsAnalyticsProperties {
  onCancel: () => void;
  error: unknown;
}

export function OrderErrors({ error, onCancel, ...rest }: OrderErrorsProps) {
  if (isOrderError(error) && isOrdersHaveVerificationErrors(error)) {
    return <VerificationDialogs open={true} onClose={onCancel} {...rest} />;
  }

  return <OrderErrorModal error={error} onCancel={onCancel} />;
}
