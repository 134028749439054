import { Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { DateTime } from 'luxon';
import { calculatePriceNegotiationInfo } from 'orders/data/dto/PriceNegotiationDTO';
import { usePriceNegotiation } from 'orders/data/PriceNegotiationAPI';
import { useMemo } from 'react';
import { NumericFormat } from 'react-number-format';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import Order from 'shared/types/order';

export function OrderRequestPriceNegotiationInfo({ order }: { order: Order }) {
  const today = DateTime.local().startOf('day');
  const { data: priceNegotiation } = usePriceNegotiation(order);

  const priceNegotiationInfo = useMemo(
    () =>
      priceNegotiation?.active
        ? calculatePriceNegotiationInfo(order.price, priceNegotiation)
        : null,
    [order.price, priceNegotiation],
  );

  if (!priceNegotiationInfo) return null;

  return (
    <Inline space="xxsmall">
      <TextBox variant="body-semibold" color="secondary">
        <NumericFormat
          prefix="$"
          displayType="text"
          thousandSeparator={true}
          value={
            priceNegotiationInfo.currentIteration > 0
              ? formatCurrency(priceNegotiationInfo.currentPrice, {
                  maximumFractionDigits: 0,
                })
              : order.price
          }
        />
      </TextBox>
      &#xb7;
      <TextBox color="secondary">
        {priceNegotiationInfo.currentIteration > 0 &&
        priceNegotiation?.increase_by_percent &&
        priceNegotiation.increase_every
          ? `Day ${priceNegotiationInfo.currentDay} Acceptable Bid${
              priceNegotiationInfo.maxDate.startOf('day') <= today
                ? ', Max bid reached'
                : ''
            }`
          : 'Acceptable bid'}
      </TextBox>
    </Inline>
  );
}
