import { useQueryClient } from 'react-query';
import { apiInstance } from 'shared/api/API';
import { useAPIMutation } from 'shared/api/APIMutation';
import {
  APIQueryOptions,
  APIQueryResult,
  useAPIQuery,
} from 'shared/api/APIQuery';
import {
  alertSchema,
  disableDatesExpectedChargeSchema,
  dynamicStatisticsSchema,
  expectedChargeDateScheduleSchema,
  nowPreviewSchema,
  requestedCarriersSchema,
  SuperPayEditDTO,
  SuperPaySettingsDTO,
  superPaySettingsSchema,
} from '../../../superpay/data/SuperPayDTO';

export function useSuperPaySettings(
  options?: APIQueryOptions<SuperPaySettingsDTO>,
): APIQueryResult<SuperPaySettingsDTO> {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'settings'],
    () =>
      requestResource('GET /internal/payments/super_pay_settings', (data) =>
        superPaySettingsSchema.cast(data),
      ),
    options,
  );
}

export function useSuperPaySettingsCache() {
  const queryClient = useQueryClient();

  function invalidateSuperPaySettings() {
    void queryClient.invalidateQueries(['super-pay', 'settings']);
  }

  return { invalidateSuperPaySettings };
}

export function useSuperPayEdit(orderId: number) {
  const { requestResource } = apiInstance;
  return useAPIMutation((params: SuperPayEditDTO) =>
    requestResource(
      'PUT /internal/orders/{orderId}/update_payment',
      (data) => data,
      { orderId, json: params },
    ),
  );
}

export function useExpectedChargeDateSchedule(expectedDate: string | null) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'expected-charge-date', { expectedDate }],
    () =>
      requestResource(
        'POST /internal/payments/expected_charge_date',
        (data) => expectedChargeDateScheduleSchema.cast(data),
        { json: { expected_date: expectedDate } },
      ),
    {
      enabled: !!expectedDate,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );
}

export function useDisableDatesExpectedCharge() {
  const { requestResource } = apiInstance;
  return useAPIQuery(['super-pay', 'expected-disable-charge-date'], () =>
    requestResource('GET /internal/payments/disabled_dates', (data) =>
      disableDatesExpectedChargeSchema.cast(data),
    ),
  );
}

export function useDynamicStatistics() {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'dynamic-statistics-promo'],
    () =>
      requestResource(
        'GET /internal/payments/pre_onboarding_statistics',
        (data) => dynamicStatisticsSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useSuperPayRequestedCarriers() {
  const { requestPage } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'requested-carriers'],
    () =>
      requestPage(
        'GET /internal/payments/superpay_requested_carriers',
        (data) => requestedCarriersSchema.cast(data),
      ),
    { refetchOnWindowFocus: false },
  );
}

export function useSuperPayAlert(enabled = true) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'alerts'],
    () =>
      requestResource('GET /internal/payments/alert', (data) =>
        alertSchema.cast(data),
      ),
    { enabled },
  );
}

export function useSuperPayNowPreview(order_guids: string[]) {
  const { requestResource } = apiInstance;
  return useAPIQuery(
    ['super-pay', 'now-preview'],
    () =>
      requestResource(
        'GET /internal/orders/payments/superpay_now_preview{?order_guids}',
        (data) => nowPreviewSchema.cast(data),
        { order_guids },
      ),
    { refetchOnWindowFocus: false },
  );
}
