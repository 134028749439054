import { Tag } from '@superdispatch/ui';
import {
  ApprovedTag,
  BlacklistedTag,
  CanExpeditePaymentTag,
  CanInstantlyBookTag,
  InsuranceCertificateHolderTag,
  InsuranceExpiredTag,
  PrivateNetworkTag,
} from 'core/CarrierTags';
import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

const TagContainer = styled.div`
  min-width: fit-content;
`;

function getTags(request: OrderRequestDTO) {
  const brokerRecords = request.broker_records;
  return [
    brokerRecords?.approved && (
      <TagContainer key="approved">
        <ApprovedTag />
      </TagContainer>
    ),
    brokerRecords?.preferred && (
      <TagContainer key="preferred">
        <CanInstantlyBookTag />
      </TagContainer>
    ),
    brokerRecords?.can_expedite_payment && (
      <TagContainer key="can-expedite-payment">
        <CanExpeditePaymentTag />
      </TagContainer>
    ),
    brokerRecords?.is_in_private_network &&
      brokerRecords.private_network_groups && (
        <TagContainer key="private-network">
          <PrivateNetworkTag groups={brokerRecords.private_network_groups} />
        </TagContainer>
      ),
    brokerRecords?.insurance_certificate_holder && (
      <TagContainer key="insurance-certificate-holder">
        <InsuranceCertificateHolderTag />
      </TagContainer>
    ),
    request.is_insurance_expired && (
      <TagContainer key="insurance-expired">
        <InsuranceExpiredTag />
      </TagContainer>
    ),
    brokerRecords?.in_blacklist && (
      <TagContainer key="blacklisted">
        <BlacklistedTag />
      </TagContainer>
    ),
  ].filter(Boolean);
}

export function useVisibleTags(
  containerRef: React.RefObject<HTMLElement>,
  compact?: boolean,
) {
  const [visibleTags, setVisibleTags] = useState<number>(Infinity);

  useEffect(() => {
    if (!compact) {
      setVisibleTags(Infinity);
      return;
    }

    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      // reset visible tags when resize
      setVisibleTags(Infinity);

      const VIEW_MORE_WIDTH = 40;
      const SCREEN_WIDTH = window.innerWidth;
      const containerWidth = container.offsetWidth;
      const maxWidth = Math.min(SCREEN_WIDTH, containerWidth) - VIEW_MORE_WIDTH;

      let totalWidth = 0;
      let visibleCount = 0;

      const tags = Array.from(container.children);
      for (const tag of tags) {
        totalWidth += (tag as HTMLElement).offsetWidth;
        if (totalWidth <= maxWidth) {
          visibleCount++;
        } else {
          break;
        }
      }

      setVisibleTags(visibleCount);
    };

    calculateVisibleTags();

    window.addEventListener('resize', calculateVisibleTags);
    return () => window.removeEventListener('resize', calculateVisibleTags);
  }, [containerRef, compact]);

  return visibleTags;
}

const Container = styled.div`
  display: flex;
  gap: 8px;
  position: relative;
`;

const TagsContainer = styled.div<{ $compact?: boolean }>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  ${({ $compact }) =>
    $compact &&
    css`
      flex-wrap: nowrap;
      overflow: hidden;
      max-width: 100%;
    `}
`;

interface OrderRequestCarrierTagsProps {
  compact?: boolean;
  request: OrderRequestDTO;
}

export function OrderRequestCarrierTags({
  request,
  compact,
}: OrderRequestCarrierTagsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const allTags = getTags(request);
  const visibleTags = useVisibleTags(containerRef, compact);

  const visibleTagElements = allTags.slice(0, visibleTags);
  const hiddenTagsCount = Math.max(0, allTags.length - visibleTags);

  return (
    <Container>
      <TagsContainer ref={containerRef} $compact={compact}>
        {visibleTagElements}
      </TagsContainer>

      {hiddenTagsCount > 0 && (
        <Tag color="grey" variant="subtle">
          +{hiddenTagsCount} more
        </Tag>
      )}
    </Container>
  );
}
