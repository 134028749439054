import { Drawer, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  DrawerTitle,
  ExitTransitionPlaceholder,
  Stack,
} from '@superdispatch/ui';
import { Box } from '@superdispatch/ui-lab';
import Order from 'shared/types/order';
import { PostingStatistics } from '../posting/PostingStatistics';
import { PostingView } from '../posting/PostingView';
import { OrderRequests } from './OrderRequests';
import { OrderRequestsRecentPostings } from './OrderRequestsRecentPostings';

interface OrderRequestsDrawerProps {
  order?: Order;
  onClose: () => void;
  onSubmitSuccess: (order: Order) => void;
}

export function OrderRequestsDrawer({
  order,
  onClose,
  onSubmitSuccess,
}: OrderRequestsDrawerProps) {
  return (
    <Drawer
      open={!!order}
      onClose={onClose}
      PaperProps={{ style: { maxWidth: 900, width: '100%' } }}
    >
      <Box borderBottomWidth="small" borderColor="Silver400">
        <DrawerTitle
          title="Carrier Requests"
          endAction={
            <IconButton edge="end" onClick={onClose}>
              <Close aria-label="close" />
            </IconButton>
          }
        />
      </Box>
      <ExitTransitionPlaceholder in={!!order}>
        {!!order && (
          <OrderRequestsDrawerContent
            order={order}
            onSubmitSuccess={onSubmitSuccess}
          />
        )}
      </ExitTransitionPlaceholder>
    </Drawer>
  );
}

function OrderRequestsDrawerContent({
  order,
  onSubmitSuccess,
}: {
  order: Order;
  onSubmitSuccess: (order: Order) => void;
}) {
  return (
    <Box overflowY="auto">
      <Box padding="large" backgroundColor="Silver200">
        <Stack space="small">
          <PostingStatistics order={order} />

          {order.is_posted_to_loadboard && (
            <>
              <PostingView order={order} />

              <OrderRequestsRecentPostings order={order} />
            </>
          )}
        </Stack>
      </Box>

      <OrderRequests order={order} onSubmitSuccess={onSubmitSuccess} />
    </Box>
  );
}
