import { Dialog, Typography } from '@material-ui/core';
import { useConstant } from '@superdispatch/hooks';
import { Inline, Stack } from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useNavigate } from 'react-router-dom';
import { useOrderCounts } from 'shared/api/OrderCountAPI';
import { useStorageValue, writeStorageItem } from 'shared/helpers/LocalStorage';
import { useBoolean } from 'shared/helpers/ReactHelpers';
import styled from 'styled-components';
import createOrderBackground from './assets/create-order-bg.svg';
import createOrderImage from './assets/create-order-img.png';

const DIALOG_CLOSED_LS_KEY = 'is_create_order_onboarding_nudging_closed';

const OnboardingBox = styled(Box)({
  backgroundImage: `url(${createOrderBackground})`,
  backgroundRepeat: 'no-repeat',
});

function useHasOrders() {
  const { data: orderCounts } = useOrderCounts();

  if (!orderCounts) {
    return true;
  }

  const countersThatShouldBeZero: Array<keyof typeof orderCounts> = [
    'new',
    'pending',
    'declined',
    'accepted',
    'picked_up',
    'delivered',
    'invoiced',
    'paid',
    'canceled',
    'requests',
    'posted_to_lb',
  ];

  return countersThatShouldBeZero.some((key) => orderCounts[key] > 0);
}

export function CreateOrderDialog() {
  const hasClosedDialog = useStorageValue(DIALOG_CLOSED_LS_KEY);
  const hasClosedDialogConstant = useConstant(() => hasClosedDialog);
  const [open, _, closeDialog] = useBoolean(!hasClosedDialog);
  const navigate = useNavigate();
  const hasOrders = useHasOrders();

  if (hasOrders) {
    return null;
  }

  if (hasClosedDialogConstant) {
    return null;
  }

  const handleClose = () => {
    writeStorageItem(DIALOG_CLOSED_LS_KEY, String(true));
    closeDialog();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <OnboardingBox
        padding="large"
        paddingLeft="xlarge"
        paddingRight="xlarge"
        maxWidth="492px"
      >
        <Stack space="large" align="center">
          <img src={createOrderImage} width="287px" />
          <Stack space="small" align="center">
            <Typography
              variant="h4"
              align="center"
              color="textSecondary"
              noWrap={false}
            >
              Welcome to Super Dispatch!
            </Typography>

            <Typography variant="h2" align="center">
              Next, Create and Post Orders
            </Typography>
            <Typography align="center" color="textSecondary">
              Now you can start creating orders and posting them to Super
              Loadboard.
            </Typography>

            <Inline space="small">
              <Button
                variant="neutral"
                size="large"
                fullWidth={true}
                onClick={handleClose}
              >
                Do it Later
              </Button>
              <Button
                size="large"
                fullWidth={true}
                onClick={() => {
                  handleClose();
                  navigate('/orders/create');
                }}
              >
                Create Order
              </Button>
            </Inline>
          </Stack>
        </Stack>
      </OnboardingBox>
    </Dialog>
  );
}
