import { Box, CircularProgress } from '@material-ui/core';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from './ErrorBoundry';
import type { MapboxDirectionsMapProps } from './MapboxDirectionsMap';
const MapboxDirectionsMapComponent = lazy<
  React.ComponentType<MapboxDirectionsMapProps>
>(() =>
  import('./MapboxDirectionsMap').then((module) => ({
    default: module.MapboxDirectionsMap,
  })),
);

export function MapboxDirectionsMapLazy(props: MapboxDirectionsMapProps) {
  return (
    <ErrorBoundary
      fallback={
        <div style={props.style} className={props.className}>
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <div>Error loading map component</div>
          </Box>
        </div>
      }
    >
      <Suspense
        fallback={
          <div style={props.style} className={props.className}>
            <Box
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          </div>
        }
      >
        <MapboxDirectionsMapComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
}
