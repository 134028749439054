import { MenuItem } from '@material-ui/core';
import { AddAPhoto, Edit } from '@material-ui/icons';
import { FormikTextField } from '@superdispatch/forms';
import { formatVehicleType, VEHICLE_TYPES } from '@superdispatch/sdk';
import {
  AvatarButton,
  Column,
  Columns,
  OverflowText,
  useSnackbarStack,
} from '@superdispatch/ui';
import { Box, Button } from '@superdispatch/ui-lab';
import { useField } from 'formik';
import { useMemo, useRef } from 'react';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import styled from 'styled-components';
import { OrderFormVehiclePhotoDTO } from '../form/data/OrderFormVehicleDTO';
import { VehicleConditionIcon } from '../pricing-insights/VehicleConditionIcon';

const PhotoButton = styled(AvatarButton)`
  .MuiAvatar-root {
    width: 48px;
    height: 36px;
    border-radius: 4px;
  }

  .SD-AvatarButton-overlay {
    border-radius: 4px; /* TODO Support rounded avatar for overlay in sd/ui*/
  }
`;

interface VehicleSamplePhotoFieldProps {
  name: string;
}

export function VehicleSamplePhotoField({
  name,
}: VehicleSamplePhotoFieldProps) {
  const { addSnackbar } = useSnackbarStack();
  const inputRef = useRef<HTMLInputElement>(null);
  const [field, , { setValue }] = useField<
    OrderFormVehiclePhotoDTO[] | undefined
  >(name);
  const thumbnail = useMemo(
    () => field.value?.find((x) => x.photo_type === 'Sample'),
    [field.value],
  );

  const photoUrl = useMemo(
    () =>
      !thumbnail
        ? null
        : thumbnail.photo_file
        ? URL.createObjectURL(thumbnail.photo_file)
        : thumbnail.photo_url,
    [thumbnail],
  );

  function openUploadDialog() {
    inputRef.current?.click();
  }

  return (
    <div>
      {!photoUrl ? (
        <Button
          size="small"
          variant="text"
          onClick={openUploadDialog}
          startIcon={<AddAPhoto fontSize="small" color="primary" />}
        >
          Add Vehicle Photo
        </Button>
      ) : (
        <PhotoButton
          aria-label="thumbnail"
          size="small"
          icon={<Edit />}
          src={photoUrl}
          onClick={() => {
            openUploadDialog();
          }}
        >
          <AddAPhoto />
        </PhotoButton>
      )}

      <input
        type="file"
        hidden={true}
        ref={inputRef}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => {
          // Allow choosing same file
          // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file/54632736#54632736
          event.currentTarget.value = '';
        }}
        onChange={({ currentTarget: { files } }) => {
          const file = files?.[0];
          const maxAllowedSize = 20 * 1024 * 1024;

          if (!file) return;

          if (file.size > maxAllowedSize) {
            addSnackbar('The photo should be less than 20 Mb');
            return;
          }

          if (thumbnail) {
            void setValue([{ ...thumbnail, photo_file: file }]);
          } else {
            void setValue([{ photo_type: 'Sample', photo_file: file }]);
          }
        }}
      />
    </div>
  );
}

interface OrderDetailsVehicleItemProps {
  vehicle: VehicleDTO;
  index: number;
}

export function OrderDetailsVehicleItem({
  vehicle,
  index,
}: OrderDetailsVehicleItemProps) {
  return (
    <Columns space="large">
      <Column width="1/4">
        <OverflowText>
          <Box display="inline-block">
            <VehicleConditionIcon
              requiresEnclosedTrailer={false}
              isInoperable={!!vehicle.is_inoperable}
            />
          </Box>
          {vehicle.year} {vehicle.make} {vehicle.model}{' '}
        </OverflowText>
      </Column>

      <Column width="1/4">
        <FormikTextField
          name={`vehicles.${index}.type`}
          select={true}
          fullWidth={true}
          style={{ backgroundColor: 'white' }}
        >
          {VEHICLE_TYPES.map((value) => (
            <MenuItem key={value} value={value}>
              {formatVehicleType(value)}
            </MenuItem>
          ))}
        </FormikTextField>
      </Column>

      <Column>
        <VehicleSamplePhotoField name={`vehicles.${index}.photos`} />
      </Column>
    </Columns>
  );
}
