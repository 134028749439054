import { CircularProgress } from '@material-ui/core';
import { Inline } from '@superdispatch/ui';
import { useEffect, useMemo } from 'react';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { useSuperPaySettingsCache } from 'shared/helpers/superpay/SuperPayAPI';
import { useErrorSnackbar } from 'shared/hooks/useErrorSnackbar';
import { useMoovAccount, useRepresentativesList } from 'superpay/data/MoovAPI';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import { SuperPayOnboardingPageLayout } from 'superpay/SuperPayOnboardingPageLayout';
import { OwnerAddInitialForm } from './OwnerAddInitialForm';
import { OwnersListContainer } from './OwnersListContainer';

export function OwnersListPage() {
  const { data: representatives, refetch, error } = useRepresentativesList();
  useErrorSnackbar(error);

  const { data: moovAccount } = useMoovAccount(!!representatives); // get moov account only after representatives are loaded
  const businessType = moovAccount?.profile.business?.businessType;

  const { invalidateSuperPaySettings } = useSuperPaySettingsCache();

  const stepNumber = useMemo(
    () => (representatives && representatives.length > 0 ? 3 : 2),
    [representatives],
  );

  useEffect(() => {
    trackEvent('Shipper Opened Representatives List');
    logPaymentInfo(
      'Shipper Opened Representatives List',
      'RepresentativesList',
    );
  }, []);

  return (
    <SuperPayOnboardingPageLayout stepNumber={stepNumber}>
      {!representatives ? (
        <Inline horizontalAlign="center">
          <CircularProgress />
        </Inline>
      ) : representatives.length > 0 ? (
        <OwnersListContainer
          owners={representatives}
          refetchOwnersList={refetch}
        />
      ) : (
        <OwnerAddInitialForm
          onSubmitSuccess={() => {
            if (
              businessType === 'publicCorporation' ||
              businessType === 'soleProprietorship'
            ) {
              invalidateSuperPaySettings();
            } else {
              void refetch();
            }
          }}
        />
      )}
    </SuperPayOnboardingPageLayout>
  );
}
