import { Color, Inline } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import {
  PricingRecommendationDTO,
  usePricingRecommendation,
} from 'orders/data/PricingSuggestionAPI';
import { useEffect, useMemo } from 'react';
import { formatCurrency } from 'shared/helpers/IntlHelpers';
import { useCallbackRef } from 'shared/hooks/useCallbackRef';
import { RecommendedPriceIcon } from 'shared/icons/RecommendedPriceIcon';
import { Order } from 'shared/types/order';
import styled from 'styled-components';
import { OrderFormValues } from '../form/OrderForm';
import { PricingRecommendation } from './PricingRecommendation';
import { validateOrder } from './PricingRecommendationHelpers';

const PaymentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const Container = styled.div`
  background-color: ${Color.Silver200};
  display: inline-flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
`;

interface PriceRecommendationOrderFormProps {
  order: Partial<Order> | OrderFormValues;
  source: string;
  onPriceClick: (value: number) => void;
  onPriceRecommendation: (value: PricingRecommendationDTO | null) => void;
}

export function PriceRecommendationOrderForm({
  order,
  source,
  onPriceClick,
  onPriceRecommendation: onPricingRecommendation,
}: PriceRecommendationOrderFormProps) {
  const validationError = useMemo(() => validateOrder(order), [order]);
  const isEnabled = !validationError;
  const {
    isFetching,
    error,
    data: recommendedPrice,
  } = usePricingRecommendation(isEnabled ? order : undefined, {
    keepPreviousData: true,
  });
  const onPricingRecommendationRef = useCallbackRef(onPricingRecommendation);

  useEffect(() => {
    if (!isEnabled || error) {
      onPricingRecommendationRef(null);
    } else if (recommendedPrice !== undefined) {
      onPricingRecommendationRef(recommendedPrice);
    }
  }, [isEnabled, recommendedPrice, error, onPricingRecommendationRef]);

  if (validationError) {
    return (
      <PaymentContainer>
        <RecommendedPriceIcon />
        <TextBox color="secondary" noWrap={false}>
          {validationError}
        </TextBox>
      </PaymentContainer>
    );
  }

  if (isFetching && recommendedPrice) {
    return (
      <Container>
        <Inline verticalAlign="center">
          <RecommendedPriceIcon />
          <TextBox color="secondary">
            New details received! Updating from{' '}
            {formatCurrency(recommendedPrice.total_price, {
              maximumFractionDigits: 0,
            })}
            …
          </TextBox>
        </Inline>
      </Container>
    );
  }

  if (isFetching && !recommendedPrice) {
    return (
      <div>
        <Inline verticalAlign="center">
          <RecommendedPriceIcon />
          <TextBox color="secondary">
            Generating your pricing recommendation…
          </TextBox>
        </Inline>
      </div>
    );
  }

  return (
    <PricingRecommendation
      order={order}
      onPriceClick={onPriceClick}
      source={source}
    />
  );
}
