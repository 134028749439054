import { Link, Typography } from '@material-ui/core';
import { Column, Columns, Stack } from '@superdispatch/ui';
import { Box, TextBox } from '@superdispatch/ui-lab';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { formatPlural } from 'shared/helpers/IntlHelpers';
import { useDynamicStatistics } from 'shared/helpers/superpay/SuperPayAPI';
import { CarrierAvatars } from '../request-to-use/CarrierAvatars';
import { SuperPayRequestedCarrierList } from '../request-to-use/SuperPayRequestedCarrierList';

function TopCarrierName({
  name = '',
  carriersCount,
}: {
  name?: string;
  carriersCount: number;
}) {
  return (
    <>
      {name}{' '}
      {carriersCount
        ? `and ${carriersCount} other ${formatPlural(
            carriersCount,
            'carrier',
            'carriers',
          )} request`
        : 'requests'}{' '}
      SuperPay from you
    </>
  );
}

export function SuperPayRequestedPromoBanner() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { data } = useDynamicStatistics();
  const requestedStatistics = data?.superpay_requested_statistics;

  if (!requestedStatistics) {
    return null;
  }

  const {
    carriers_list,
    carriers_count,
    delivered_orders_count,
    paid_via_superpay_percentage,
  } = requestedStatistics;

  const [topCarrier] = carriers_list;
  const carriersCount = carriers_count && carriers_count - 1;

  return (
    <>
      <Box
        padding="small"
        borderRadius="medium"
        backgroundColor="White"
        width="100%"
        aria-label="SuperPay Requested Banner"
      >
        <Columns
          align="center"
          space={['small', 'medium']}
          collapseBelow="tablet"
        >
          <Column width={['fluid', 'content']}>
            <Box display="flex" width="100%">
              <CarrierAvatars
                carriersList={carriers_list}
                width={40}
                height={40}
              />
            </Box>
          </Column>
          <Column>
            <Stack space="xxsmall">
              <Typography variant="body1">
                {carriersCount ? (
                  <Link
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpenDialog(true);
                    }}
                  >
                    <TopCarrierName
                      name={topCarrier?.name}
                      carriersCount={carriersCount}
                    />
                  </Link>
                ) : (
                  <TopCarrierName
                    name={topCarrier?.name}
                    carriersCount={carriersCount}
                  />
                )}
              </Typography>
              <Box>
                <TextBox color="secondary">
                  They {!!carriersCount && 'overall'} delivered
                </TextBox>{' '}
                <b>
                  <NumericFormat
                    displayType="text"
                    value={delivered_orders_count}
                    thousandSeparator={true}
                  />
                </b>{' '}
                <TextBox color="secondary">
                  orders for you in the last 30 days
                  {!paid_via_superpay_percentage && '.'}
                </TextBox>
                {!!paid_via_superpay_percentage && (
                  <>
                    {' '}
                    <TextBox color="secondary">and</TextBox>{' '}
                    <b>{paid_via_superpay_percentage}%</b>{' '}
                    <TextBox color="secondary">
                      of their orders are paid via SuperPay.
                    </TextBox>
                  </>
                )}
              </Box>
            </Stack>
          </Column>
        </Columns>
      </Box>
      <SuperPayRequestedCarrierList
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
      />
    </>
  );
}
