import { createSvgIcon } from 'shared/icons/IconUtils';

export const ClickIcon = createSvgIcon(
  'ClickIcon',
  <path
    fill="currentColor"
    d="M7.80065 12.0007C6.73398 11.9451 5.83398 11.534 5.10065 10.7673C4.36732 10.0007 4.00065 9.07843 4.00065 8.00065C4.00065 6.88954 4.38954 5.9451 5.16732 5.16732C5.9451 4.38954 6.88954 4.00065 8.00065 4.00065C9.07843 4.00065 10.0007 4.36732 10.7673 5.10065C11.534 5.83398 11.9451 6.73398 12.0007 7.80065L10.6007 7.38398C10.4562 6.78398 10.1451 6.29232 9.66732 5.90898C9.18954 5.52565 8.63398 5.33398 8.00065 5.33398C7.26732 5.33398 6.63954 5.5951 6.11732 6.11732C5.5951 6.63954 5.33398 7.26732 5.33398 8.00065C5.33398 8.63398 5.52565 9.18954 5.90898 9.66732C6.29232 10.1451 6.78398 10.4562 7.38398 10.6007L7.80065 12.0007ZM8.60065 14.634C8.50065 14.6562 8.40065 14.6673 8.30065 14.6673H8.00065C7.07843 14.6673 6.21176 14.4923 5.40065 14.1423C4.58954 13.7923 3.88398 13.3173 3.28398 12.7173C2.68398 12.1173 2.20898 11.4118 1.85898 10.6007C1.50898 9.78954 1.33398 8.92287 1.33398 8.00065C1.33398 7.07843 1.50898 6.21176 1.85898 5.40065C2.20898 4.58954 2.68398 3.88398 3.28398 3.28398C3.88398 2.68398 4.58954 2.20898 5.40065 1.85898C6.21176 1.50898 7.07843 1.33398 8.00065 1.33398C8.92287 1.33398 9.78954 1.50898 10.6007 1.85898C11.4118 2.20898 12.1173 2.68398 12.7173 3.28398C13.3173 3.88398 13.7923 4.58954 14.1423 5.40065C14.4923 6.21176 14.6673 7.07843 14.6673 8.00065V8.30065C14.6673 8.40065 14.6562 8.50065 14.634 8.60065L13.334 8.20065V8.00065C13.334 6.51176 12.8173 5.25065 11.784 4.21732C10.7507 3.18398 9.48954 2.66732 8.00065 2.66732C6.51176 2.66732 5.25065 3.18398 4.21732 4.21732C3.18398 5.25065 2.66732 6.51176 2.66732 8.00065C2.66732 9.48954 3.18398 10.7507 4.21732 11.784C5.25065 12.8173 6.51176 13.334 8.00065 13.334H8.20065L8.60065 14.634ZM13.684 15.0007L10.834 12.1507L10.0007 14.6673L8.00065 8.00065L14.6673 10.0007L12.1507 10.834L15.0007 13.684L13.684 15.0007Z"
  />,
  { viewBox: '0 0 16 16' },
);
