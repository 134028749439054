import { Box } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import {
  FormikDrawer,
  FormikDrawerActions,
  FormikDrawerContent,
} from 'shared/form/FormikDrawer';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { logPaymentInfo } from 'superpay/helpers/PaymentLogger';
import { BankAccountDTO } from '../data/SuperPayDTO';
import { BankVerificationFormContent } from './BankVerificationFormContent';
import {
  getStatusErrors,
  useBankVerificationForm,
} from './useBankVerificationForm';

function VerifyBankAccountFormAction({ isDisabled }: { isDisabled: boolean }) {
  const { submitCount, isValid } = useFormikContext();
  return (
    <FormikDrawerActions
      submitButtonLabel="Verify"
      isDisabled={isDisabled || (!!submitCount && !isValid)}
    />
  );
}

interface BankVerificationDrawerProps {
  open: boolean;
  onClose: () => void;
  onComplete: () => void;
  bankAccount: BankAccountDTO;
}

export function BankVerificationDrawer({
  open,
  onClose,
  onComplete,
  bankAccount,
}: BankVerificationDrawerProps) {
  const formik = useBankVerificationForm({
    onClose,
    onComplete,
    bankAccount,
  });

  const { isIncorrectTotalSum, isIncorrectLastValues } = getStatusErrors(
    formik.status,
  );
  const isDisabled = bankAccount.validation_fail_count === 2;
  const isErrorMoov = bankAccount.validation_fail_count === 1;

  useEffect(() => {
    if (open) {
      trackEvent('Shipper Opened Bank Account Verification');
      logPaymentInfo(
        'Shipper Opened Bank Account Verification',
        'VerifyBankAccountForm',
      );
    }
  }, [open]);

  return (
    <FormikDrawer open={open} onClose={onClose}>
      <Box maxWidth={['initial', 'initial', '432px']}>
        <FormikDrawerContent
          formik={formik}
          title="Verify Bank Account"
          actions={<VerifyBankAccountFormAction isDisabled={isDisabled} />}
        >
          <BankVerificationFormContent
            isDisabled={isDisabled}
            isErrorMoov={isErrorMoov}
            isErrorTotalSum={isIncorrectTotalSum && !formik.isValid}
            isErrorLastValues={isIncorrectLastValues && !formik.isValid}
          />
        </FormikDrawerContent>
      </Box>
    </FormikDrawer>
  );
}
