import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Column, Columns } from '@superdispatch/ui';
import { ReactNode } from 'react';
import { ExitTransitionPlaceholder } from 'shared/ui/ExitTransitionPlaceholder';
import styled from 'styled-components';

const VerifyShipperDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    max-width: 1480px;
    max-height: 880px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
  }
`;

const VerificationContent = styled.div`
  display: flex;
  height: 100%;
`;

interface VerificationOverlayProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  'aria-labelledby': string;
}

export function VerificationOverlay({
  open,
  onClose,
  'aria-labelledby': labelId,
  children,
}: VerificationOverlayProps) {
  return (
    <VerifyShipperDialog
      aria-labelledby={labelId}
      open={open}
      onClose={onClose}
      fullWidth={true}
    >
      <ExitTransitionPlaceholder in={open}>
        {open && <VerificationContent>{children}</VerificationContent>}
      </ExitTransitionPlaceholder>
    </VerifyShipperDialog>
  );
}

interface VerificationApplicationDialogContentProps {
  title: string;
  'aria-labelledby'?: string;
  onClose?: () => void;
}

export function VerificationOverlayHeader({
  title,
  onClose,
  'aria-labelledby': labelId,
}: VerificationApplicationDialogContentProps) {
  return (
    <AppBar>
      <Toolbar>
        <Columns>
          <Column width="fluid">
            <Typography id={labelId} variant="h2">
              {title}
            </Typography>
          </Column>
          {onClose && (
            <Column width="content">
              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Column>
          )}
        </Columns>
      </Toolbar>
    </AppBar>
  );
}
