import { Card, CardContent, Link, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { FormattedDate, parseDate } from '@superdispatch/dates';
import {
  Color,
  Column,
  Columns,
  Stack,
  useResponsiveValue,
} from '@superdispatch/ui';
import { Alert, Box, Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import { usePermission } from 'shared/data/UserPermissions';
import { showIntercomDialog } from 'shared/helpers/IntercomHelpers';
import { usePromptNavigate } from 'shared/routing/Prompt';
import microDepositIllustration from '../assets/micro_deposit_illustration.png';
import { BankAccountDTO } from '../data/SuperPayDTO';
import { logPaymentInfo } from '../helpers/PaymentLogger';
import { ROUTES_SUPERPAY } from '../SuperPayRoute';
import { BankVerificationDrawer } from './BankVerificationDrawer';

export function BankAccountVerify({
  bankAccount,
  onCompleteVerification,
}: {
  bankAccount: BankAccountDTO;
  onCompleteVerification: () => void;
}) {
  const navigate = useNavigate();
  const promptNavigate = usePromptNavigate();
  const isMobile = useResponsiveValue(true, false);
  const canManageSuperPay = usePermission('MANAGE_SUPERPAY', 'canExecute');
  const isSettingsV2Enabled = useFeatureToggle('settings-v2.ui');

  const verificationDrawerRouteMatch = useMatch(
    isSettingsV2Enabled
      ? `/settings${ROUTES_SUPERPAY.bankVerification}`
      : ROUTES_SUPERPAY.bankVerification,
  );

  const expirationDate = useMemo(() => {
    const accountInitiatedDate = parseDate(
      bankAccount.microdeposit_initiated_date,
    );
    return accountInitiatedDate.plus({ days: 14 });
  }, [bankAccount]);

  const onDrawerClose = () => {
    promptNavigate('', { replace: true });
  };

  return (
    <>
      <Card square={isMobile} aria-label="verify account">
        <CardContent>
          <Stack space="small">
            <Columns space="large">
              <Column>
                <Stack space="small">
                  <Typography variant="h4">
                    {bankAccount.is_bank_verification_available ? (
                      'Ready for Bank Account Verification'
                    ) : (
                      <>
                        Transactions should appear by{' '}
                        <FormattedDate
                          variant="ShortDate"
                          date={parseDate(
                            bankAccount.bank_verification_available_date,
                          )}
                        />
                      </>
                    )}
                  </Typography>

                  <Box maxWidth={['auto', '300px']}>
                    {bankAccount.is_bank_verification_available ? (
                      <Typography>
                        Verify the three transactions appeared in &nbsp;
                        <b>{bankAccount.bank_name}</b> ending in&nbsp;
                        <b>{bankAccount.display_number}.</b>
                      </Typography>
                    ) : (
                      <Typography>
                        We are sending three transactions to &nbsp;
                        <b>{bankAccount.bank_name}</b> ending in&nbsp;
                        <b>{bankAccount.display_number}.</b>
                      </Typography>
                    )}
                  </Box>
                </Stack>
              </Column>

              <Column width="content">
                <img
                  src={microDepositIllustration}
                  width="109px"
                  alt="Micro Deposit Illustration"
                />
              </Column>
            </Columns>
            <Box maxWidth={['auto', '350px']}>
              {bankAccount.is_bank_verification_available ? (
                <Typography>
                  When you have received three transactions to your bank
                  account, click the Verify Bank Account button below.
                </Typography>
              ) : (
                <Typography>
                  Please wait until the transactions arrive.
                </Typography>
              )}
            </Box>

            {bankAccount.is_bank_verification_available && (
              <Alert severity="caution" icon={<Warning fontSize="small" />}>
                <Stack space="none">
                  <Typography>
                    Deposits expire on&nbsp;
                    <FormattedDate variant="ShortDate" date={expirationDate} />
                  </Typography>
                  {!canManageSuperPay && (
                    <Typography>
                      Admin or Accounting roles can set up SuperPay, contact
                      your manager for assistance.
                    </Typography>
                  )}
                </Stack>
              </Alert>
            )}

            <Stack space="xsmall">
              <Button
                disabled={
                  !bankAccount.is_bank_verification_available ||
                  !canManageSuperPay
                }
                onClick={() => {
                  navigate(
                    isSettingsV2Enabled
                      ? `/settings${ROUTES_SUPERPAY.bankVerification}`
                      : ROUTES_SUPERPAY.bankVerification,
                    { replace: true },
                  );
                  logPaymentInfo(
                    'Clicked Verify Bank Account Button',
                    'VerifyBankAccountForm',
                  );
                }}
              >
                Verify Bank Account
              </Button>
              {!bankAccount.is_bank_verification_available && (
                <Columns space="xsmall">
                  <Column width="content">
                    <Box top="2px" position="relative">
                      <Warning fontSize="small" htmlColor={Color.Yellow300} />
                    </Box>
                  </Column>
                  <Column>
                    <Box color="Yellow500">
                      <Stack space="none">
                        <Typography variant="caption">
                          The Verify Bank Account button will be available by
                          the end of&nbsp;
                          <FormattedDate
                            variant="ShortDate"
                            date={parseDate(
                              bankAccount.bank_verification_available_date,
                            )}
                          />
                        </Typography>
                        {!canManageSuperPay && (
                          <Typography variant="caption">
                            Admin or Accounting roles can set up SuperPay,
                            contact your manager for assistance.
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Column>
                </Columns>
              )}
            </Stack>

            <Typography variant="caption" color="textSecondary">
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  showIntercomDialog();
                }}
                component="button"
              >
                Contact support
              </Link>
              &nbsp; if transactions haven’t appeared or you want to change bank
              account.
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      <BankVerificationDrawer
        open={!!verificationDrawerRouteMatch}
        onClose={onDrawerClose}
        bankAccount={bankAccount}
        onComplete={onCompleteVerification}
      />
    </>
  );
}
