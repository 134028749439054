import { MenuItem } from '@material-ui/core';
import { DirectionsCar, MonetizationOn } from '@material-ui/icons';
import { FormikTextField, useFormikEnhanced } from '@superdispatch/forms';
import {
  formatPaymentMethod,
  formatPaymentTerm,
  isValidPaymentTerm,
  isValidSuperPayTerm,
} from '@superdispatch/sdk';
import { Column, Columns, Stack, useSnackbarStack } from '@superdispatch/ui';
import { Box, Button, TextBox } from '@superdispatch/ui-lab';
import { usePaymentOptions } from 'core/SuperPayUtils';
import { updatedDiff } from 'deep-object-diff';
import { Form, FormikProvider } from 'formik';
import { useOrderAPI, useOrdersCache } from 'orders/data/OrderAPI';
import { useOrderRequests } from 'orders/data/OrderRequestsAPI';
import { ChangeEvent, useMemo } from 'react';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import Order from 'shared/types/order';
import styled from 'styled-components';
import { OrderDetailsVehicleItem } from './OrderDetailsVehicleItem';
import { trackOrderRecommendations } from './OrderRequestRecommendationAnalytics';

const PaymentFormikTextField = styled(FormikTextField)<{ disabled?: boolean }>`
  & .MuiInputBase-root {
    background-color: ${({ disabled }) =>
      !disabled ? 'white' : 'transparent'};
  }
`;

function hasVehicleIssue(vehicle: VehicleDTO) {
  const hasSamplePhoto = vehicle.photos?.find((x) => x.photo_type === 'Sample');

  return vehicle.type === 'other' || (vehicle.is_inoperable && !hasSamplePhoto);
}

export function useOrderDetailsRecommendation(order: Order) {
  const isVehicleInfoComplete = useMemo(
    () => !order.vehicles?.some(hasVehicleIssue),
    [order.vehicles],
  );

  const isPaymentInfoComplete = useMemo(
    () => order.payment?.method !== 'other' && order.payment?.terms !== 'other',
    [order.payment],
  );

  if (!isVehicleInfoComplete || !isPaymentInfoComplete) {
    return 'error';
  }

  return 'success';
}

export function OrderDetailsRecommendation({ order }: { order: Order }) {
  const { addSnackbar } = useSnackbarStack();
  const { invalidateOrders } = useOrdersCache();
  const { updateOrder, uploadOrderVehiclePhoto, removeOrderVehiclePhoto } =
    useOrderAPI();
  const { data: requests } = useOrderRequests(order.guid);

  const showPaymentInfo =
    order.payment?.method === 'other' || order.payment?.terms === 'other';

  const isSuperPayStatusAvailable =
    order.payment?.method === 'superpay' && !!order.payment.super_pay?.status;

  const disableSuperPayOption =
    order.payment?.method !== 'superpay' &&
    (order.status === 'invoiced' ||
      order.status === 'delivered' ||
      order.status === 'delivery_verified');

  const formik = useFormikEnhanced({
    initialValues: { ...order },
    onSubmit: async (values) => {
      const nextVehicles = values.vehicles || [];
      const prevVehicles = order.vehicles || [];
      const updatedOrder = await updateOrder(order.id, values);

      await Promise.all(
        nextVehicles.map((vehicle, index) => {
          const photo = vehicle.photos?.find(
            (x) => x.photo_file instanceof File,
          );
          const updatedVehicle = updatedOrder.vehicles?.[index];

          if (photo?.photo_file && updatedVehicle) {
            return uploadOrderVehiclePhoto(
              order.id,
              updatedVehicle.id,
              photo.photo_file,
            );
          }

          return undefined;
        }),
      );

      await Promise.all(
        prevVehicles.map((prevVehicle) => {
          const nextVehicle = nextVehicles.find((x) => x.id === prevVehicle.id);
          const prevPhoto = prevVehicle.photos?.find(
            (x) => x.photo_type === 'Sample',
          );
          const nextPhoto = nextVehicle?.photos?.find(
            (x) => x.photo_type === 'Sample',
          );

          if (prevPhoto && nextVehicle && !nextPhoto) {
            return removeOrderVehiclePhoto(order.id, prevVehicle.id);
          }

          return undefined;
        }),
      );
    },
    onSubmitSuccess: (_, values) => {
      const updatedValues = updatedDiff(order, values);
      trackOrderRecommendations(updatedValues, requests?.objects.length);
      void invalidateOrders();
    },
    onSubmitFailure: (error) => {
      addSnackbar(error.message, { variant: 'error' });
    },
  });

  const invalidVehicles = order.vehicles?.filter(hasVehicleIssue);
  const { paymentMethods, paymentTerms } = usePaymentOptions(
    formik.values.payment?.method,
    disableSuperPayOption,
  );

  const onPaymentMethodChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (
      value === 'superpay' &&
      !isValidSuperPayTerm(formik.values.payment?.terms)
    ) {
      void formik.setFieldValue('payment.terms', '1_3_days');
    } else if (!isValidPaymentTerm(formik.values.payment?.terms)) {
      void formik.setFieldValue('payment.terms', 'other');
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack space="small">
          {!!invalidVehicles?.length && (
            <>
              <Columns space="xsmall">
                <Column width="content">
                  <Box padding="xxsmall">
                    <DirectionsCar fontSize="small" color="action" />
                  </Box>
                </Column>

                <Column>
                  <TextBox display="block" variant="heading-5">
                    Include a vehicle photo and accurately specify the vehicle
                    type.
                  </TextBox>
                  <TextBox color="secondary">
                    Avoid selecting &quot;Other&quot;, especially for inoperable
                    or non-standard vehicles.
                  </TextBox>
                </Column>
              </Columns>

              <Box
                padding="small"
                borderRadius="small"
                marginLeft="large"
                backgroundColor="Silver200"
                overflow="auto"
                width={['290px', '100%']}
              >
                <Box width={['550px', '100%']}>
                  <Stack space="large">
                    <Columns space="large">
                      <Column width="1/4">
                        <TextBox color="secondary">Vehicle</TextBox>
                      </Column>

                      <Column width="1/4">
                        <TextBox color="secondary">Type</TextBox>
                      </Column>

                      <Column>
                        <TextBox color="secondary">Photo</TextBox>
                      </Column>
                    </Columns>

                    {order.vehicles?.map((vehicle, index) => {
                      if (!hasVehicleIssue(vehicle)) {
                        return null;
                      }

                      return (
                        <OrderDetailsVehicleItem
                          key={vehicle.id}
                          index={index}
                          vehicle={vehicle}
                        />
                      );
                    })}
                  </Stack>
                </Box>
              </Box>
            </>
          )}

          {showPaymentInfo && (
            <>
              <Columns space="xsmall" collapseBelow="desktop">
                <Column width="content">
                  <Box padding="xxsmall">
                    <MonetizationOn fontSize="small" color="action" />
                  </Box>
                </Column>
                <Column>
                  <Stack space="none">
                    <TextBox variant="heading-5">
                      Set clear payment terms to attract carriers faster.
                    </TextBox>
                    <TextBox color="secondary">
                      Avoid selecting &quot;Other&quot; for payment method and
                      terms.
                    </TextBox>
                  </Stack>
                </Column>
              </Columns>

              <Stack space="large">
                <Box
                  padding="small"
                  borderRadius="small"
                  marginLeft="large"
                  backgroundColor="Silver200"
                >
                  <Columns space="large" collapseBelow="desktop">
                    <Column width="content">
                      <PaymentFormikTextField
                        label="Method"
                        select={true}
                        fullWidth={true}
                        name="payment.method"
                        unstableOnKeyDownSelection={true}
                        helperText="Select accurate method"
                        onChange={onPaymentMethodChange}
                        disabled={
                          isSuperPayStatusAvailable ||
                          (!!order.payment?.method &&
                            order.payment.method !== 'other')
                        }
                        error={
                          formik.dirty &&
                          formik.values.payment?.method === 'other'
                        }
                      >
                        {paymentMethods
                          .filter((method) => {
                            if (method === 'superpay') {
                              return order.inspection_type === 'advanced';
                            }
                            return true;
                          })
                          .map((value) => (
                            <MenuItem key={value} value={value}>
                              {formatPaymentMethod(value)}
                            </MenuItem>
                          ))}
                      </PaymentFormikTextField>
                    </Column>

                    <Column width="content">
                      <PaymentFormikTextField
                        label="Terms"
                        select={true}
                        fullWidth={true}
                        defaultValue="other"
                        name="payment.terms"
                        disabled={isSuperPayStatusAvailable}
                        unstableOnKeyDownSelection={true}
                        helperText="Select accurate term"
                        error={
                          formik.dirty &&
                          formik.values.payment?.terms === 'other'
                        }
                      >
                        {paymentTerms.map((value) => (
                          <MenuItem key={value} value={value}>
                            {formatPaymentTerm(value)}
                          </MenuItem>
                        ))}
                      </PaymentFormikTextField>
                    </Column>
                  </Columns>
                </Box>
              </Stack>
            </>
          )}

          <Box paddingTop="small">
            <Button
              type="submit"
              disabled={!formik.dirty}
              pending={formik.isSubmitting}
            >
              Save
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
