import { Container } from '@material-ui/core';
import { Button } from '@superdispatch/ui-lab';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const VerificationApplicationStepsFormBox = styled(Container)`
  max-width: 512px;
  padding: 0;
  display: flex;
`;

export const VerificationApplicationStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  padding-bottom: 40px;
  height: 100%;
  width: 100%;
`;

export const VerificationApplicationStepsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

export const VerificationApplicationBottomBar = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;

  margin: 0 auto;
  width: 100%;
  max-width: 512px;
`;

interface VerificationApplicationWrapperProps {
  showBackButton: boolean;
  onBackNavigation?: () => void;
  onSubmit: () => void;
  saveButtonTitle?: string;
  children: ReactNode;
}

export function VerificationApplicationWrapper({
  showBackButton,
  onBackNavigation,
  onSubmit,
  saveButtonTitle = 'Save and Continue',
  children,
}: VerificationApplicationWrapperProps) {
  return (
    <VerificationApplicationStepsContainer>
      <VerificationApplicationStepsContent>
        {children}
      </VerificationApplicationStepsContent>
      <VerificationApplicationBottomBar>
        {showBackButton && (
          <Button
            type="submit"
            variant="neutral"
            size="small"
            onClick={onBackNavigation}
          >
            Back
          </Button>
        )}
        <Button type="submit" variant="primary" size="small" onClick={onSubmit}>
          {saveButtonTitle}
        </Button>
      </VerificationApplicationBottomBar>
    </VerificationApplicationStepsContainer>
  );
}

interface VerificationStepperProps {
  step: number;
  children: ReactNode;
  onGoBack?: () => void;
}

export function VerificationApplicationStepsWrapper({
  step,
  children,
  onGoBack,
}: VerificationStepperProps) {
  const formik = useFormikContext();
  return (
    <VerificationApplicationWrapper
      showBackButton={step > 1}
      onBackNavigation={onGoBack}
      onSubmit={() => formik.handleSubmit()}
    >
      {children}
    </VerificationApplicationWrapper>
  );
}
