import { Tooltip, Typography } from '@material-ui/core';
import { Inline, Stack } from '@superdispatch/ui';
import { TextBox } from '@superdispatch/ui-lab';
import { VehicleDTO } from 'shared/dto/Order/VehicleDTO';
import { PricingInsightsRecentLoadVehicleDTO } from '../../data/dto/PricingInsightsRecentPostings';
import { formatVehicleTitle } from './LoadVehicle';

interface PostingVehiclesSummaryProps {
  title: string;
  vehicles: PricingInsightsRecentLoadVehicleDTO[] | VehicleDTO[];
}

export function PostingVehiclesSummary({
  title,
  vehicles,
}: PostingVehiclesSummaryProps) {
  return (
    <Inline space="xxsmall" verticalAlign="center">
      <Tooltip
        enterDelay={800}
        title={
          <Stack space="xxsmall">
            {vehicles.map((vehicle, index) => (
              <TextBox key={index} color="white">
                {formatVehicleTitle(vehicle)}
              </TextBox>
            ))}
          </Stack>
        }
      >
        <Typography display="inline" noWrap={true} color="textPrimary">
          {title}
        </Typography>
      </Tooltip>
    </Inline>
  );
}
