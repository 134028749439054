import { OrderRequestDTO } from 'orders/data/dto/OrderRequestDTO';
import { useUserState } from 'shared/data/AppUserState';
import { useFeatureToggle } from 'shared/data/FeatureToggle';
import Order from 'shared/types/order';
import {
  AcceptsSuperPayChangeTerms,
  CarrierAcceptsSuperPay,
} from 'superpay/CarrierAcceptsSuperPay';
import { CarrierRequestsSuperPay } from 'superpay/CarrierRequestsSuperPay';

type Variant =
  | 'carrier-requests-superpay'
  | 'accepts-superpay-change-terms'
  | 'carrier-accepts-superpay';

interface SuperPayActionsProps {
  variants?: Variant[];
  isDisabled: boolean;
  request: OrderRequestDTO;
  order: Order;
  isSuperPay: boolean;
  setSuperPay: (checked: boolean) => void;
}

export function OrderRequestSuperPayActions({
  isDisabled,
  request,
  order,
  isSuperPay,
  variants = [
    'carrier-requests-superpay',
    'accepts-superpay-change-terms',
    'carrier-accepts-superpay',
  ],
  setSuperPay,
}: SuperPayActionsProps) {
  const { user } = useUserState();
  const isCarrierRequestsSuperPayEnabled = useFeatureToggle(
    'carrier-requests-superpay.access.ui',
  );

  const showSuperPayCheckbox =
    user?.shipper.super_pay_settings?.on_boarding_status === 'COMPLETED' &&
    !order.payment?.superpay_canceled &&
    (!request.is_super_pay_enabled || order.payment?.method !== 'superpay');

  if (request.status !== 'REQUEST_PENDING') return null;

  if (
    isCarrierRequestsSuperPayEnabled &&
    request.broker_records?.superpay_requested &&
    variants.includes('carrier-requests-superpay')
  ) {
    return (
      <CarrierRequestsSuperPay
        source="Carrier Requests Drawer"
        compact={true}
      />
    );
  }

  if (
    showSuperPayCheckbox &&
    variants.includes('accepts-superpay-change-terms')
  ) {
    return (
      <AcceptsSuperPayChangeTerms
        disabled={isDisabled}
        checked={isSuperPay}
        source="Carrier Requests Drawer"
        onChange={(_, checked) => setSuperPay(checked)}
      />
    );
  }

  if (variants.includes('carrier-accepts-superpay')) {
    return (
      <CarrierAcceptsSuperPay source="Carrier Requests Drawer" compact={true} />
    );
  }

  return null;
}
