import Link from '@material-ui/core/Link';
import { Stack } from '@superdispatch/ui';
import {
  Alert,
  AlertSeverityProp,
  Button,
  TextBox,
} from '@superdispatch/ui-lab';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { trackEvent } from 'shared/helpers/AnalyticsHelpers';
import { showNewMessage } from 'shared/helpers/IntercomHelpers';
import { getIntercomMessageByAlertCode } from './getIntercomMessageByAlertCode';
import { AlertDTO } from './useAlerts';

const severityMap: Record<AlertDTO['severity_type'], AlertSeverityProp> = {
  WARNING: 'caution',
  ERROR: 'critical',
};

export function SystemAlert({
  alert,
  onClose,
}: {
  alert: AlertDTO;
  onClose?: () => void;
}) {
  const navigate = useNavigate();

  return (
    <Alert severity={severityMap[alert.severity_type]} onClose={onClose}>
      <Stack space="xsmall">
        <Stack space="none">
          <TextBox variant="body-semibold" color="inherit">
            {alert.name}
          </TextBox>
          <Description
            description={alert.description}
            orderGuid={alert.order_guid}
            orderNumber={alert.order_number}
            orderCount={alert.order_count}
          />
        </Stack>

        {alert.action_type === 'CONTACT_SUPPORT' && (
          <Button
            variant="neutral"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              trackEvent('Clicked Contact Support', {
                utm_medium: 'alerts',
                utm_content: alert.code ? `Error ${alert.code}` : undefined,
              });
              showNewMessage(getIntercomMessageByAlertCode(alert.code));
            }}
          >
            Contact Support
          </Button>
        )}

        {alert.action_type === 'UPDATE_CENTRAL_DISPATCH_CREDENTIALS' && (
          <Button
            variant="neutral"
            size="small"
            onClick={() => navigate('/profile/cd-integration/edit')}
          >
            Update Credentials
          </Button>
        )}
      </Stack>
    </Alert>
  );
}

function Description({
  description,
  orderGuid,
  orderNumber,
  orderCount,
}: {
  description: string;
  orderGuid: string | null;
  orderNumber: string | null;
  orderCount: number;
}) {
  if (!description.includes('%s')) {
    return <TextBox color="inherit">{description}</TextBox>;
  }

  const linkTo =
    orderCount === 1 && orderGuid
      ? `/orders/view/${orderGuid}`
      : '/orders/superpay_failed';
  const linkText = orderCount > 1 ? `${orderCount} orders` : orderNumber;
  const linkElement = (
    <Link to={linkTo} component={RouterLink}>
      {linkText}
    </Link>
  );

  const descriptionParts = description.split('%s');

  return (
    <TextBox color="inherit">
      {descriptionParts.map((part, idx) => (
        <>
          {part}
          {idx < descriptionParts.length - 1 && linkElement}
        </>
      ))}
    </TextBox>
  );
}
