import { Typography } from '@material-ui/core';
import { CheckCircle, Lock } from '@material-ui/icons';
import {
  Color,
  Inline,
  Stack,
  useSnackbarStack,
  useUID,
} from '@superdispatch/ui';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  OrderActionsAnalyticsProperties,
  trackVerificationApplicationEvent,
} from '../data/VerificationAnalyticsEvents';
import { VerificationApplicationSteps } from '../verification-application-steps/VerificationApplicationSteps';
import {
  VerificationOverlay,
  VerificationOverlayHeader,
} from './VerificationOverlay';
import { VerificationSidebar } from './VerificationSidebar';

const LockContainer = styled.div`
  width: fit-content;
  border-radius: 50%;
  padding: 8px;
  background-color: ${Color.White10};
`;

const DialogStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface VerificationApplicationDialogContentProps {
  onClose: () => void;
  uid: string;
}

function VerificationApplicationDialogContent({
  onClose,
  uid,
}: VerificationApplicationDialogContentProps) {
  const { addSnackbar } = useSnackbarStack();
  return (
    <DialogStepsContainer>
      <VerificationOverlayHeader
        title="Verification Application"
        aria-labelledby={uid}
        onClose={onClose}
      />
      <VerificationApplicationSteps
        onSubmitSuccess={() => {
          onClose();
          addSnackbar('Your documents were submitted successfully', {
            variant: 'default',
          });
        }}
      />
    </DialogStepsContainer>
  );
}

interface VerificationApplicationDialogProps
  extends OrderActionsAnalyticsProperties {
  open: boolean;
  onClose: () => void;
}

export function VerificationApplicationDialog({
  open,
  onClose,
  action,
  actionType,
}: VerificationApplicationDialogProps) {
  const uid = useUID();
  const location = useLocation();

  const dialogLocation = location.pathname.includes('profile')
    ? 'Profile'
    : 'Orders';

  useEffect(() => {
    if (open) {
      trackVerificationApplicationEvent({
        name: 'Shipper Opened Verification Dialog',
        utm_medium: dialogLocation,
        action,
        action_type: actionType,
      });
    }
  }, [open, action, actionType, dialogLocation]);

  return (
    <VerificationOverlay aria-labelledby={uid} open={open} onClose={onClose}>
      <VerificationSidebar bottomText="Verification may take up to two business days to complete.">
        <Stack space="medium">
          <LockContainer>
            <Lock />
          </LockContainer>
          <Typography variant="h2">
            Unlock the Power of Super Dispatch:
          </Typography>
          <Stack space="small">
            <Inline verticalAlign="center">
              <CheckCircle />
              <Typography>Access Thousands of Verified Carriers</Typography>
            </Inline>
            <Inline verticalAlign="center">
              <CheckCircle />
              <Typography>Send Direct Offers to Preferred Carriers</Typography>
            </Inline>
            <Inline verticalAlign="center">
              <CheckCircle />
              <Typography>Post on the Super LoadBoard</Typography>
            </Inline>
            <Inline verticalAlign="center">
              <CheckCircle />
              <Typography>Manage Orders & Payments Seamlessly</Typography>
            </Inline>
            <Inline verticalAlign="center">
              <CheckCircle />
              <Typography>Say Goodbye to Endless Calls & Paperwork</Typography>
            </Inline>
          </Stack>
        </Stack>
      </VerificationSidebar>
      <VerificationApplicationDialogContent onClose={onClose} uid={uid} />
    </VerificationOverlay>
  );
}
